import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreditorDatasource } from "@entity/data/datasources/creditor.datasource";
import { CreditorMapperError } from "@entity/domain/errors/creditor/creditor-mapper.error";
import { EntityCreditorError } from "@entity/domain/errors/entity.error";
import {
    CreateCreditor,
    Creditor,
    CreditorSearchFilters,
    Creditors,
    EditCreditor,
} from "@entity/domain/models/creditor/creditor.model";
import { inject, injectable } from "inversify";

@injectable()
export class CreditorRepository {
    constructor(
        @inject(CreditorDatasource)
        private readonly creditorDatasource: CreditorDatasource,
    ) { }

    async getBy(
        pagination: Pagination,
        filters?: CreditorSearchFilters,
    ): Promise<Either<EntityCreditorError, Creditors>> {
        const creditorsSummary = await this.creditorDatasource.fetchAllBy(
            pagination,
            filters,
        );

        return creditorsSummary.mapLeft(() => new EntityCreditorError("fetchFilteredCreditorsError"));
    }

    async getById(id: number): Promise<Either<EntityCreditorError | CreditorMapperError, Creditor>> {
        const creditorResult = await this.creditorDatasource.fetchById(id);

        if (creditorResult.isLeft())
            Either.Left(creditorResult.getLeftOrThrow());

        const creditor = creditorResult.getOrThrow().creditor;

        return Either.Right(creditor);
    }

    async getAll(): Promise<Either<EntityCreditorError, Creditor[]>> {
        const creditorsResult = await this.creditorDatasource.fetchAll();

        return creditorsResult
            .mapLeft(() => new EntityCreditorError("fetchCreditorsError"))
            .map((creditors) => creditors);
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<EntityCreditorError, Creditors>> {
        const creditorsResult = await this.creditorDatasource.fetchAllPaginated(
            limit,
            offset,
        );

        return creditorsResult.mapLeft(() => new EntityCreditorError("fetchCreditorsPaginatedError"));
    }

    async create(
        createCreditor: CreateCreditor,
    ): Promise<Either<ValidationError | EntityCreditorError | CreditorMapperError, Creditor>> {
        return this.creditorDatasource.create(createCreditor);
    }

    async edit(
        editCreditor: EditCreditor,
    ): Promise<Either<ValidationError | EntityCreditorError | CreditorMapperError, Creditor>> {
        return this.creditorDatasource.edit(editCreditor);
    }

    async delete(creditorId: number): Promise<Either<EntityCreditorError, boolean>> {
        return this.creditorDatasource.delete(creditorId);
    }
}
