import { InternalNotesDatasource } from "@beneficiary/data/datasource/internal-notes.datasource";
import { BeneficiaryInternalNotesError } from "@beneficiary/domain/errors/beneficiary.error";
import { InternalNotesMapperError } from "@beneficiary/domain/errors/internal-notes/internal-notes-mapper.error";
import { CreateInternalNote } from "@beneficiary/domain/models/internal-notes/create-internal-note.model";
import { EditInternalNote } from "@beneficiary/domain/models/internal-notes/edit-internal-note.model";
import {
    InternalNote,
    InternalNotes,
} from "@beneficiary/domain/models/internal-notes/internal-note.model";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class InternalNotesRepository {
    constructor(
        @inject(InternalNotesDatasource)
        private readonly internalNotesDatasource: InternalNotesDatasource,
    ) { }

    async getAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<BeneficiaryInternalNotesError, InternalNotes>> {
        return this.internalNotesDatasource.fetchAllBy(
            beneficiaryId,
            pagination,
        );
    }

    async create(
        internalNote: CreateInternalNote,
    ): Promise<Either<ValidationError | BeneficiaryInternalNotesError | InternalNotesMapperError, InternalNote>> {
        return this.internalNotesDatasource.create(internalNote);
    }

    async edit(
        editInternalNote: EditInternalNote,
    ): Promise<Either<ValidationError | BeneficiaryInternalNotesError | InternalNotesMapperError, InternalNote>> {
        return this.internalNotesDatasource.edit(editInternalNote);
    }

    async delete(
        internalNoteId: number,
    ): Promise<Either<BeneficiaryInternalNotesError, boolean>> {
        return this.internalNotesDatasource.delete(internalNoteId);
    }
}
