import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { DisabilityTypeDto } from "@entity/data/dto/employee/disability-type.dto";
import { EditEmployeeBody } from "@entity/data/dto/employee/edit-employee.body";
import { EmployeesTypeCountDto } from "@entity/data/dto/employee/employee-type-count.dto";
import { EmployeeTypeDto } from "@entity/data/dto/employee/employee-type.dto";
import { EmployeeDto } from "@entity/data/dto/employee/employee.dto";
import {
    EmployeesDto,
    EmployeesSummaryQuery,
} from "@entity/data/dto/employee/employees.dto";
import { ProfessionalGroupDto } from "@entity/data/dto/employee/professional-group.dto";
import { SubsidyReasonDto } from "@entity/data/dto/employee/subsidy-reason.dto";
import { TerminationReasonDto } from "@entity/data/dto/employee/termination-reason.dto";
import { ContractTerminationsMapper } from "@entity/data/mappers/employee/contracts/termination/contract-terminations.mapper";
import { CreateEmployeeMapper } from "@entity/data/mappers/employee/create-employee.mapper";
import { DisabilityTypeMapper } from "@entity/data/mappers/employee/disability-type.mapper";
import { EditEmployeeMapper } from "@entity/data/mappers/employee/edit-employee.mapper";
import { EmployeeTypeEnumMapper } from "@entity/data/mappers/employee/employee-type-enum.mapper";
import { EmployeeTypeMapper } from "@entity/data/mappers/employee/employee-type.mapper";
import { EmployeesCountMapper } from "@entity/data/mappers/employee/employees-count.mapper";
import { EmployeesMapper } from "@entity/data/mappers/employee/employees.mapper";
import { ProfessionalGroupMapper } from "@entity/data/mappers/employee/professional-group.mapper";
import { SubsidyReasonMapper } from "@entity/data/mappers/employee/subsidy-reason.mapper";
import { TerminationReasonMapper } from "@entity/data/mappers/employee/termination-reason.mapper";
import { CreateEmployee } from "@entity/domain/models/employee/create-employee.model";
import { DisabilityType } from "@entity/domain/models/employee/disability-type.model";
import { EditEmployee } from "@entity/domain/models/employee/edit-employee.model";
import { EmployeeType } from "@entity/domain/models/employee/employee-type.model";
import {
    Employee,
    EmployeeSearchFilters,
    Employees,
} from "@entity/domain/models/employee/employee.model";
import { ProfessionalGroup } from "@entity/domain/models/employee/professional-group.model";
import { SubsidyReason } from "@entity/domain/models/employee/subsidy-reason.model";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { ProjectEmployeesCount } from "@project/domain/models/project-employees.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { EmployeeMapper } from "../mappers/employee/employee.mapper";
import { EntityEmployeeError } from "@entity/domain/errors/entity.error";
import { EmployeeMapperError } from "@entity/domain/errors/employee/employee-mapper.error";

const EMPLOYEE_PATH = "/employees/";

@injectable()
export class EmployeeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(EmployeesCountMapper)
        private readonly employeesCountMapper: EmployeesCountMapper,
        @inject(EmployeeTypeEnumMapper)
        private readonly employeeTypeEnumMapper: EmployeeTypeEnumMapper,
        @inject(EmployeeTypeMapper)
        private readonly employeeTypeMapper: EmployeeTypeMapper,
        @inject(EmployeesMapper)
        private readonly employeesMapper: EmployeesMapper,
        @inject(EmployeeMapper)
        private readonly employeeMapper: EmployeeMapper,
        @inject(CreateEmployeeMapper)
        private readonly createEmployeeMapper: CreateEmployeeMapper,
        @inject(ContractTerminationsMapper)
        private readonly contractTerminationsMapper: ContractTerminationsMapper,
        @inject(EditEmployeeMapper)
        private readonly editEmployeeMapper: EditEmployeeMapper,
        @inject(ProfessionalGroupMapper)
        private readonly professionalGroupMapper: ProfessionalGroupMapper,
        @inject(DisabilityTypeMapper)
        private readonly disabilityTypeMapper: DisabilityTypeMapper,
        @inject(TerminationReasonMapper)
        private readonly terminationReasonMapper: TerminationReasonMapper,
        @inject(SubsidyReasonMapper)
        private readonly subsidyReasonMapper: SubsidyReasonMapper,
    ) { }

    async fetchAllBy(
        pagination: Pagination,
        filters?: EmployeeSearchFilters,
    ): Promise<Either<EntityEmployeeError, Employees>> {
        const query: EmployeesSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };
        if (filters?.name) query.search = filters.name;
        if (filters?.entityId) {
            query.entity = filters.entityId;
        }
        if (filters?.type)
            query.type = this.employeeTypeEnumMapper.mapToDto(filters.type);
        if (filters?.isActive) query.is_active = filters.isActive;
        if (filters?.activeContract)
            query.active_contract = filters.activeContract;
        if (filters?.entityIds) query.entities = filters.entityIds.join(",");

        const employeesResult = await this.http.get<EmployeesDto>(
            EMPLOYEE_PATH,
            {
                query,
            },
        );

        return employeesResult
            .map((response) =>
                this.employeesMapper.map(
                    plainToClass(EmployeesDto, response.data),
                ),
            )
            .mapLeft(() => new EntityEmployeeError("fetchEmployeesError"));
    }

    async fetchAllTypes(): Promise<Either<EntityEmployeeError, EmployeeType[]>> {
        const responseResult = await this.http.get<EmployeeTypeDto[]>(
            `${EMPLOYEE_PATH}type/`,
        );

        return responseResult
            .mapLeft(() => new EntityEmployeeError("fetchEmployeeTypesError"))
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.employeeTypeMapper.map(
                        plainToClass(EmployeeTypeDto, typeDto),
                    ),
                ),
            );
    }

    async getCountByType(): Promise<
        Either<EntityEmployeeError, ProjectEmployeesCount>
    > {
        const employeesCountResult = await this.http.get<EmployeesTypeCountDto>(
            `${EMPLOYEE_PATH}type_count/`,
        );
        return employeesCountResult
            .mapLeft(() => new EntityEmployeeError("fetchEmployeesCountError"))
            .map((response) =>
                this.employeesCountMapper.map(
                    plainToClass(EmployeesTypeCountDto, response.data),
                ),
            );
    }

    async create(
        employeeToCreate: CreateEmployee,
    ): Promise<Either<ValidationError | EntityEmployeeError | EmployeeMapperError, Employee>> {
        const employeeBody =
            this.createEmployeeMapper.mapToCreateDto(employeeToCreate);

        const createEmployeeResult = await this.http.post<EmployeeDto>(
            EMPLOYEE_PATH,
            employeeBody,
        );

        return createEmployeeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityEmployeeError("createEmployeeError");
            })
            .flatMap((response) => {
                const employee = this.employeeMapper.map(
                    plainToClass(EmployeeDto, response.data),
                );

                if (!employee) return Either.Left(new EmployeeMapperError());

                return Either.Right(employee);
            });
    }

    async edit(
        employeeToEdit: EditEmployee,
    ): Promise<Either<ValidationError | EntityEmployeeError | EmployeeMapperError, Employee>> {
        const employeeBody = this.editEmployeeMapper.mapToDto(employeeToEdit);

        const editEmployeeResult = await this.http.patch<
            EmployeeDto,
            EditEmployeeBody
        >(`${EMPLOYEE_PATH}${employeeToEdit.id}/`, employeeBody);

        return editEmployeeResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityEmployeeError("editEmployeeError");
            })
            .flatMap((response) => {
                const employee = this.employeeMapper.map(
                    plainToClass(EmployeeDto, response.data),
                );

                if (!employee) return Either.Left(new EmployeeMapperError());

                return Either.Right(employee);
            });
    }

    async fetchById(
        employeeId: number,
    ): Promise<Either<EntityEmployeeError | EmployeeMapperError, Employee>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const employeeResult = await this.http.get<EmployeeDto>(
            `${EMPLOYEE_PATH}${employeeId}/`,
            {
                query,
            },
        );

        return employeeResult
            .mapLeft(() => new EntityEmployeeError("fetchEmployeeError"))
            .flatMap((response) => {
                const employee = this.employeeMapper.map(
                    plainToClass(EmployeeDto, response.data),
                );

                if (!employee) return Either.Left(new EmployeeMapperError());

                return Either.Right(employee);
            });
    }

    async fetchAllProfessionalGroups(): Promise<
        Either<EntityEmployeeError, ProfessionalGroup[]>
    > {
        const responseResult = await this.http.get<ProfessionalGroupDto[]>(
            "/freelance_contracts/professional_groups/",
        );

        return responseResult
            .mapLeft(() => new EntityEmployeeError("fetchProfessionalGroupsError"))
            .map((response) =>
                response.data.mapNotNull((groupDto) =>
                    this.professionalGroupMapper.map(
                        plainToClass(ProfessionalGroupDto, groupDto),
                    ),
                ),
            );
    }

    async fetchAllDisabilityTypes(): Promise<
        Either<EntityEmployeeError, DisabilityType[]>
    > {
        const responseResult = await this.http.get<DisabilityTypeDto[]>(
            "/employee_contracts/disability_types/",
        );

        return responseResult
            .mapLeft(() => new EntityEmployeeError("fetchDisabilityTypesError"))
            .map((response) =>
                response.data.mapNotNull((disabilityTypeDto) =>
                    this.disabilityTypeMapper.map(
                        plainToClass(DisabilityTypeDto, disabilityTypeDto),
                    ),
                ),
            );
    }

    async fetchAllTerminationReasons(): Promise<
        Either<EntityEmployeeError, TerminationReason[]>
    > {
        const responseResult = await this.http.get<TerminationReasonDto[]>(
            "/employee_contracts/termination_reasons/",
        );

        return responseResult
            .mapLeft(() => new EntityEmployeeError("fetchTerminationReasonsError"))
            .map((response) =>
                response.data.mapNotNull((terminationReasonDto) =>
                    this.terminationReasonMapper.map(
                        plainToClass(
                            TerminationReasonDto,
                            terminationReasonDto,
                        ),
                    ),
                ),
            );
    }

    async fetchAllSubsidyReasons(): Promise<
        Either<EntityEmployeeError, SubsidyReason[]>
    > {
        const responseResult = await this.http.get<SubsidyReasonDto[]>(
            "/employee_contracts/subsidy_reasons/",
        );

        return responseResult
            .mapLeft(() => new EntityEmployeeError("fetchSubsidyReasonsError"))
            .map((response) =>
                response.data.mapNotNull((subsidyReasonDto) =>
                    this.subsidyReasonMapper.map(
                        plainToClass(SubsidyReasonDto, subsidyReasonDto),
                    ),
                ),
            );
    }
}
