import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { AddressDto, AddressesDto } from "@entity/data/dto/address/address.dto";
import { CreateAddressBody } from "@entity/data/dto/address/create-address.body";
import { EditAddressBody } from "@entity/data/dto/address/edit-address.body";
import { AddressesMapper } from "@entity/data/mappers/address/addresses.mapper";
import { CreateAddressMapper } from "@entity/data/mappers/address/create-address.mapper";
import { EditAddressMapper } from "@entity/data/mappers/address/edit-address.mapper";
import { AddressMapperError } from "@entity/domain/errors/address/address-mapper.error";
import { EntityAddressError } from "@entity/domain/errors/entity.error";
import {
    Address,
    Addresses,
} from "@entity/domain/models/address/address.model";
import { CreateAddress } from "@entity/domain/models/address/create-address.model";
import { EditAddress } from "@entity/domain/models/address/edit-address.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_ADDRESS_PATH = "/entities_addresses/";

@injectable()
export class AddressDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(AddressesMapper)
        private readonly addressesMapper: AddressesMapper,
        @inject(CreateAddressMapper)
        private readonly createAddressMapper: CreateAddressMapper,
        @inject(EditAddressMapper)
        private readonly editAddressMapper: EditAddressMapper,
    ) { }

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, Addresses>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const addressResult = await this.http.get<AddressDto>(
            ENTITY_ADDRESS_PATH,
            {
                query,
            },
        );

        return addressResult.map((response) =>
            this.addressesMapper.map(plainToClass(AddressesDto, response.data)),
        );
    }

    async create(
        newAddress: CreateAddress,
    ): Promise<Either<ValidationError | EntityAddressError | AddressMapperError, Address>> {
        const addressBody = this.createAddressMapper.mapToCreateDto(newAddress);

        const addressResult = await this.http.post<
            AddressDto,
            CreateAddressBody
        >(ENTITY_ADDRESS_PATH, addressBody);

        return addressResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityAddressError("createAddressError");
            })
            .flatMap((response) => {
                const address = this.addressesMapper.mapAddress(
                    plainToClass(AddressDto, response.data),
                );

                if (!address) return Either.Left(new AddressMapperError());

                return Either.Right(address);
            });
    }

    async edit(
        editAddress: EditAddress,
    ): Promise<Either<ValidationError | EntityAddressError | AddressMapperError, Address>> {
        const editedAddress = this.editAddressMapper.mapToDto(editAddress);

        const editAddressResult = await this.http.patch<
            AddressDto,
            EditAddressBody
        >(`${ENTITY_ADDRESS_PATH}${editAddress.id}/`, editedAddress);

        return editAddressResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityAddressError("editAddressError");
            })
            .flatMap((response) => {
                const address = this.addressesMapper.mapAddress(
                    plainToClass(AddressDto, response.data),
                );

                if (!address) return Either.Left(new AddressMapperError());

                return Either.Right(address);
            });
    }

    async delete(addressId: number): Promise<Either<EntityAddressError, true>> {
        const deleteAddress = await this.http.delete(
            `${ENTITY_ADDRESS_PATH}${addressId}`,
        );

        return deleteAddress.mapLeft(() => new EntityAddressError("deleteAddressError")).map(() => true);
    }
}
