export enum ExpenseTypeEnum {
    NONE = "NONE",
    PERSONNEL_EXPENSES = "PERSONNEL EXPENSES",
    TRAVEL = "TRAVEL",
    ACCOMMODATION_MEALS = "ACCOMMODATION_MEALS",
    PERSONNEL_MANAGEMENT_ADMINISTRATION = "PERSONNEL_MANAGEMENT_ADMINISTRATION",
    MAINTENANCE_ACTIVITIES = "MAINTENANCE_ACTIVITIES",
    INVESTMENT = "INVESTMENT",
    MANAGEMENT_ADMINISTRATION = "MANAGEMENT_ADMINISTRATION",
    OTHER_SUBSIDIZABLE_EXPENSES = "OTHER_SUBSIDIZABLE_EXPENSES",
}

export class ExpenseType {
    constructor(
        public readonly id: ExpenseTypeEnum,
        public readonly label: string,
    ) { }
}
