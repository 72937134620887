import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ContractsDatasource } from "@entity/data/datasources/contracts.datasource";
import { ContractsMapperError } from "@entity/domain/errors/contracts/contracts-mapper.error";
import { EntityContractsError } from "@entity/domain/errors/entity.error";
import { CreateEmployeeContract } from "@entity/domain/models/employee/contracts/employee/create-employee-contract.model";
import {
    EmployeeContract,
    EmployeeContractSearchFilters,
    EmployeeContracts,
} from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { CreateFreelanceContract } from "@entity/domain/models/employee/contracts/freelance/create-freelance-contract.model";
import {
    FreelanceContract,
    FreelanceContractSearchFilters,
    FreelanceContracts,
} from "@entity/domain/models/employee/contracts/freelance/freelance-contract.model";
import { CreateVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/create-volunteer-contract.model";
import {
    VolunteerContract,
    VolunteerContractSearchFilters,
    VolunteerContracts,
} from "@entity/domain/models/employee/contracts/volunteer/volunteer-contract.model";
import { inject, injectable } from "inversify";

@injectable()
export class ContractsRepository {
    constructor(
        @inject(ContractsDatasource)
        private readonly contractsDatasource: ContractsDatasource,
    ) { }

    async getAllFreelanceContractsBy(
        pagination: Pagination,
        filters?: FreelanceContractSearchFilters,
    ): Promise<Either<EntityContractsError, FreelanceContracts>> {
        return this.contractsDatasource.fetchAllFreelanceContractsBy(
            pagination,
            filters,
        );
    }

    async getAllVolunteerContractsBy(
        pagination: Pagination,
        filters?: VolunteerContractSearchFilters,
    ): Promise<Either<EntityContractsError, VolunteerContracts>> {
        return this.contractsDatasource.fetchAllVolunteerContractsBy(
            pagination,
            filters,
        );
    }

    async getAllEmployeeContractsBy(
        pagination: Pagination,
        filters?: EmployeeContractSearchFilters,
    ): Promise<Either<EntityContractsError, EmployeeContracts>> {
        return this.contractsDatasource.fetchAllEmployeeContractsBy(
            pagination,
            filters,
        );
    }

    async createFreelanceContract(
        contract: CreateFreelanceContract,
    ): Promise<Either<ValidationError | EntityContractsError | ContractsMapperError, FreelanceContract>> {
        return this.contractsDatasource.createFreelanceContract(contract);
    }

    async createVolunteerContract(
        contract: CreateVolunteerContract,
    ): Promise<Either<ValidationError | EntityContractsError | ContractsMapperError, VolunteerContract>> {
        return this.contractsDatasource.createVolunteerContract(contract);
    }

    async createEmployeeContract(
        contract: CreateEmployeeContract,
    ): Promise<Either<ValidationError | EntityContractsError | ContractsMapperError, EmployeeContract>> {
        return this.contractsDatasource.createEmployeeContract(contract);
    }

    async editFreelanceContract(
        freelanceContract: FreelanceContract,
    ): Promise<Either<ValidationError | EntityContractsError | ContractsMapperError, FreelanceContract>> {
        return this.contractsDatasource.updateFreelanceContract(
            freelanceContract,
        );
    }

    async editVolunteerContract(
        volunteerContract: VolunteerContract,
    ): Promise<Either<ValidationError | EntityContractsError | ContractsMapperError, VolunteerContract>> {
        return this.contractsDatasource.updateVolunteerContract(
            volunteerContract,
        );
    }

    async editEmployeeContract(
        employeeContract: EmployeeContract,
    ): Promise<Either<ValidationError | EntityContractsError | ContractsMapperError, EmployeeContract>> {
        return this.contractsDatasource.updateEmployeeContract(
            employeeContract,
        );
    }

    async deleteFreelanceContract(
        contractId: number,
    ): Promise<Either<ValidationError | EntityContractsError, true>> {
        return this.contractsDatasource.deleteFreelanceContract(contractId);
    }

    async deleteVolunteerContract(
        contractId: number,
    ): Promise<Either<EntityContractsError, true>> {
        return this.contractsDatasource.deleteVolunteerContract(contractId);
    }

    async deleteEmployeeContract(
        contractId: number,
    ): Promise<Either<EntityContractsError, true>> {
        return this.contractsDatasource.deleteEmployeeContract(contractId);
    }
}
