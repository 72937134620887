import { FallbackError } from "@core/domain/errors/fallback.error";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CostIVATypeEnumMapper } from "@entity/data/mappers/cost/cost-iva-type-enum.mapper";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import { EditCost } from "@entity/domain/models/cost/edit-cost.model";
import { CostFormValuesValidated } from "@entity/presentation/component/cost-form/cost-form.component";
import { inject, injectable } from "inversify";
import { EditCostBody } from "../../dto/cost/edit-cost.body";
import { IVATypeEnumDto } from "../../dto/iva-type.dto";
import { IdentificationEnumMapper } from "../deposit/identification-enum.mapper";
import { CostTypeEnumMapper } from "./cost-type-enum.mapper";
import { IRPFTypeEnumMapper } from "./irpf-type-enum.mapper";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type.mapper";

@injectable()
export class EditCostMapper {
    constructor(
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
        @inject(IdentificationEnumMapper)
        private readonly identificationEnumMapper: IdentificationEnumMapper,
        @inject(CostTypeEnumMapper)
        private readonly costTypeEnumMapper: CostTypeEnumMapper,
        @inject(CostIVATypeEnumMapper)
        private readonly costIvaTypeEnumMapper: CostIVATypeEnumMapper,
        @inject(IRPFTypeEnumMapper)
        private readonly irpfTypeEnumMapper: IRPFTypeEnumMapper,
        @inject(ServiceProvidedEnumMapper)
        private serviceProvidedEnumMapper: ServiceProvidedEnumMapper,
    ) { }
    mapFromCostFormValues(
        costFormValuesValidated: CostFormValuesValidated,
        entityId: number,
        costId: number,
    ): EditCost {
        const editCost = new EditCost(
            costId,
            entityId,
            costFormValuesValidated.invoiceNum,
            costFormValuesValidated.concept,
            costFormValuesValidated.socialReason,
            costFormValuesValidated.identificationType,
            costFormValuesValidated.identification,
            costFormValuesValidated.costType,
            costFormValuesValidated.invoiceDate,
            costFormValuesValidated.paymentDate,
            costFormValuesValidated.paymentMethod,
            costFormValuesValidated.base,
            costFormValuesValidated.iva,
            costFormValuesValidated.otherIva ?? 0,
            0,
            costFormValuesValidated.totalCost,
            costFormValuesValidated.irpfType,
            convertEmptyToUndefined(costFormValuesValidated.otherCostType),
            convertEmptyToUndefined(costFormValuesValidated.otherPaymentMethod),
            costFormValuesValidated.documents[0],
            costFormValuesValidated.additionalExpensesToUpdate,
            convertEmptyToUndefined(costFormValuesValidated.category),
            convertEmptyToUndefined(costFormValuesValidated.otherCategory),
        );

        return editCost;
    }

    mapToDto(editCost: EditCost): EditCostBody {
        if (!editCost.paymentMethod || !editCost.issuerIdentificationType) {
            throw new FallbackError();
        }
        const identificationType = this.identificationEnumMapper.mapToDto(
            editCost.issuerIdentificationType,
        );

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            editCost.paymentMethod,
        );

        const type = this.costTypeEnumMapper.mapToDto(editCost.type);

        const iva = this.costIvaTypeEnumMapper.mapToDto(editCost.iva);

        const irpf = editCost.irpfType
            ? this.irpfTypeEnumMapper.mapToDto(editCost.irpfType)
            : "";

        const categories = editCost.category
            ? editCost.category.map((cat) =>
                this.serviceProvidedEnumMapper.mapToDto(cat),
            )
            : undefined;

        return {
            id: editCost.id,
            number: editCost.invoiceNum,
            concept: editCost.concept,
            issuer_name: editCost.issuerName,
            issuer_identification_type: identificationType,
            issuer_identification_number: editCost.identificationNumber,
            type: type,
            others_type: editCost.othersType ?? "",
            issue_date: convertEmptyToUndefined(editCost.invoiceDate?.toISO()),
            payment_date: convertEmptyToUndefined(
                editCost.paymentDate?.toISO(),
            ),
            payment_method: convertEmptyToUndefined(paymentMethod),
            other_payment_method: editCost.otherPaymentMethod ?? "",
            base: editCost.base ?? 0,
            iva: iva ?? IVATypeEnumDto.Cero,
            iva_other: convertEmptyToUndefined(editCost.otherIva),
            total: convertEmptyToUndefined(editCost.total),
            // discount: editCost.discount ?? 0,
            irpf_type: irpf,
            entity: editCost.entityId,
            document: editCost.document?.id,
            categories,
            other_category: editCost.otherCategory,
        };
    }
}
