import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/create-contract-termination.mapper";
import { EditContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/edit-contract-termination.mapper";
import {
    EmployeeContract,
    type EmployeeContractSearchFilters,
} from "@entity/domain/models/employee/contracts/employee/employee-contract.model";
import { ContractTermination } from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { EmployeeTypeEnum } from "@entity/domain/models/employee/employee-type.model";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { GetAllEmployeeContractsByUseCase } from "@entity/domain/usecases/employee/contracts/employee/get-all-employee-contracts-by.usecase";
import { CreateContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/create-contract-termination.usecase";
import { DeleteContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/delete-contract-termination.usecase";
import { EditContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/edit-contract-termination.usecase";
import { GetAllContractTerminationsUseCase } from "@entity/domain/usecases/employee/contracts/termination/get-all-contract-terminations.usecase";
import { GetAllTerminationReasonsUseCase } from "@entity/domain/usecases/employee/get-all-termination-reasons.usecase";
import { ContractTerminationFormValuesValidated } from "@entity/presentation/component/employee-form/contract-termination/form/contract-termination-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ContractTerminationTabViewModel extends BaseViewModel {
    employeeId: Undefinable<number> = undefined;
    employeeType: Nullable<string> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    showAddContractTerminationModal: boolean = false;

    @observable
    showEditContractTerminationModal: boolean = false;

    @observable
    contractTerminations: ContractTermination[] = [];

    @observable
    terminationReasons: TerminationReason[] = [];

    @observable
    contractTerminationToEdit: Undefinable<ContractTermination> = undefined;

    @observable
    employeeContracts: EmployeeContract[] = [];

    filtersValue: EmployeeContractSearchFilters = {
        employee: undefined,
    };

    @observable
    filters: EmployeeContractSearchFilters = this.filtersValue;

    constructor(
        @inject(DeleteContractTerminationUseCase)
        private readonly deleteContractTerminationUseCase: DeleteContractTerminationUseCase,
        @inject(CreateContractTerminationUseCase)
        private readonly createContractTerminationUseCase: CreateContractTerminationUseCase,
        @inject(CreateContractTerminationMapper)
        private readonly createContractTerminationMapper: CreateContractTerminationMapper,
        @inject(EditContractTerminationUseCase)
        private readonly editContractTerminationUseCase: EditContractTerminationUseCase,
        @inject(EditContractTerminationMapper)
        private readonly editContractTerminationMapper: EditContractTerminationMapper,
        @inject(GetAllContractTerminationsUseCase)
        private readonly getAllContractTerminationsUseCase: GetAllContractTerminationsUseCase,
        @inject(GetAllTerminationReasonsUseCase)
        private readonly getAllTerminationReasonsUseCase: GetAllTerminationReasonsUseCase,
        @inject(GetAllEmployeeContractsByUseCase)
        private readonly getAllEmployeeContractsByUseCase: GetAllEmployeeContractsByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await this.getContracts();

        await Promise.all([
            this.getContractTerminations(),
            this.getTerminationReasons(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getContractTerminations(): Promise<void> {
        const contractTerminations = await this.getAllContractTerminationsUseCase.execute();

        runInAction(() => {
            this.contractTerminations = contractTerminations.filter(termination => {
                const associatedContract = this.employeeContracts.find(
                    contract => contract.id === termination.contractId
                );
                return !!associatedContract;
            });
        });
    }

    async getTerminationReasons(): Promise<void> {
        const terminationReasons =
            await this.getAllTerminationReasonsUseCase.execute();

        runInAction(() => {
            this.terminationReasons = terminationReasons;
        });
    }

    @action
    setShowAddContractTerminationModal(show: boolean): void {
        this.showAddContractTerminationModal = show;
    }

    @action
    closeEditContractTerminationModal(): void {
        this.showEditContractTerminationModal = false;
    }

    @action
    openEditContractTerminationModal(
        contractTermination: Undefinable<ContractTermination>,
    ): void {
        this.contractTerminationToEdit = contractTermination;
        this.showEditContractTerminationModal = true;
    }

    async addContractTermination(
        values: ContractTerminationFormValuesValidated,
    ): Promise<Nullable<ContractTermination>> {
        LoadLayoutStore.start();

        const createContractResult =
            await this.createContractTerminationUseCase.execute(
                this.createContractTerminationMapper.mapFromFormValues(values),
            );

        if (createContractResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createContractResult;
    }

    async editContractTermination(
        values: ContractTerminationFormValuesValidated,
    ): Promise<void> {
        if (
            !this.contractTerminationToEdit?.id ||
            !this.contractTerminationToEdit.contractId
        )
            throw Error("Missing contractTermination");

        LoadLayoutStore.start();
        const editContractTerminationResult =
            await this.editContractTerminationUseCase.execute(
                this.editContractTerminationMapper.mapFromContractTerminationFormValues(
                    this.contractTerminationToEdit.id,
                    values,
                ),
            );

        if (editContractTerminationResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteContractTermination(
        contractTerminationId: number,
    ): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteContractTerminationUseCase.execute(
            contractTerminationId,
        );
        LoadLayoutStore.finish();
    }

    async reloadContractTerminations(): Promise<void> {
        await this.getContractTerminations();
    }

    @action
    setEmployeeId(employeeId: number): void {
        this.employeeId = employeeId;
        this.filters = {
            ...this.filters,
            employee: employeeId,
        };
    }

    @action
    setEmployeeType(employeeType: string): void {
        this.employeeType = employeeType;
    }

    async getContracts(): Promise<void> {
        if (this.employeeType === EmployeeTypeEnum.Employee) {
            await this.getEmployeeContracts();
        }
    }

    async getEmployeeContracts(): Promise<void> {
        const employeeContracts =
            await this.getAllEmployeeContractsByUseCase.execute(this.filters);
        runInAction(() => {
            this.employeeContracts = employeeContracts;
        });
    }
}
