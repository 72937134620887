import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { AddressDatasource } from "@entity/data/datasources/address.datasource";
import { AddressMapperError } from "@entity/domain/errors/address/address-mapper.error";
import { EntityAddressError } from "@entity/domain/errors/entity.error";
import {
    Address,
    Addresses,
} from "@entity/domain/models/address/address.model";
import { CreateAddress } from "@entity/domain/models/address/create-address.model";
import { EditAddress } from "@entity/domain/models/address/edit-address.model";
import { inject, injectable } from "inversify";

@injectable()
export class AddressRepository {
    constructor(
        @inject(AddressDatasource)
        private readonly addressDatasource: AddressDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityAddressError, Addresses>> {
        const materialResult =
            await this.addressDatasource.fetchAll(pagination);

        return materialResult.mapLeft(() => new EntityAddressError("fetchAllAddressesError"));
    }

    async create(
        address: CreateAddress,
    ): Promise<Either<ValidationError | EntityAddressError | AddressMapperError, Address>> {
        return this.addressDatasource.create(address);
    }

    async edit(
        editAddress: EditAddress,
    ): Promise<Either<ValidationError | EntityAddressError | AddressMapperError, Address>> {
        return this.addressDatasource.edit(editAddress);
    }

    async delete(addressId: number): Promise<Either<EntityAddressError, true>> {
        const addressResult = await this.addressDatasource.delete(addressId);
        return addressResult.mapLeft(() => new EntityAddressError("deleteAddressError"));
    }
}
