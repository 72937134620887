import { MaritalStatusDatasource } from "@beneficiary/data/datasource/marital-status.datasoruce";
import { BeneficiaryMaritalStatusError } from "@beneficiary/domain/errors/beneficiary.error";
import { MaritalStatus } from "@beneficiary/domain/models/marital-status.model";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { inject, injectable } from "inversify";

@injectable()
export class MaritalStatusRepository {
    private maritalStatus: Undefinable<MaritalStatus[]>;

    constructor(
        @inject(MaritalStatusDatasource)
        private readonly maritalStatusDatasoruce: MaritalStatusDatasource,
    ) { }

    async getAllMaritalStatuses(): Promise<
        Either<BeneficiaryMaritalStatusError, MaritalStatus[]>
    > {
        if (this.maritalStatus) {
            return Either.Right(this.maritalStatus);
        }

        const maritalStatusResult =
            await this.maritalStatusDatasoruce.fetchAllMaritalStatuses();

        if (maritalStatusResult.isRight()) {
            this.maritalStatus = maritalStatusResult.getOrThrow();
        }

        return maritalStatusResult;
    }
}
