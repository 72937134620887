import { Nullable } from "@core/domain/types/nullable.type";
import { EditExpenseAllTypesBody } from "@project/data/dto/expenses/edit-expense.body";
import {
    EditAccommodationExpense,
    EditExpenseAllTypes,
    EditInvestmentExpense,
    EditMaintenanceExpense,
    EditManagementExpense,
    EditOtherSubsidizableExpense,
    EditPersonnelExpense,
    EditTravelExpense,
} from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { ExpenseAccommodationFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/accommodation/expense-accommodation-form.component";
import { ExpensesFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/expense-modal-body.component";
import { ExpenseInvestmentFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/investment/expense-investment-form.component";
import { ExpenseMaintenanceFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/maintenance/expense-maintenance-form.component";
import { ExpenseManagementFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/management/expense-management-form.component";
import { ExpensePersonnelFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/personnel/expense-personnel-form.component";
import { ExpenseTravelFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/travels/expense-travel-form.component";
import { inject, injectable } from "inversify";
import { AccommodationExpenseMapper } from "./accommodation-expense.mapper";
import { InvestmentExpenseMapper } from "./investment-expense.mapper";
import { MaintenanceExpenseMapper } from "./maintenance-expense.mapper";
import { ManagementExpenseMapper } from "./management-expense.mapper";
import { PersonnelExpenseMapper } from "./personnel-expense.mapper";
import { TravelExpenseMapper } from "./travel-expense.mapper";
import { ExpenseOtherSubsidizableFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/otherSubsidizable/expense-otherSubsidizable-form.component";
import { OtherSubsidizableExpenseMapper } from "./otherSubsidizable-expense.mapper";

@injectable()
export class EditExpenseMapper {
    constructor(
        @inject(InvestmentExpenseMapper)
        private readonly investmentExpenseMapper: InvestmentExpenseMapper,
        @inject(MaintenanceExpenseMapper)
        private readonly maintenanceExpenseMapper: MaintenanceExpenseMapper,
        @inject(TravelExpenseMapper)
        private readonly travelExpenseMapper: TravelExpenseMapper,
        @inject(AccommodationExpenseMapper)
        private readonly accommodationExpenseMapper: AccommodationExpenseMapper,
        @inject(ManagementExpenseMapper)
        private readonly managementExpenseMapper: ManagementExpenseMapper,
        @inject(PersonnelExpenseMapper)
        private readonly personnelExpenseMapper: PersonnelExpenseMapper,
        @inject(OtherSubsidizableExpenseMapper)
        private readonly otherSubsidizableExpenseMapper: OtherSubsidizableExpenseMapper,
    ) { }

    mapFromFormValues(
        editFormValues: ExpensesFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): Nullable<EditExpenseAllTypes> {
        switch (editFormValues.__type) {
            case "INVESTMENT":
                return this.investmentExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpenseInvestmentFormValuesValidated,
                    expenseId,
                    projectId,
                );
            case "MAINTENANCE_ACTIVITIES":
                return this.maintenanceExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpenseMaintenanceFormValuesValidated,
                    expenseId,
                    projectId,
                );
            case "MANAGEMENT_ADMINISTRATION":
                return this.managementExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpenseManagementFormValuesValidated,
                    expenseId,
                    projectId,
                );
            case "ACCOMMODATION_MEALS":
                return this.accommodationExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpenseAccommodationFormValuesValidated,
                    expenseId,
                    projectId,
                );
            case "TRAVEL":
                return this.travelExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpenseTravelFormValuesValidated,
                    expenseId,
                    projectId,
                );
            case "PERSONNEL_MANAGEMENT_ADMINISTRATION":
                return this.personnelExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpensePersonnelFormValuesValidated,
                    expenseId,
                    projectId,
                );
            case "OTHER_SUBSIDIZABLE_EXPENSES":
                return this.otherSubsidizableExpenseMapper.mapFromFormEditValues(
                    editFormValues as ExpenseOtherSubsidizableFormValuesValidated,
                    expenseId,
                    projectId,
                );
            default:
                return null;
        }
    }

    mapToDto(
        editFormValues: EditExpenseAllTypes,
    ): Nullable<EditExpenseAllTypesBody> {
        switch (editFormValues.typeId) {
            case ExpenseTypeEnum.INVESTMENT:
                return this.investmentExpenseMapper.mapToEditDto(
                    editFormValues as EditInvestmentExpense,
                );
            case ExpenseTypeEnum.MAINTENANCE_ACTIVITIES:
                return this.maintenanceExpenseMapper.mapToEditDto(
                    editFormValues as EditMaintenanceExpense,
                );
            case ExpenseTypeEnum.MANAGEMENT_ADMINISTRATION:
                return this.managementExpenseMapper.mapToEditDto(
                    editFormValues as EditManagementExpense,
                );
            case ExpenseTypeEnum.ACCOMMODATION_MEALS:
                return this.accommodationExpenseMapper.mapToEditDto(
                    editFormValues as EditAccommodationExpense,
                );
            case ExpenseTypeEnum.TRAVEL:
                return this.travelExpenseMapper.mapToEditDto(
                    editFormValues as EditTravelExpense,
                );
            case ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION:
                return this.personnelExpenseMapper.mapToEditDto(
                    editFormValues as EditPersonnelExpense,
                );
            case ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES:
                return this.otherSubsidizableExpenseMapper.mapToEditDto(
                    editFormValues as EditOtherSubsidizableExpense,
                );
            default:
                return null;
        }
    }
}
