import { Gender } from "@beneficiary/domain/models/gender.model";
import { GetAllGendersUseCase } from "@beneficiary/domain/usecases/get-all-genders.usecase";
import { IdentificationType } from "@core/domain/models/identification-document-type.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { GetAllIdentificationDocumentTypesUseCase } from "@core/domain/usecases/get-all-identification-document-types.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EmployeeType } from "@entity/domain/models/employee/employee-type.model";
import { GetAllEmployeeTypesUseCase } from "@entity/domain/usecases/employee/get-all-employee-types.usecase";
import { ProjectEmployeeMapper } from "@project/data/mappers/project-employee.mapper";
import {
    CreateProjectEmployeeDedication,
    UpdateProjectEmployeeDedication,
} from "@project/domain/models/project-employee-dedications.model";
import { ProjectEmployee } from "@project/domain/models/project-employees.model";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { CreateProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/create-project-employee-dedication.usecase";
import { UpdateProjectEmployeeDedicationUsecase } from "@project/domain/usecases/project-employee-dedication/update-project-employee-dedication.usecase";
import { CreateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/create-project-employee.usecase";
import { DeleteProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/delete-project-employee.usecase";
import { GetAllProjectEmployeesByUseCase } from "@project/domain/usecases/project-employee/get-all-project-employees-by.usecase";
import { UpdateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/update-project-employee.usecase";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ProjectEmployeeFormValuesValidated } from "./form/add-employee-from-entity-modal-form.view";
import { SearchPostalCodesUseCase } from "@postal-codes/domain/usecases/postal-code.usecase";
import { PostalCode } from "@postal-codes/domain/models/postal-code.model";

export interface ProjectEmployeeDedicationList {
    projectEmployeeId: number;
    projectEmployeeDedicationId: number;
    projectId: number;
    hoursDedicated: number;
    startDate: DateTime;
    endDate: DateTime;
}

export interface ProjectListTable {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    hoursDedicated: number;
    projectEmployeeDedication?: ProjectEmployeeDedicationList | undefined;
}

export interface ProjectsListTable {
    projects: ProjectListTable[];
    count: number;
}

@injectable()
export class EmployeeGeneralTabViewModel extends BaseViewModel {
    @observable
    employeeId: Nullable<number> = null;

    @observable
    initialLoading: boolean = true;

    @observable
    employeeTypes: EmployeeType[] = [];

    @observable
    identificationTypes: IdentificationType[] = [];

    @observable
    genders: Gender[] = [];

    @observable
    showAddEmployeeModal: boolean = false;

    @observable
    projects: ProjectSummary[] = [];

    @observable
    _projectEmployees: ProjectEmployee[] = [];

    @observable
    projectEmployeeDedication: Undefinable<ProjectEmployeeDedicationList> =
    undefined;

    @computed
    get projectsTable(): ProjectsListTable {
        return {
            count: this.projects.length,
            projects: this._projectEmployees
                .map((projectEmployee) => {
                    const project = this.projects.find(
                        (proj) => proj.id === projectEmployee.projectId,
                    );

                    return projectEmployee.dedications.map((dedication) => {
                        const projectListTable: ProjectListTable = {
                            id: projectEmployee.projectId,
                            name: project?.name ?? "",
                            startDate: dedication.startDate.toLocaleString(),
                            endDate: dedication.endDate.toLocaleString(),
                            hoursDedicated: dedication.hoursDedicated,
                            projectEmployeeDedication: {
                                projectEmployeeDedicationId: dedication.id,
                                projectEmployeeId: projectEmployee.id,
                                projectId: projectEmployee.projectId,
                                hoursDedicated: dedication.hoursDedicated,
                                startDate: dedication.startDate,
                                endDate: dedication.endDate,
                            },
                        };

                        return projectListTable;
                    });
                })
                .flat(),
        };
    }

    @computed
    get projectsSelect(): ProjectSummary[] {
        return this.projects;
    }

    // eslint-disable-next-line max-params
    constructor(
        @inject(GetAllIdentificationDocumentTypesUseCase)
        private readonly getAllIdentificationDocumentTypesUseCase: GetAllIdentificationDocumentTypesUseCase,
        @inject(GetAllEmployeeTypesUseCase)
        private readonly getAllEmployeeTypesUseCase: GetAllEmployeeTypesUseCase,
        @inject(GetAllGendersUseCase)
        private readonly getAllGendersUseCase: GetAllGendersUseCase,
        @inject(GetAllProjectEmployeesByUseCase)
        private readonly getAllProjectEmployeesUseCase: GetAllProjectEmployeesByUseCase,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
        @inject(DeleteProjectEmployeeUseCase)
        private readonly deleteProjectEmployeeUseCase: DeleteProjectEmployeeUseCase,
        @inject(CreateProjectEmployeeUseCase)
        private readonly createProjectEmployeeUseCase: CreateProjectEmployeeUseCase,
        @inject(CreateProjectEmployeeDedicationUseCase)
        private readonly createProjectEmployeeDedicationUseCase: CreateProjectEmployeeDedicationUseCase,
        @inject(UpdateProjectEmployeeDedicationUsecase)
        private readonly updateProjectEmployeeDedicationUsecase: UpdateProjectEmployeeDedicationUsecase,
        @inject(UpdateProjectEmployeeUseCase)
        private readonly updateProjectEmployeeUseCase: UpdateProjectEmployeeUseCase,
        @inject(ProjectEmployeeMapper)
        private readonly projectEmployeeMapper: ProjectEmployeeMapper,
        @inject(SearchPostalCodesUseCase)
        private readonly searchPostalCodesUseCase: SearchPostalCodesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllEmployeeTypes(),
            this.getAllIdentificationDocumentTypes(),
            this.getAllGenders(),
            this.getAllEmployeeProjects(),
            this.getAllProjects(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setProjectEmployeeDedicationList(
        projectEmployeeDedication?: Undefinable<ProjectEmployeeDedicationList>,
    ): void {
        this.projectEmployeeDedication = projectEmployeeDedication;
    }

    @action
    setEmployeeId(employeeId: Nullable<number>): void {
        this.employeeId = employeeId;
    }

    async reloadEmployeeProjects(): Promise<void> {
        await this.getAllEmployeeProjects();
    }

    async getAllEmployeeProjects(): Promise<void> {
        if (!this.employeeId) return;
        const projectEmployees =
            await this.getAllProjectEmployeesUseCase.execute({
                employeeId: this.employeeId,
            });

        runInAction(() => {
            this._projectEmployees = projectEmployees;
        });
    }

    async getAllIdentificationDocumentTypes(): Promise<void> {
        const identificationDocumentTypes =
            await this.getAllIdentificationDocumentTypesUseCase.execute();

        runInAction(() => {
            this.identificationTypes = identificationDocumentTypes;
        });
    }

    async getAllEmployeeTypes(): Promise<void> {
        const employeeTypes = await this.getAllEmployeeTypesUseCase.execute();

        runInAction(() => {
            this.employeeTypes = employeeTypes;
        });
    }

    async getAllGenders(): Promise<void> {
        const genders = await this.getAllGendersUseCase.execute();

        runInAction(() => {
            this.genders = genders;
        });
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllProjectsUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }

    @action
    setShowAddEmployeeModal(show: boolean): void {
        this.showAddEmployeeModal = show;
    }

    async createProjectEmployee(
        values: ProjectEmployeeFormValuesValidated,
    ): Promise<boolean> {
        if (!values.projectId) throw new Error("Project id is not defined");

        let projectEmployee = this._projectEmployees.find(
            (_projectEmployee) =>
                _projectEmployee.projectId === values.projectId &&
                _projectEmployee.employee.id === values.employeeId,
        );

        LoadLayoutStore.start();

        if (!projectEmployee) {
            projectEmployee =
                (await this.createProjectEmployeeUseCase.execute(
                    this.projectEmployeeMapper.mapFromFormToCreate(
                        values.projectId,
                        values,
                    ),
                )) ?? undefined;
        }

        if (projectEmployee?.id) {
            const projectEmployeeDedicationCreated =
                await this.createProjectEmployeeDedicationUseCase.execute(
                    new CreateProjectEmployeeDedication(
                        projectEmployee.id,
                        values.dedication.hoursDedicated,
                        values.dedication.startDate,
                        values.dedication.endDate,
                    ),
                );

            if (projectEmployeeDedicationCreated) {
                ToastManagerStore.success();
                this.setShowAddEmployeeModal(false);
                this.reloadEmployeeProjects();
            }
        }

        LoadLayoutStore.finish();

        return !!projectEmployee?.id;
    }

    async editProjectEmployee(
        values: ProjectEmployeeFormValuesValidated,
    ): Promise<boolean> {
        if (!values.projectId) throw new Error("Project id is not defined");

        LoadLayoutStore.start();

        const projectEmployee =
            this.projectEmployeeMapper.mapFromFormEntityToUpdate(values);

        const projectEmployeeEdited =
            await this.updateProjectEmployeeUseCase.execute(projectEmployee);

        if (projectEmployeeEdited?.id) {
            if (!values.projectEmployeeDedicationId)
                throw new Error(
                    "Project employee dedication id is not defined",
                );
            if (!values.projectEmployeeId)
                throw new Error("Project employee id is not defined");
            const projectEmployeeDedicationEdited =
                await this.updateProjectEmployeeDedicationUsecase.execute(
                    new UpdateProjectEmployeeDedication(
                        values.projectEmployeeDedicationId,
                        values.projectEmployeeId,
                        values.dedication.hoursDedicated,
                        values.dedication.startDate,
                        values.dedication.endDate,
                    ),
                );

            if (projectEmployeeDedicationEdited) {
                ToastManagerStore.success();
                this.setShowAddEmployeeModal(false);
                this.reloadEmployeeProjects();
            }
        }

        LoadLayoutStore.finish();

        return !!projectEmployeeEdited?.id;
    }

    async deleteProject(projectId: number): Promise<void> {
        const employeeProjectId = this._projectEmployees.find(
            (projectEmployee) => projectEmployee.projectId === projectId,
        )?.id;

        if (!employeeProjectId) return;

        LoadLayoutStore.start();

        await this.deleteProjectEmployeeUseCase.execute(employeeProjectId);

        LoadLayoutStore.finish();
    }

    async searchPostalCodes(query: string): Promise<PostalCode[]> {
        return this.searchPostalCodesUseCase.execute(query);
    }
}
