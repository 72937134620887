import { ContainerModule } from "inversify";
import { PostalCodeMapper } from "./data/mappers/postal-code.mapper";
import { PostalCodeDatasource } from "./data/datasources/postal-codes.datasource";
import { SearchPostalCodesUseCase } from "./domain/usecases/postal-code.usecase";
import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { PostalCodeRepository } from "./data/repository/postal-code.repository";


const postalCodesModule = new DiModuleBuilder();

postalCodesModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(PostalCodeDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(PostalCodeMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(PostalCodeRepository).toSelf().inSingletonScope();
    }),
    useCases: new ContainerModule((bind) => {
        bind(SearchPostalCodesUseCase).toSelf();
    }),
}));

postalCodesModule.registerModule();

export { postalCodesModule };