import { coreTypes } from "@core/core-types.di";
import { PaginatedQueryDto } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CostPeriodicityType } from "../../domain/models/locals/cost-periodicity.model";
import {
    CreateLocal,
    EditLocal,
    Local,
    Locals,
} from "../../domain/models/locals/local.model";
import { StatusType } from "../../domain/models/locals/status.model";
import { CostPeriodicityTypeDto } from "../dto/locals/cost-periodicity.dto";
import { CreateLocalBody } from "../dto/locals/create-local.body";
import { EditLocalBody } from "../dto/locals/edit-local.body";
import { LocalDto, LocalsDto } from "../dto/locals/local.dto";
import { StatusTypeDto } from "../dto/locals/status.dto";
import { CostPeriodicityTypeMapper } from "../mappers/locals/cost-periodicity-type.mapper";
import { LocalMapper } from "../mappers/locals/local.mapper";
import { LocalsMapper } from "../mappers/locals/locals.mapper";
import { RoomMapper } from "../mappers/locals/room.mapper";
import { StatusTypeMapper } from "../mappers/locals/status-type.mapper";
import { EntityLocalError } from "@entity/domain/errors/entity.error";
import { LocalMapperError } from "@entity/domain/errors/local/local-mapper.error";

const LOCAL_PATH = "/locals/";
const COST_PERIODICITY_PATH = "/locals/costs_periodicity/";
const STATUS_PATH = "/locals/status/";

@injectable()
export class LocalDatasource {
    constructor(
        @inject(LocalMapper)
        private readonly localMapper: LocalMapper,
        @inject(LocalsMapper)
        private readonly localsMapper: LocalsMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(CostPeriodicityTypeMapper)
        private readonly costPeriodicityTypeMapper: CostPeriodicityTypeMapper,
        @inject(StatusTypeMapper)
        private readonly statusTypeMapper: StatusTypeMapper,
        @inject(RoomMapper)
        private readonly roomMapper: RoomMapper,
    ) { }

    async fetchAll(): Promise<Either<EntityLocalError, Local[]>> {
        const localsResult = await this.http.get<LocalsDto>(LOCAL_PATH);

        return localsResult
            .mapLeft(() => new EntityLocalError("fetchLocalsError"))
            .map((response) => {
                const locales = response.data.results.mapNotNull((local) =>
                    this.localMapper.map(plainToClass(LocalDto, local)),
                );

                return locales;
            });
    }

    async fetchById(localId: number): Promise<Either<HttpError | EntityLocalError | LocalMapperError, Local>> {
        const localResult = await this.http.get<LocalDto>(
            `${LOCAL_PATH}${localId}/`,
        );

        return localResult
            .mapLeft(() => new EntityLocalError("fetchLocalError"))
            .flatMap((response) => {
                const local = this.localMapper.map(
                    plainToClass(LocalDto, response.data),
                );

                if (!local) return Either.Left(new LocalMapperError());

                return Either.Right(local);
            });
    }

    async fetchAllPaginated(
        limit?: number,
        offset?: number,
    ): Promise<Either<HttpError, Locals>> {
        const query: PaginatedQueryDto = {
            limit,
            offset,
        };
        const localsResult = await this.http.get<LocalsDto>(LOCAL_PATH, {
            query,
        });

        return localsResult.map((response) =>
            this.localsMapper.map(plainToClass(LocalsDto, response.data)),
        );
    }

    async fetchAllCostPeriodicities(): Promise<
        Either<HttpError, CostPeriodicityType[]>
    > {
        const costPeriodicityResult = await this.http.get<
            CostPeriodicityTypeDto[]
        >(COST_PERIODICITY_PATH);

        return costPeriodicityResult.map((response) =>
            response.data.mapNotNull((costPeriodicity) =>
                this.costPeriodicityTypeMapper.map(
                    plainToClass(CostPeriodicityTypeDto, costPeriodicity),
                ),
            ),
        );
    }

    async fetchAllStatusTypes(): Promise<Either<HttpError, StatusType[]>> {
        const statusResult = await this.http.get<StatusTypeDto[]>(STATUS_PATH);

        return statusResult.map((response) =>
            response.data.mapNotNull((status) =>
                this.statusTypeMapper.map(plainToClass(StatusTypeDto, status)),
            ),
        );
    }

    async create(
        createLocal: CreateLocal,
    ): Promise<Either<ValidationError | EntityLocalError | LocalMapperError, Local>> {
        const createdLocalDto = this.localMapper.mapToCreate(createLocal);

        const createLocalResult = await this.http.post<
            LocalDto,
            CreateLocalBody
        >(LOCAL_PATH, createdLocalDto);

        return createLocalResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityLocalError("createLocalError");
            })
            .flatMap((response) => {
                const createdLocal = this.localMapper.map(
                    plainToClass(LocalDto, response.data),
                );

                if (!createdLocal) return Either.Left(new LocalMapperError());

                return Either.Right(createdLocal);
            });
    }

    async edit(
        editLocal: EditLocal,
    ): Promise<Either<ValidationError | EntityLocalError | LocalMapperError, Local>> {
        const editedLocalDto = this.localMapper.mapToEdit(editLocal);

        const createLocalResult = await this.http.patch<
            LocalDto,
            EditLocalBody
        >(`${LOCAL_PATH}${editLocal.id}/`, editedLocalDto);

        return createLocalResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityLocalError("editLocalError");
            })
            .flatMap((response) => {
                const createdLocal = this.localMapper.map(
                    plainToClass(LocalDto, response.data),
                );

                if (!createdLocal) return Either.Left(new LocalMapperError());

                return Either.Right(createdLocal);
            });
    }

    async delete(localId: number): Promise<Either<EntityLocalError, boolean>> {
        const deleteLocalResult = await this.http.delete(
            `${LOCAL_PATH}${localId}/`,
        );

        return deleteLocalResult
            .mapLeft(() => new EntityLocalError("deleteLocalError"))
            .map(() => true);
    }
}
