import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { ContactDatasource } from "@entity/data/datasources/contact.datasource";
import { ContactMapperError } from "@entity/domain/errors/contact/contact-mapper.error";
import { EntityContactError } from "@entity/domain/errors/entity.error";
import {
    Contact,
    CreateContactBody,
} from "@proceeding/domain/models/contact.model";
import { inject, injectable } from "inversify";

@injectable()
export class ContactRepository {
    constructor(
        @inject(ContactDatasource)
        private readonly contactDatasource: ContactDatasource,
    ) { }

    async getAll(): Promise<Either<EntityContactError, Contact[]>> {
        const contactResult = await this.contactDatasource.fetchAll();

        return contactResult
            .mapLeft(() => new EntityContactError("fetchAllContactsError"))
            .map((contact) => contact);
    }

    async create(
        contact: CreateContactBody,
    ): Promise<Either<ValidationError | EntityContactError | ContactMapperError, Contact>> {
        return this.contactDatasource.create(contact);
    }

    async edit(
        contact: Contact,
    ): Promise<Either<ValidationError | EntityContactError | ContactMapperError, Contact>> {
        return this.contactDatasource.edit(contact);
    }

    async delete(id: number): Promise<Either<EntityContactError, true>> {
        return this.contactDatasource.delete(id);
    }
}
