import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { IVATypeEnum } from "@entity/domain/models/cost/iva-type.model";
import { DateTime } from "luxon";
import { PaymentMethodTypeEnum } from "../payment-method-type.model";
import { ContractTypeEnum } from "./contract-type.model";
import { ContributionGroupTypeEnum } from "./contribution-group-type.model";
import { DietTypeEnum } from "./diet-type.model";
import { ExpenseTypeEnum } from "./expense-type.model";
import { MonthTypeEnum } from "./month-type.model";
import { ExpenseSubTypeEnum } from "./sub-type.model";
import { TransportTypeEnum } from "./transport-type.model";

export type ExpenseAllTypes =
    | InvestmentExpense
    | OtherSubsidizableExpense
    | PersonnelExpense
    | MaintenanceExpense
    | ManagementExpense
    | AccommodationExpense
    | TravelExpense;

export class Expense {
    constructor(
        public readonly id: number,
        public readonly typeId: ExpenseTypeEnum,
        public typeLabel: string,
        public readonly percentageImputation: Nullable<number>,
        public readonly amountImputation: Nullable<number>,
        public readonly paymentDate: Nullable<DateTime>,
        public readonly concept?: string,
        public readonly documents: IncDocument[] = [],
        public readonly projectId?: number,
    ) { }
}

export class InvestmentExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly subtypeExpense: Nullable<ExpenseSubTypeEnum>,
        public subTypeLabel: string,
        public readonly provider: string,
        public readonly nifProvider: Nullable<string>,
        public readonly description: Nullable<string>,
        public readonly invoiceNumber: Nullable<string>,
        public readonly invoiceDate: Nullable<DateTime>,
        public readonly expenseDate: Nullable<DateTime>,
        public readonly paymentMethod: Nullable<PaymentMethodTypeEnum>,
        public readonly taxBase: Nullable<number>,
        public readonly iva: Nullable<IVATypeEnum>,
        public readonly totalTaxWithIva: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.INVESTMENT,
    ) {
        super(
            id,
            ExpenseTypeEnum.INVESTMENT,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}

export class OtherSubsidizableExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly provider: string,
        public readonly nifProvider: Nullable<string>,
        public readonly description: Nullable<string>,
        public readonly invoiceNumber: Nullable<string>,
        public readonly invoiceDate: Nullable<DateTime>,
        public readonly expenseDate: Nullable<DateTime>,
        public readonly paymentMethod: Nullable<PaymentMethodTypeEnum>,
        public readonly taxBase: Nullable<number>,
        public readonly iva: Nullable<IVATypeEnum>,
        public readonly totalTaxWithIva: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES,
    ) {
        super(
            id,
            ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}

export class MaintenanceExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly subtypeExpense: Nullable<ExpenseSubTypeEnum>,
        public subTypeLabel: string,
        public readonly provider: string,
        public readonly nifProvider: Nullable<string>,
        public readonly description: Nullable<string>,
        public readonly invoiceNumber: Nullable<string>,
        public readonly invoiceDate: Nullable<DateTime>,
        public readonly expenseDate: Nullable<DateTime>,
        public readonly paymentMethod: Nullable<PaymentMethodTypeEnum>,
        public readonly taxBase: Nullable<number>,
        public readonly iva: Nullable<IVATypeEnum>,
        public readonly totalTaxWithIva: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.MAINTENANCE_ACTIVITIES,
    ) {
        super(
            id,
            ExpenseTypeEnum.MAINTENANCE_ACTIVITIES,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}

export class ManagementExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly subtypeExpense: Nullable<ExpenseSubTypeEnum>,
        public subTypeLabel: string,
        public readonly provider: string,
        public readonly nifProvider: Nullable<string>,
        public readonly description: Nullable<string>,
        public readonly invoiceNumber: Nullable<string>,
        public readonly invoiceDate: Nullable<DateTime>,
        public readonly expenseDate: Nullable<DateTime>,
        public readonly paymentMethod: Nullable<PaymentMethodTypeEnum>,
        public readonly taxBase: Nullable<number>,
        public readonly iva: Nullable<IVATypeEnum>,
        public readonly totalTaxWithIva: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.MANAGEMENT_ADMINISTRATION,
    ) {
        super(
            id,
            ExpenseTypeEnum.MANAGEMENT_ADMINISTRATION,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}

export class TravelExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly dni: Nullable<string>,
        public readonly name: Nullable<string>,
        public readonly address: Nullable<string>,
        public readonly positionFunction: Nullable<string>,
        public readonly travelDestination: Nullable<string>,
        public readonly dateDeparture: Nullable<DateTime>,
        public readonly dateReturn: Nullable<DateTime>,
        public readonly transport: TransportTypeEnum,
        public readonly otherTransport: Nullable<string>,
        public readonly amount: Nullable<number>,
        public readonly kilometers: Nullable<number>,
        public readonly costPerKilometer: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.TRAVEL,
    ) {
        super(
            id,
            ExpenseTypeEnum.TRAVEL,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}

export class AccommodationExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly name: string,
        public readonly dni: Nullable<string>,
        public readonly address: Nullable<string>,
        public readonly position: Nullable<string>,
        public readonly travel: Nullable<string>,
        public readonly startDate: Nullable<DateTime>,
        public readonly endDate: Nullable<DateTime>,
        public readonly diet: Nullable<DietTypeEnum>,
        public readonly days: number,
        public readonly expensePersonDay: Nullable<number>,
        public readonly totalExpense: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.ACCOMMODATION_MEALS,
    ) {
        super(
            id,
            ExpenseTypeEnum.ACCOMMODATION_MEALS,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}

export class PersonnelExpense extends Expense {
    constructor(
        id: number,
        typeLabel: string,
        public readonly subtypeExpense: Nullable<ExpenseSubTypeEnum>,
        public subTypeLabel: string,
        public readonly name: string,
        public readonly dni: string,
        public readonly contractStartDate: Nullable<DateTime>,
        public readonly contractEndDate: Nullable<DateTime>,
        public readonly contractType: Nullable<ContractTypeEnum>,
        public readonly weeklyHours: Nullable<number>,
        // public readonly professionalGroupExp: Nullable<ProfEnum>,
        public readonly contributionGroup: Nullable<ContributionGroupTypeEnum>,
        public readonly month: Nullable<MonthTypeEnum>,
        public readonly daysImputable: Nullable<number>,
        public readonly netSalary: Nullable<number>,
        public readonly irpf: Nullable<number>,
        public readonly socialSecurity: Nullable<number>,
        public readonly monthlyGrossIncome: Nullable<number>,
        public readonly companySocialSecurity: Nullable<number>,
        public readonly totalCost: Nullable<number>,
        percentageImputation: Nullable<number>,
        amountImputation: Nullable<number>,
        paymentDate: Nullable<DateTime>,
        concept?: string,
        documents: IncDocument[] = [],
        projectId?: number,
        public override readonly typeId: ExpenseTypeEnum = ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION,
    ) {
        super(
            id,
            ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION,
            typeLabel,
            percentageImputation,
            amountImputation,
            paymentDate,
            concept,
            documents,
            projectId,
        );
    }
}
