export interface PostalCodeSearchFilters {
    query?: string;
}

export class PostalCode {
    constructor(
        public readonly code: string,
        public readonly city: string,
        public readonly province: string,
    ) { }
}

export class PostalCodes {
    constructor(
        public readonly postalCodes: PostalCode[],
        public readonly count: number,
    ) { }
}

export interface PostalCodeOption {
    label: string;
    value: string;
    postal_code: PostalCode;
}