import { ValidationError } from "@core/domain/errors/validation.error";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { DepositSearchFilters } from "../../domain/models/deposit-search-filters";
import { DepositGeneralTypes } from "../../domain/models/deposit-type.model";
import {
    CreateDeposit,
    CreateSplitDeposit,
    Deposit,
    SplitDeposit,
} from "../../domain/models/deposit.model";
import { IdentificationType } from "../../domain/models/identification-type.model";
import { DepositDatasource } from "../datasources/deposit.datasource";
import { EntityDepositError } from "@entity/domain/errors/entity.error";
import { DepositMapperError } from "@entity/domain/errors/deposit/deposit-mapper.error";

@injectable()
export class DepositRepository {
    constructor(
        @inject(DepositDatasource)
        private readonly depositDatasource: DepositDatasource,
    ) { }

    async getAllDepositTypes(): Promise<
        Either<EntityDepositError, DepositGeneralTypes>
    > {
        return this.depositDatasource.fetchAllDepositTypes();
    }

    async getAllIdentificationDocumentTypes(): Promise<
        Either<EntityDepositError, IdentificationType[]>
    > {
        return this.depositDatasource.getAllIdentificationDocumentTypes();
    }

    async getAll(): Promise<Either<EntityDepositError, Deposit[]>> {
        const depositResult = await this.depositDatasource.fetchAll();

        return depositResult
            .mapLeft(() => new EntityDepositError("fetchDepositsError"))
            .map((deposits) => deposits);
    }

    async getAllBy(
        pagination: Pagination,
        filters?: DepositSearchFilters,
        order?: Order<Deposit>,
    ): Promise<Either<EntityDepositError, Deposit[]>> {
        const deposits = await this.depositDatasource.fetchBy(
            pagination,
            filters,
            order,
        );

        return deposits.mapLeft(() => new EntityDepositError("fetchFilteredDepositsError"));
    }

    async create(
        deposit: CreateDeposit,
    ): Promise<Either<ValidationError | EntityDepositError | DepositMapperError, Deposit>> {
        return this.depositDatasource.create(deposit);
    }

    async edit(
        deposit: Deposit,
    ): Promise<Either<ValidationError | EntityDepositError | DepositMapperError, Deposit>> {
        return this.depositDatasource.edit(deposit);
    }

    async createSplitDeposit(
        splitDeposit: CreateSplitDeposit,
    ): Promise<Either<ValidationError | EntityDepositError | DepositMapperError, SplitDeposit>> {
        return this.depositDatasource.createSplitDeposit(splitDeposit);
    }

    async deleteSplitDeposit(
        depositId: number,
    ): Promise<Either<EntityDepositError, boolean>> {
        return this.depositDatasource.deleteSplitDeposit(depositId);
    }

    async editSplitDeposit(
        splitDeposit: SplitDeposit,
    ): Promise<Either<ValidationError | EntityDepositError | DepositMapperError, SplitDeposit>> {
        return this.depositDatasource.editSplitDeposit(splitDeposit);
    }

    async delete(id: number): Promise<Either<EntityDepositError, boolean>> {
        return this.depositDatasource.delete(id);
    }
}
