import { PublicItineraryDatasource } from "@beneficiary/data/datasource/public-itinerary.datasource";
import { BeneficiaryPublicItineraryError } from "@beneficiary/domain/errors/beneficiary.error";
import { PublicItineraryMapperError } from "@beneficiary/domain/errors/public-itinerary/public-itinerary-mapper.error";
import { CreatePublicItinerary } from "@beneficiary/domain/models/public-itinerary/create-public-itinerary.model";
import { EditPublicItinerary } from "@beneficiary/domain/models/public-itinerary/edit-public-itinerary.model";
import {
    PublicItineraries,
    PublicItinerary,
} from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class PublicItineraryRepository {
    constructor(
        @inject(PublicItineraryDatasource)
        private readonly publicItineraryDatasource: PublicItineraryDatasource,
    ) { }

    async getAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<BeneficiaryPublicItineraryError, PublicItineraries>> {
        return this.publicItineraryDatasource.fetchAllBy(
            beneficiaryId,
            pagination,
        );
    }

    async create(
        publicItinerary: CreatePublicItinerary,
    ): Promise<Either<ValidationError | BeneficiaryPublicItineraryError | PublicItineraryMapperError, PublicItinerary>> {
        return this.publicItineraryDatasource.create(publicItinerary);
    }

    async edit(
        editPublicItinerary: EditPublicItinerary,
    ): Promise<Either<ValidationError | BeneficiaryPublicItineraryError | PublicItineraryMapperError, PublicItinerary>> {
        return this.publicItineraryDatasource.edit(editPublicItinerary);
    }

    async delete(
        publicItineraryId: number,
    ): Promise<Either<BeneficiaryPublicItineraryError, boolean>> {
        return this.publicItineraryDatasource.delete(publicItineraryId);
    }
}
