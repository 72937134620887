import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { IVATypeEnumDto } from "@entity/data/dto/iva-type.dto";
import { IVAEnumMapper } from "@entity/data/mappers/cost/iva-enum.mapper";
import { CreateOtherSubsidizableExpenseBody } from "@project/data/dto/expenses/create-expense.body";
import { EditOtherSubsidizableExpenseBody } from "@project/data/dto/expenses/edit-expense.body";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { CreateOtherSubsidizableExpense } from "@project/domain/models/expenses/create-expense.model";
import { EditOtherSubsidizableExpense } from "@project/domain/models/expenses/edit-expense.model";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { OtherSubsidizableExpense } from "@project/domain/models/expenses/expense.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { ExpenseDto } from "../../dto/expenses/expenses.dto";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type-enum.mapper";
import { OtherSubsidizableDto } from "@project/data/dto/expenses/otherSubsidizable.dto";
import { ExpenseOtherSubsidizableFormValuesValidated } from "@project/presentation/components/project-form/expenses/expenses-form/otherSubsidizable/expense-otherSubsidizable-form.component";

const maximumDecimalsNumber = 2;

@injectable()
export class OtherSubsidizableExpenseMapper {
    constructor(
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
        @inject(IVAEnumMapper)
        private readonly ivaEnumMapper: IVAEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
    ) { }
    map(
        expenseDto: ExpenseDto,
        otherSubsidizableData: OtherSubsidizableDto,
    ): Nullable<OtherSubsidizableExpense> {
        const errors = validateSync(otherSubsidizableData);

        if (errors.length > 0) {
            console.error(errors);
            return null;
        }

        let paymentMethod = null;
        if (otherSubsidizableData.payment_method)
            paymentMethod = this.paymentMethodTypeEnumMapper.map(
                otherSubsidizableData.payment_method,
            );

        const ivaEnum = otherSubsidizableData.iva.toString() as IVATypeEnumDto;
        const iva = this.ivaEnumMapper.map(ivaEnum);
        const documents: IncDocument[] = [];
        if (expenseDto.documents_data) {
            expenseDto.documents_data.forEach((document) => {
                const documentMapped = this.documentMapper.map(document);
                if (documentMapped) {
                    documents.push(documentMapped);
                }
            });
        }

        return new OtherSubsidizableExpense(
            expenseDto.id,
            "",
            otherSubsidizableData.provider,
            otherSubsidizableData.nif_provider,
            otherSubsidizableData.description,
            otherSubsidizableData.invoice_number,
            otherSubsidizableData.invoice_date
                ? DateTime.fromISO(otherSubsidizableData.invoice_date)
                : null,
            otherSubsidizableData.expense_date
                ? DateTime.fromISO(otherSubsidizableData.expense_date)
                : null,
            paymentMethod,
            otherSubsidizableData.tax_base ? parseFloat(otherSubsidizableData.tax_base) : 0,
            iva,
            otherSubsidizableData.total_tax_with_iva
                ? parseFloat(otherSubsidizableData.total_tax_with_iva)
                : 0,
            otherSubsidizableData.project_allocation_percentage
                ? parseFloat(otherSubsidizableData.project_allocation_percentage)
                : null,
            otherSubsidizableData.amount_allocated_to_project
                ? parseFloat(otherSubsidizableData.amount_allocated_to_project)
                : null,
            otherSubsidizableData.payment_date
                ? DateTime.fromISO(otherSubsidizableData.payment_date)
                : null,
            otherSubsidizableData.concept,
            documents,
            expenseDto.project,
        );
    }

    mapFromFormValues(
        createFormValues: ExpenseOtherSubsidizableFormValuesValidated,
        projectId: number,
    ): CreateOtherSubsidizableExpense {
        return new CreateOtherSubsidizableExpense(
            projectId,
            ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES,
            createFormValues.provider,
            createFormValues.nifProvider ? createFormValues.nifProvider : null,
            createFormValues.description,
            createFormValues.invoiceNumber,
            createFormValues.invoiceDate,
            createFormValues.invoiceDate,
            createFormValues.paymentMethod,
            createFormValues.base,
            createFormValues.iva,
            createFormValues.baseIva,
            createFormValues.percentageImputation,
            createFormValues.amountImputation,
            createFormValues.paymentDate,
            createFormValues.documents.map((document) => document.id),
            createFormValues.concept,
        );
    }

    mapFromFormEditValues(
        editFormValues: ExpenseOtherSubsidizableFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): EditOtherSubsidizableExpense {
        return new EditOtherSubsidizableExpense(
            expenseId,
            projectId,
            ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES,
            editFormValues.provider,
            editFormValues.nifProvider ? editFormValues.nifProvider : null,
            editFormValues.description,
            editFormValues.invoiceNumber,
            editFormValues.invoiceDate,
            editFormValues.invoiceDate,
            editFormValues.paymentMethod,
            editFormValues.base,
            editFormValues.iva,
            editFormValues.baseIva,
            editFormValues.percentageImputation,
            editFormValues.amountImputation,
            editFormValues.paymentDate,
            editFormValues.documents.map((document) => document.id),
            editFormValues.concept,
        );
    }

    mapToDto(
        createExpense: CreateOtherSubsidizableExpense,
    ): CreateOtherSubsidizableExpenseBody {
        if (!createExpense.paymentMethod) {
            throw new FallbackError();
        }

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            createExpense.paymentMethod,
        );

        const percentageImputation =
            createExpense.percentageImputation?.toFixed(maximumDecimalsNumber);

        const amountAllocatedToProject = createExpense.amountImputation
            ? Number(
                createExpense.amountImputation.toFixed(maximumDecimalsNumber),
            )
            : 0;

        return {
            project: createExpense.projectId,
            type_expense: ExpenseTypeEnumDto.OTHER_SUBSIDIZABLE_EXPENSES,
            amount_allocated_to_project: amountAllocatedToProject,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: createExpense.documents,
            concept: createExpense.concept ?? "",
            provider: createExpense.provider,
            nif_provider: createExpense.nifProvider ?? "",
            description: createExpense.description ?? "",
            invoice_number: createExpense.invoiceNumber ?? "",
            expense_date: createExpense.invoiceDate?.toISODate() ?? "",
            tax_base: createExpense.taxBase?.toString() ?? "0",
            iva: createExpense.iva?.toString() ?? "0",
            total_tax_with_iva:
                createExpense.totalTaxWithIva?.toString() ?? "0",
            payment_method: paymentMethod,
            payment_date: createExpense.paymentDate?.toISODate() ?? "",
        };
    }

    mapToEditDto(
        editExpense: EditOtherSubsidizableExpense,
    ): EditOtherSubsidizableExpenseBody {
        if (!editExpense.paymentMethod) {
            throw new FallbackError();
        }

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            editExpense.paymentMethod,
        );

        const percentageImputation = editExpense.percentageImputation?.toFixed(
            maximumDecimalsNumber,
        );

        return {
            id: editExpense.id,
            project: editExpense.projectId,
            type_expense: ExpenseTypeEnumDto.OTHER_SUBSIDIZABLE_EXPENSES,
            amount_allocated_to_project: editExpense.amountImputation ?? 0,
            project_allocation_percentage: percentageImputation
                ? parseFloat(percentageImputation)
                : 0,
            documents: editExpense.documents,
            concept: editExpense.concept ?? "",
            provider: editExpense.provider,
            nif_provider: editExpense.nifProvider ?? "",
            description: editExpense.description ?? "",
            invoice_number: editExpense.invoiceNumber ?? "",
            expense_date: editExpense.invoiceDate?.toISODate() ?? "",
            tax_base: editExpense.taxBase?.toString() ?? "0",
            iva: editExpense.iva?.toString() ?? "0",
            total_tax_with_iva: editExpense.totalTaxWithIva?.toString() ?? "0",
            payment_method: paymentMethod,
            payment_date: editExpense.paymentDate?.toISODate() ?? "",
        };
    }
}
