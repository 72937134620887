import {
    CreateIncome,
    EditIncome,
    Income,
    Incomes,
} from "@beneficiary/domain/models/economic-data/income/income.model";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { IncomeDatasource } from "../datasource/income.datasource";
import { BeneficiaryIncomeError } from "@beneficiary/domain/errors/beneficiary.error";
import { IncomeMapperError } from "@beneficiary/domain/errors/income/income-mapper.error";

@injectable()
export class IncomeRepository {
    constructor(
        @inject(IncomeDatasource)
        private readonly incomeDatasource: IncomeDatasource,
    ) { }

    async create(
        createIncome: CreateIncome,
    ): Promise<Either<ValidationError | BeneficiaryIncomeError | IncomeMapperError, Income>> {
        return this.incomeDatasource.create(createIncome);
    }

    async edit(
        income: EditIncome,
    ): Promise<Either<ValidationError | BeneficiaryIncomeError | IncomeMapperError, Income>> {
        return this.incomeDatasource.edit(income);
    }

    async delete(incomeId: number): Promise<Either<BeneficiaryIncomeError, boolean>> {
        return this.incomeDatasource.delete(incomeId);
    }

    async getAllBy(
        beneficiaryId: number,
    ): Promise<Either<BeneficiaryIncomeError, Incomes>> {
        const incomesSummary = await this.incomeDatasource.fetchAllBy(
            beneficiaryId,
            Pagination.NoPagination(),
        );

        return incomesSummary.mapLeft(() => new BeneficiaryIncomeError("fetchAllByError"));
    }

    async findById(incomeId: number): Promise<Either<BeneficiaryIncomeError | IncomeMapperError, Income>> {
        const incomeResult = await this.incomeDatasource.fetchById(incomeId);

        if (incomeResult.isLeft())
            return Either.Left(incomeResult.getLeftOrThrow());

        const { income } = incomeResult.getOrThrow();

        return Either.Right(income);
    }
}
