import { BeneficiaryError } from "@beneficiary/domain/errors/beneficiary.error";
import { coreTypes } from "@core/core-types.di";
import { EmploymentStatusDto } from "@core/data/dto/employment-status.dto";
import { type Http } from "@core/data/infrastructures/http/http";
import { EmploymentStatusMapper } from "@core/data/mappers/employment-status.mapper";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class EmploymentStatusDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,

        @inject(EmploymentStatusMapper)
        private readonly employmentStatusMapper: EmploymentStatusMapper,
    ) { }
    async fetchAllEmploymentStatus(): Promise<
        Either<BeneficiaryError, EmploymentStatus[]>
    > {
        const employmentStatusResult = await this.http.get<
            EmploymentStatusDto[]
        >("/beneficiaries/employment_status/");

        return employmentStatusResult
            .mapLeft(() => new BeneficiaryError("fetchAllEmploymentStatusError"))
            .map((employmentStatusResponse) =>
                employmentStatusResponse.data.mapNotNull(
                    (employmentStatusDto) =>
                        this.employmentStatusMapper.map(
                            plainToClass(
                                EmploymentStatusDto,
                                employmentStatusDto,
                            ),
                        ),
                ),
            );
    }
}
