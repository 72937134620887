import { coreTypes } from "@core/core-types.di";
import type { Http } from "@core/data/infrastructures/http/http";
import { Either } from "@core/domain/types/either";
import {
    GlobalEntityDto,
    GlobalEntityQuery,
} from "@entity/data/dto/global-entity.dto";
import { GlobalEntityMapper } from "@entity/data/mappers/global-entity.mapper";
import { GlobalEntityError } from "@entity/domain/errors/entity.error";
import {
    GlobalEntity,
    GlobalEntitySearchFilters,
} from "@entity/domain/models/global-entity.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class GlobalEntityDatasource {
    constructor(
        @inject(GlobalEntityMapper)
        private readonly globalEntityMapper: GlobalEntityMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
    ) { }

    async fetchAll(
        filters?: GlobalEntitySearchFilters,
    ): Promise<Either<GlobalEntityError, GlobalEntity[]>> {
        const query: GlobalEntityQuery = {};

        if (filters?.catalogue) query.catalogue = filters.catalogue;

        const entitiesResult = await this.http.get<GlobalEntityDto[]>(
            "/entities/public_entity/",
            {
                query,
            },
        );

        return entitiesResult
            .mapLeft(() => new GlobalEntityError("fetchGlobalEntitiesError"))
            .map((response) =>
                response.data.mapNotNull((entityDto) =>
                    this.globalEntityMapper.map(
                        plainToClass(GlobalEntityDto, entityDto),
                    ),
                ),
            );
    }
}
