import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class EntityAddressError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.address.${errorKey}`));
        Object.setPrototypeOf(this, EntityAddressError.prototype);
    }
}

export class EntityAffiliationError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.affiliation.${errorKey}`));
        Object.setPrototypeOf(this, EntityAffiliationError.prototype);
    }
}

export class EntityAuditError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.audit.${errorKey}`));
        Object.setPrototypeOf(this, EntityAuditError.prototype);
    }
}

export class EntityAuthorityManagerError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.authorityManager.${errorKey}`));
        Object.setPrototypeOf(this, EntityAuthorityManagerError.prototype);
    }
}

export class EntityCollectiveError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.collective.${errorKey}`));
        Object.setPrototypeOf(this, EntityCollectiveError.prototype);
    }
}

export class EntityContactError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.contact.${errorKey}`));
        Object.setPrototypeOf(this, EntityContactError.prototype);
    }
}

export class EntityContractTerminationError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.contractTermination.${errorKey}`));
        Object.setPrototypeOf(this, EntityContractTerminationError.prototype);
    }
}

export class EntityContractsError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.contracts.${errorKey}`));
        Object.setPrototypeOf(this, EntityContractsError.prototype);
    }
}

export class EntityCostError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.cost.${errorKey}`));
        Object.setPrototypeOf(this, EntityCostError.prototype);
    }
}

export class EntityCreditorError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.creditor.${errorKey}`));
        Object.setPrototypeOf(this, EntityCreditorError.prototype);
    }
}

export class EntityDepositError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.deposit.${errorKey}`));
        Object.setPrototypeOf(this, EntityDepositError.prototype);
    }
}

export class EntityEmployeeError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.employee.${errorKey}`));
        Object.setPrototypeOf(this, EntityEmployeeError.prototype);
    }
}

export class GlobalEntityError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.gobalEntity.${errorKey}`));
        Object.setPrototypeOf(this, GlobalEntityError.prototype);
    }
}

export class EntityLocalError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.local.${errorKey}`));
        Object.setPrototypeOf(this, EntityLocalError.prototype);
    }
}

export class EntityRoomError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.room.${errorKey}`));
        Object.setPrototypeOf(this, EntityRoomError.prototype);
    }
}

export class EntityMaterialError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.material.${errorKey}`));
        Object.setPrototypeOf(this, EntityMaterialError.prototype);
    }
}

export class EntityPartnersNumberError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.partners.${errorKey}`));
        Object.setPrototypeOf(this, EntityPartnersNumberError.prototype);
    }
}

export class EntityPolicyProtocolNumberError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.policyProtocol.${errorKey}`));
        Object.setPrototypeOf(this, EntityPolicyProtocolNumberError.prototype);
    }
}

export class EntityPolicyProjectAuditoryError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.projectAuditory.${errorKey}`));
        Object.setPrototypeOf(this, EntityPolicyProjectAuditoryError.prototype);
    }
}

export class EntityPublicInterestError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.publicInterest.${errorKey}`));
        Object.setPrototypeOf(this, EntityPublicInterestError.prototype);
    }
}

export class EntityPublicUtilityError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.publicUtility.${errorKey}`));
        Object.setPrototypeOf(this, EntityPublicUtilityError.prototype);
    }
}

export class EntityQualityCertificateError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.qualityCertificate.${errorKey}`));
        Object.setPrototypeOf(this, EntityQualityCertificateError.prototype);
    }
}

export class EntityRegconRegisterError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.regconRegister.${errorKey}`));
        Object.setPrototypeOf(this, EntityRegconRegisterError.prototype);
    }
}

export class EntityRegisteredRecordError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.registeredRecord.${errorKey}`));
        Object.setPrototypeOf(this, EntityRegisteredRecordError.prototype);
    }
}

export class EntityServiceError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.service.${errorKey}`));
        Object.setPrototypeOf(this, EntityServiceError.prototype);
    }
}

export class EntitySocialNetworkError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.socialNetwork.${errorKey}`));
        Object.setPrototypeOf(this, EntitySocialNetworkError.prototype);
    }
}

export class EntityStatutoryTerritorialError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.statutoryTerritorialScope.${errorKey}`));
        Object.setPrototypeOf(this, EntityStatutoryTerritorialError.prototype);
    }
}

export class EntitySupplierError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.supplier.${errorKey}`));
        Object.setPrototypeOf(this, EntitySupplierError.prototype);
    }
}

export class EntityTitularityTypesError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.titularityTypes.${errorKey}`));
        Object.setPrototypeOf(this, EntityTitularityTypesError.prototype);
    }
}

export class EntityError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:entity.${errorKey}`));
        Object.setPrototypeOf(this, EntityError.prototype);
    }
}