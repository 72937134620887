import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { TitularityTypesDatasource } from "@entity/data/datasources/titularity-types.datasource";
import { EntityTitularityTypesError } from "@entity/domain/errors/entity.error";
import { TitularityType } from "@entity/domain/models/titularity-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class TitularityTypesRepository {
    private titularityTypes: Undefinable<TitularityType[]>;

    constructor(
        @inject(TitularityTypesDatasource)
        private readonly titularityTypesDatasource: TitularityTypesDatasource,
    ) { }

    async getAllTitularityTypes(): Promise<
        Either<EntityTitularityTypesError, TitularityType[]>
    > {
        if (this.titularityTypes) {
            return Either.Right(this.titularityTypes);
        }

        const titularityTypesResult =
            await this.titularityTypesDatasource.fetchAllTitularityTypes();

        if (titularityTypesResult.isRight()) {
            this.titularityTypes = titularityTypesResult.getOrThrow();
        }

        return titularityTypesResult;
    }
}
