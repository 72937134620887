import {
    DistrictDto,
    DistrictQuery,
    DistrictsDto,
} from "@beneficiary/data/dto/district.dto";
import { DistrictMapper } from "@beneficiary/data/mappers/district.mapper";
import { BeneficiaryDistrictError } from "@beneficiary/domain/errors/beneficiary.error";
import { DistrictMapperError } from "@beneficiary/domain/errors/district/district-mapper.error";
import { District } from "@beneficiary/domain/models/district.model";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class DistrictDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(DistrictMapper)
        private readonly districtMapper: DistrictMapper,
    ) { }

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<BeneficiaryDistrictError | DistrictMapperError, District[]>> {
        const query: DistrictQuery = {
            offset: pagination.offset,
            limit: pagination.pageSize,
        };
        const districtResult = await this.http.get<DistrictsDto>(
            "/districts/",
            { query },
        );

        return districtResult
            .mapLeft(() => new BeneficiaryDistrictError("fetchAllDistrictsError"))
            .flatMap((response) => {
                try {
                    const mappedDistricts = response.data.results.mapNotNull((district) =>
                        this.districtMapper.map(plainToClass(DistrictDto, district)),
                    )
                    return Either.Right(mappedDistricts);
                } catch (error) {
                    return Either.Left(new DistrictMapperError());
                }

            }

            );
    }
}
