import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import {
    AuthorityManagerDto,
    AuthorityManagersDto,
} from "@entity/data/dto/authority-manager/authority-manager.dto";
import { CreateAuthorityManagerBody } from "@entity/data/dto/authority-manager/create-authority-manager.body";
import { EditAuthorityManagerBody } from "@entity/data/dto/authority-manager/edit-authority-manager.body";
import { MandateTerminationReasonDto } from "@entity/data/dto/mandate-termination-reason-enum.dto";
import { PositionTypeDto } from "@entity/data/dto/position-type.dto";
import { AuthorityManagersMapper } from "@entity/data/mappers/authority-manager/authority-managers.mapper";
import { CreateAuthorityManagerMapper } from "@entity/data/mappers/authority-manager/create-authority-manager.mapper";
import { EditAuthorityManagerMapper } from "@entity/data/mappers/authority-manager/edit-authority-manager.mapper";
import { MandateTerminationReasonMapper } from "@entity/data/mappers/mandate-termination-reason.mapper";
import { PositionTypeMapper } from "@entity/data/mappers/position-type.mapper";
import { AuthorityManagerMapperError } from "@entity/domain/errors/authority-manager/authority-manager-mapper.error";
import { EntityAuthorityManagerError } from "@entity/domain/errors/entity.error";
import {
    AuthorityManager,
    AuthorityManagers,
} from "@entity/domain/models/authority-manager/authority-manager.model";
import { CreateAuthorityManager } from "@entity/domain/models/authority-manager/create-authority-manager.model";
import { EditAuthorityManager } from "@entity/domain/models/authority-manager/edit-authority-manager.model";
import { MandateTerminationReason } from "@entity/domain/models/mandate-termination-reason.model";
import { PositionType } from "@entity/domain/models/position-type.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const AUTHORITY_MANAGER_PATH = "/entities_management_body/";

@injectable()
export class AuthorityManagerDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(AuthorityManagersMapper)
        private readonly authorityManagersMapper: AuthorityManagersMapper,
        @inject(MandateTerminationReasonMapper)
        private readonly mandateTerminationReasonMapper: MandateTerminationReasonMapper,
        @inject(PositionTypeMapper)
        private readonly positionTypeMapper: PositionTypeMapper,
        @inject(CreateAuthorityManagerMapper)
        private readonly createAuthorityManagerMapper: CreateAuthorityManagerMapper,
        @inject(EditAuthorityManagerMapper)
        private readonly editAuthorityManagerMapper: EditAuthorityManagerMapper,
    ) { }

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, AuthorityManagers>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const authorityManagerResult = await this.http.get<AuthorityManagerDto>(
            AUTHORITY_MANAGER_PATH,
            {
                query,
            },
        );

        return authorityManagerResult.map((response) =>
            this.authorityManagersMapper.map(
                plainToClass(AuthorityManagersDto, response.data),
            ),
        );
    }

    async create(
        newAuthorityManager: CreateAuthorityManager,
    ): Promise<Either<ValidationError | EntityAuthorityManagerError | AuthorityManagerMapperError, AuthorityManager>> {
        const authorityManagerBody =
            this.createAuthorityManagerMapper.mapToCreateDto(
                newAuthorityManager,
            );

        const authorityManagerResult = await this.http.post<
            AuthorityManagerDto,
            CreateAuthorityManagerBody
        >(AUTHORITY_MANAGER_PATH, authorityManagerBody);

        return authorityManagerResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityAuthorityManagerError("createAuthorityManagerError");
            })
            .flatMap((response) => {
                const authorityManager =
                    this.authorityManagersMapper.mapAuthorityManager(
                        plainToClass(AuthorityManagerDto, response.data),
                    );

                if (!authorityManager) return Either.Left(new AuthorityManagerMapperError());

                return Either.Right(authorityManager);
            });
    }

    async edit(
        editAuthorityManager: EditAuthorityManager,
    ): Promise<Either<ValidationError | EntityAuthorityManagerError | AuthorityManagerMapperError, AuthorityManager>> {
        const editedAuthorityManager =
            this.editAuthorityManagerMapper.mapToDto(editAuthorityManager);

        const editAuthorityManagerResult = await this.http.put<
            AuthorityManagerDto,
            EditAuthorityManagerBody
        >(
            `${AUTHORITY_MANAGER_PATH}${editAuthorityManager.id}/`,
            editedAuthorityManager,
        );

        return editAuthorityManagerResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityAuthorityManagerError("editAuthorityManagerError");
            })
            .flatMap((response) => {
                const authorityManager =
                    this.authorityManagersMapper.mapAuthorityManager(
                        plainToClass(AuthorityManagerDto, response.data),
                    );

                if (!authorityManager) return Either.Left(new AuthorityManagerMapperError());

                return Either.Right(authorityManager);
            });
    }

    async delete(
        authorityManagerId: number,
    ): Promise<Either<EntityAuthorityManagerError, true>> {
        const deleteAuthorityManager = await this.http.delete(
            `${AUTHORITY_MANAGER_PATH}${authorityManagerId}`,
        );

        return deleteAuthorityManager
            .mapLeft(() => new EntityAuthorityManagerError("deleteAuthorityManagerError"))
            .map(() => true);
    }

    async fetchAllMandateTerminationReasons(): Promise<
        Either<EntityAuthorityManagerError, MandateTerminationReason[]>
    > {
        const responseResult = await this.http.get<
            MandateTerminationReasonDto[]
        >(`${AUTHORITY_MANAGER_PATH}mandate_termination_reasons/`);

        return responseResult
            .mapLeft(() => new EntityAuthorityManagerError("fetchAllMandateTerminationReasonsError"))
            .map((response) =>
                response.data.mapNotNull((mandateTerminationReasonDto) =>
                    this.mandateTerminationReasonMapper.map(
                        plainToClass(
                            MandateTerminationReasonDto,
                            mandateTerminationReasonDto,
                        ),
                    ),
                ),
            );
    }

    async fetchAllPositionTypes(): Promise<
        Either<EntityAuthorityManagerError, PositionType[]>
    > {
        const responseResult = await this.http.get<PositionTypeDto[]>(
            `${AUTHORITY_MANAGER_PATH}position_types/`,
        );

        return responseResult
            .mapLeft(() => new EntityAuthorityManagerError("fetchAllPositionTypesError"))
            .map((response) =>
                response.data.mapNotNull((positionTypeDto) =>
                    this.positionTypeMapper.map(
                        plainToClass(PositionTypeDto, positionTypeDto),
                    ),
                ),
            );
    }
}
