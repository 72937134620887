import { CountriesDatasource } from "@beneficiary/data/datasource/country.datasource";
import { BeneficiaryCountryError } from "@beneficiary/domain/errors/beneficiary.error";
import { CountryMapperError } from "@beneficiary/domain/errors/country/country-mapper.error";
import { Country } from "@core/domain/models/country.model";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { inject, injectable } from "inversify";

@injectable()
export class CountryRepository {
    private countries: Undefinable<Country[]>;

    constructor(
        @inject(CountriesDatasource)
        private readonly countriesDatasource: CountriesDatasource,
    ) { }

    async getAllCountries(): Promise<Either<BeneficiaryCountryError | CountryMapperError, Country[]>> {
        if (this.countries) {
            return Either.Right(this.countries);
        }

        const countriesResult =
            await this.countriesDatasource.fetchAllCountries();

        if (countriesResult.isRight()) {
            this.countries = countriesResult.getOrThrow();
        }

        return countriesResult;
    }
}
