import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { StatutoryTerritorialScopeDatasource } from "@entity/data/datasources/statutory-territorial-scope.datasource";
import { EntityStatutoryTerritorialError } from "@entity/domain/errors/entity.error";
import { StatutoryTerritorialMapperError } from "@entity/domain/errors/statutory-territorial-scope/statutory-mapper.error";
import { CreateStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/create-statutory-territorial-scope.model";
import { EditStatutoryTerritorialScope } from "@entity/domain/models/statutory-territorial-scope/edit-statutory-territorial-scope.model";
import {
    StatutoryTerritorialScope,
    StatutoryTerritorialScopes,
} from "@entity/domain/models/statutory-territorial-scope/statutory-territorial-scope.model";
import { inject, injectable } from "inversify";

@injectable()
export class StatutoryTerritorialScopeRepository {
    constructor(
        @inject(StatutoryTerritorialScopeDatasource)
        private readonly statutoryTerritorialScopeDatasource: StatutoryTerritorialScopeDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityStatutoryTerritorialError, StatutoryTerritorialScopes>> {
        const statutoryTerritorialScopeResult =
            await this.statutoryTerritorialScopeDatasource.fetchAll(pagination);

        return statutoryTerritorialScopeResult.mapLeft(
            () => new EntityStatutoryTerritorialError("fetchStatutoryTerritorialScopesError"),
        );
    }

    async create(
        statutoryTerritorialScope: CreateStatutoryTerritorialScope,
    ): Promise<
        Either<ValidationError | EntityStatutoryTerritorialError | StatutoryTerritorialMapperError, StatutoryTerritorialScope>
    > {
        return this.statutoryTerritorialScopeDatasource.create(
            statutoryTerritorialScope,
        );
    }

    async edit(
        editStatutoryTerritorialScope: EditStatutoryTerritorialScope,
    ): Promise<
        Either<ValidationError | EntityStatutoryTerritorialError | StatutoryTerritorialMapperError, StatutoryTerritorialScope>
    > {
        return this.statutoryTerritorialScopeDatasource.edit(
            editStatutoryTerritorialScope,
        );
    }

    async delete(
        statutoryTerritorialScopeId: number,
    ): Promise<Either<EntityStatutoryTerritorialError, true>> {
        const statutoryTerritorialScopeResult =
            await this.statutoryTerritorialScopeDatasource.delete(
                statutoryTerritorialScopeId,
            );
        return statutoryTerritorialScopeResult.mapLeft(
            () => new EntityStatutoryTerritorialError("deleteStatutoryTerritorialScopeError"),
        );
    }
}
