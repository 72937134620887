import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CostIVATypeDto } from "@entity/data/dto/cost-iva-type.dto";
import { CostAdditionalExpensesMapper } from "@entity/data/mappers/cost/cost-additional-expenses.mapper";
import { CostIVATypeMapper } from "@entity/data/mappers/cost/cost-iva-type.mapper";
import { CostTypeEnumMapper } from "@entity/data/mappers/cost/cost-type-enum.mapper";
import { CostTypeMapper } from "@entity/data/mappers/cost/cost-type.mapper";
import { CostMapper } from "@entity/data/mappers/cost/cost.mapper";
import { CostsMapper } from "@entity/data/mappers/cost/costs.mapper";
import { CreateCostMapper } from "@entity/data/mappers/cost/create-cost.mapper";
import { EditCostMapper } from "@entity/data/mappers/cost/edit-cost.mapper";
import { IRPFTypeMapper } from "@entity/data/mappers/cost/irpf-type.mapper";
import { IvaMapper } from "@entity/data/mappers/cost/iva.mapper";
import { PaymentMethodMapper } from "@entity/data/mappers/cost/payment-method-type-enum.mapper";
import { CostIVAType } from "@entity/domain/models/cost/cost-iva-type.model";
import {
    CostSearchFilters,
    CostsSummary,
} from "@entity/domain/models/cost/cost-summary.model";
import { CostType } from "@entity/domain/models/cost/cost-type.model";
import { IRPFType } from "@entity/domain/models/cost/irpf-type.model";
import { IVAType } from "@entity/domain/models/cost/iva-type.model";
import { PaymentMethodType } from "@entity/domain/models/cost/payment-method-type.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    AdditionalExpense,
    AdditionalExpenseCreate,
    Cost,
} from "../../domain/models/cost/cost.model";
import { CreateCost } from "../../domain/models/cost/create-cost.model";
import { EditCost } from "../../domain/models/cost/edit-cost.model";
import { CostTypeDto } from "../dto/cost/cost-type.dto";
import { AdditionalCostData, CostDto } from "../dto/cost/cost.dto";
import { CostsDto, CostsSummaryQuery } from "../dto/cost/costs.dto";
import {
    CreateAdditionalExpense,
    CreateCostBody,
} from "../dto/cost/create-cost.body";
import { EditCostBody } from "../dto/cost/edit-cost.body";
import { PaymentMethodTypeDto } from "../dto/cost/payment-method-type.dto";
import { IRPFTypeDto } from "../dto/irpf-type-enum.dto";
import { IVATypeDto } from "../dto/iva-type.dto";
import { EntityCostError } from "@entity/domain/errors/entity.error";
import { CostMapperError } from "@entity/domain/errors/cost/cost-mapper.error";

@injectable()
export class CostDatasource {
    private static readonly COST_PATH = "/invoices/";

    // eslint-disable-next-line max-params
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(CostsMapper)
        private readonly costsMapper: CostsMapper,
        @inject(CostMapper)
        private readonly costMapper: CostMapper,
        @inject(EditCostMapper)
        private readonly editCostMapper: EditCostMapper,
        @inject(CreateCostMapper)
        private readonly createCostMapper: CreateCostMapper,
        @inject(PaymentMethodMapper)
        private readonly paymentMethodMapper: PaymentMethodMapper,
        @inject(IvaMapper)
        private readonly ivaMapper: IvaMapper,
        @inject(CostTypeMapper)
        private readonly costTypeMapper: CostTypeMapper,
        @inject(CostTypeEnumMapper)
        private readonly costTypeEnumMapper: CostTypeEnumMapper,
        @inject(IRPFTypeMapper)
        private readonly irpfTypeMapper: IRPFTypeMapper,
        @inject(CostIVATypeMapper)
        private readonly costIVATypeMapper: CostIVATypeMapper,
        @inject(CostAdditionalExpensesMapper)
        private readonly costAdditionalExpensesMapper: CostAdditionalExpensesMapper,
    ) { }

    async fetchAllBy(
        pagination: Pagination,
        filters?: CostSearchFilters,
    ): Promise<Either<EntityCostError, CostsSummary>> {
        const query: CostsSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.search) query.search = filters.search;
        if (filters?.costFromDate)
            query.invoice_from_date = filters.costFromDate.toISODate();
        if (filters?.costToDate)
            query.invoice_to_date = filters.costToDate.toISODate();
        if (filters?.type)
            query.type = this.costTypeEnumMapper.mapToDto(filters.type);
        if (filters?.entityIds) query.entities = filters.entityIds.join(",");

        const costsResult = await this.http.get<CostsDto>(
            CostDatasource.COST_PATH,
            {
                query,
            },
        );

        return costsResult
            .map((response) =>
                this.costsMapper.map(plainToClass(CostsDto, response.data)),
            )
            .mapLeft(() => new EntityCostError("fetchCostsError"));
    }

    async fetchById(costId: number): Promise<Either<EntityCostError | CostMapperError, Cost>> {
        const costResult = await this.http.get<CostDto>(
            `${CostDatasource.COST_PATH}${costId}/`,
        );

        return costResult
            .mapLeft(() => new EntityCostError("fetchCostError"))
            .flatMap((response) => {

                const cost = this.costMapper.map(
                    plainToClass(CostDto, response.data),
                );

                if (!cost) return Either.Left(new CostMapperError());

                return Either.Right(cost);
            });
    }

    async create(
        newCost: CreateCost,
    ): Promise<Either<ValidationError | EntityCostError | CostMapperError, Cost>> {
        const createdCostDto = this.createCostMapper.mapToDto(newCost);

        const createCostResult = await this.http.post<CostDto, CreateCostBody>(
            CostDatasource.COST_PATH,
            createdCostDto,
        );

        return createCostResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityCostError("createCostError");
            })
            .flatMap((response) => {
                const cost = this.costMapper.map(
                    plainToClass(CostDto, response.data),
                );

                if (!cost) return Either.Left(new CostMapperError());

                return Either.Right(cost);
            });
    }

    async createAdditionalExpenses(
        additionalExpense: CreateAdditionalExpense,
    ): Promise<Either<ValidationError | EntityCostError | CostMapperError, AdditionalExpense>> {
        const createAdditionalExpenses = await this.http.post<
            AdditionalCostData,
            CreateAdditionalExpense
        >(`${CostDatasource.COST_PATH}additional_expenses/`, additionalExpense);

        return createAdditionalExpenses
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityCostError("createAdditionalExpensesError");
            })
            .flatMap((response) => {
                const additionalCost = this.costAdditionalExpensesMapper.map(
                    plainToClass(AdditionalCostData, response.data),
                );

                if (!additionalCost) return Either.Left(new CostMapperError());

                return Either.Right(additionalCost);
            });
    }

    async updateAdditionalExpenses(
        additionalExpense: AdditionalExpenseCreate,
    ): Promise<Either<ValidationError | EntityCostError | CostMapperError, AdditionalExpense>> {
        const updateAdditionalExpenses = await this.http.patch<
            AdditionalCostData,
            CreateAdditionalExpense
        >(
            `${CostDatasource.COST_PATH}additional_expenses/${additionalExpense.id}/`,
            additionalExpense,
        );

        return updateAdditionalExpenses
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityCostError("updateAdditionalExpensesError");
            })
            .flatMap((response) => {
                const additionalCost = this.costAdditionalExpensesMapper.map(
                    plainToClass(AdditionalCostData, response.data),
                );

                if (!additionalCost) return Either.Left(new CostMapperError());

                return Either.Right(additionalCost);
            });
    }

    async update(
        editCost: EditCost,
    ): Promise<Either<ValidationError | EntityCostError | CostMapperError, Cost>> {
        const editedCostDto = this.editCostMapper.mapToDto(editCost);

        const editCostResult = await this.http.patch<CostDto, EditCostBody>(
            `${CostDatasource.COST_PATH}${editCost.id}/`,
            editedCostDto,
        );

        return editCostResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityCostError("updateCostError");
            })
            .flatMap((response) => {
                const cost = this.costMapper.map(
                    plainToClass(CostDto, response.data),
                );

                if (!cost) return Either.Left(new CostMapperError());

                return Either.Right(cost);
            });
    }

    async delete(costId: number): Promise<Either<EntityCostError, boolean>> {
        const deleteCostResult = await this.http.delete(
            `${CostDatasource.COST_PATH}${costId}/`,
        );

        return deleteCostResult
            .mapLeft(() => new EntityCostError("deleteCostError"))
            .map(() => true);
    }

    async deleteAdditionalExpense(
        additionalExpenseId: number,
    ): Promise<Either<EntityCostError, true>> {
        const deleteAdditionalExpenses = await this.http.delete(
            `${CostDatasource.COST_PATH}additional_expenses/${additionalExpenseId}/`,
        );

        return deleteAdditionalExpenses
            .mapLeft(() => new EntityCostError("deleteAdditionalExpensesError"))
            .map(() => true);
    }

    async fetchAllPaymentMethod(): Promise<
        Either<EntityCostError, PaymentMethodType[]>
    > {
        const paymentMethodTypes = await this.http.get<PaymentMethodTypeDto[]>(
            `${CostDatasource.COST_PATH}payment_types`,
        );

        return paymentMethodTypes
            .mapLeft(() => new EntityCostError("fetchPaymentMethodError"))
            .map((response) =>
                response.data.mapNotNull((paymentMethodType) =>
                    this.paymentMethodMapper.map(
                        plainToClass(PaymentMethodTypeDto, paymentMethodType),
                    ),
                ),
            );
    }

    async fetchAllIvaTypes(): Promise<Either<EntityCostError, IVAType[]>> {
        const ivaTypes = await this.http.get<IVATypeDto[]>(
            `${CostDatasource.COST_PATH}iva_types`,
        );

        return ivaTypes
            .mapLeft(() => new EntityCostError("fetchIvaTypesError"))
            .map((response) =>
                response.data.mapNotNull((ivaType) =>
                    this.ivaMapper.map(plainToClass(IVATypeDto, ivaType)),
                ),
            );
    }

    async fetchAllCostsTypes(): Promise<Either<EntityCostError, CostType[]>> {
        const costsTypes = await this.http.get<CostTypeDto[]>(
            `${CostDatasource.COST_PATH}types`,
        );

        return costsTypes
            .mapLeft(() => new EntityCostError("fetchCostsTypesError"))
            .map((response) =>
                response.data.mapNotNull((costType) =>
                    this.costTypeMapper.map(
                        plainToClass(CostTypeDto, costType),
                    ),
                ),
            );
    }

    async fetchAllIrpfTypes(): Promise<Either<EntityCostError, IRPFType[]>> {
        const irpfTypes = await this.http.get<IRPFTypeDto[]>(
            `${CostDatasource.COST_PATH}irpf_types`,
        );

        return irpfTypes
            .mapLeft(() => new EntityCostError("fetchIrpfTypesError"))
            .map((response) =>
                response.data.mapNotNull((irpfType) =>
                    this.irpfTypeMapper.map(
                        plainToClass(IRPFTypeDto, irpfType),
                    ),
                ),
            );
    }

    async fetchAllCostIvaTypes(): Promise<
        Either<EntityCostError, CostIVAType[]>
    > {
        const costIvaTypes = await this.http.get<CostIVATypeDto[]>(
            `${CostDatasource.COST_PATH}iva_types_with_other`,
        );

        return costIvaTypes
            .mapLeft(() => new EntityCostError("fetchCostIvaTypesError"))
            .map((response) =>
                response.data.mapNotNull((costIvaType) =>
                    this.costIVATypeMapper.map(
                        plainToClass(CostIVATypeDto, costIvaType),
                    ),
                ),
            );
    }
}
