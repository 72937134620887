import { GetCountriesUseCase } from "@beneficiary/domain/usecases/get-all-countries.usecase";
import { Country } from "@core/domain/models/country.model";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateAddressMapper } from "@entity/data/mappers/address/create-address.mapper";
import { EditAddressMapper } from "@entity/data/mappers/address/edit-address.mapper";
import { Address } from "@entity/domain/models/address/address.model";
import { TitularityType } from "@entity/domain/models/titularity-type.model";
import { CreateAddressUseCase } from "@entity/domain/usecases/address/create-address.usecase";
import { DeleteAddressUseCase } from "@entity/domain/usecases/address/delete-address.usecase";
import { EditAddressUseCase } from "@entity/domain/usecases/address/edit-address.usecase";
import { GetAllAddressesUsecase } from "@entity/domain/usecases/address/get-all-addresses.usecase";
import { GetAllTitularityTypesUseCase } from "@entity/domain/usecases/get-all-titularity-types.usecase";
import type { AddressFormValuesValidated } from "@entity/presentation/component/entity-form/address/form/address-form";
import { PostalCode } from "@postal-codes/domain/models/postal-code.model";
import { SearchPostalCodesUseCase } from "@postal-codes/domain/usecases/postal-code.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class AddressTabViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    addresses: Address[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddAddressModal: boolean = false;

    @observable
    showEditAddressModal: boolean = false;

    @observable
    addressToEdit: Undefinable<Address> = undefined;

    @observable
    countries: Country[] = [];

    @observable
    titularityTypes: TitularityType[] = [];

    @computed
    get principalAddress(): Undefinable<Address> {
        return this.addresses.find((address) => address.principalAddress);
    }

    constructor(
        @inject(GetAllAddressesUsecase)
        private readonly getAllAddressesUseCase: GetAllAddressesUsecase,
        @inject(CreateAddressUseCase)
        private readonly createAddressUseCase: CreateAddressUseCase,
        @inject(EditAddressUseCase)
        private readonly editAddressUseCase: EditAddressUseCase,
        @inject(DeleteAddressUseCase)
        private readonly deleteAddressUseCase: DeleteAddressUseCase,
        @inject(CreateAddressMapper)
        private readonly createAddressMapper: CreateAddressMapper,
        @inject(EditAddressMapper)
        private readonly editAddressMapper: EditAddressMapper,
        @inject(GetCountriesUseCase)
        private readonly getCountriesUseCase: GetCountriesUseCase,
        @inject(GetAllTitularityTypesUseCase)
        private readonly getTitularityTypesUseCase: GetAllTitularityTypesUseCase,
        @inject(SearchPostalCodesUseCase)
        private readonly searchPostalCodesUseCase: SearchPostalCodesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAddresses(),
            this.getCountries(),
            this.getTitularityTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAddresses(): Promise<void> {
        const addresses = await this.getAllAddressesUseCase.execute();
        runInAction(() => {
            this.addresses = addresses;
        });
    }

    @action
    setShowAddAddressModal(show: boolean): void {
        this.showAddAddressModal = show;
    }

    @action
    closeEditAddressModal(): void {
        this.showEditAddressModal = false;
    }

    @action
    openEditAddressModal(address: Undefinable<Address>): void {
        this.addressToEdit = address;
        this.showEditAddressModal = true;
    }

    async addAddress(
        entityId: Undefinable<number>,
        values: AddressFormValuesValidated,
    ): Promise<Nullable<Address>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createAddressResult = await this.createAddressUseCase.execute(
            this.createAddressMapper.mapFromFormValues(entityId, values),
        );

        if (createAddressResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createAddressResult;
    }

    async editAddress(values: AddressFormValuesValidated): Promise<void> {
        if (!this.addressToEdit?.id || !this.addressToEdit.entity)
            throw Error("Missing address");

        LoadLayoutStore.start();
        const editAddressResult = await this.editAddressUseCase.execute(
            this.editAddressMapper.mapFromModal(
                this.addressToEdit.id,
                this.addressToEdit.entity,
                values,
            ),
        );

        if (editAddressResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadAddresses(): Promise<void> {
        await this.getAddresses();
    }

    async deleteAddress(addressId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteAddressUseCase.execute(addressId);
        LoadLayoutStore.finish();
    }

    async getCountries(): Promise<void> {
        const countries = await this.getCountriesUseCase.execute();

        runInAction(() => {
            this.countries = countries;
        });
    }

    async getTitularityTypes(): Promise<void> {
        const titularityTypes = await this.getTitularityTypesUseCase.execute();

        runInAction(() => {
            this.titularityTypes = titularityTypes;
        });
    }

    async searchPostalCodes(query: string): Promise<PostalCode[]> {
        return this.searchPostalCodesUseCase.execute(query);
    }
}
