import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreateSupplierBody } from "@entity/data/dto/supplier/create-supplier.body";
import { EditSupplierBody } from "@entity/data/dto/supplier/edit-supplier.body";
import { ServiceProvidedDto } from "@entity/data/dto/supplier/service-provided-enum.dto";
import { SupplierIdentificationTypeDto } from "@entity/data/dto/supplier/supplier-identification-type.dto";
import { SupplierTypeDto } from "@entity/data/dto/supplier/supplier-type-enum.dto";
import { SupplierDto } from "@entity/data/dto/supplier/supplier.dto";
import {
    SupplierQuery,
    SuppliersDto,
} from "@entity/data/dto/supplier/suppliers.dto";
import { CreateSupplierMapper } from "@entity/data/mappers/supplier/create-supplier.mapper";
import { EditSupplierMapper } from "@entity/data/mappers/supplier/edit-supplier.mapper";
import { ServiceProvidedMapper } from "@entity/data/mappers/supplier/service-provided.mapper";
import { SupplierIdentificationTypeMapper } from "@entity/data/mappers/supplier/supplier-identification-type.mapper";
import { SupplierTypeMapper } from "@entity/data/mappers/supplier/supplier-type.mapper";
import { SupplierMapper } from "@entity/data/mappers/supplier/supplier.mapper";
import { SuppliersMapper } from "@entity/data/mappers/supplier/suppliers.mapper";
import { EntitySupplierError } from "@entity/domain/errors/entity.error";
import { SupplierMapperError } from "@entity/domain/errors/supplier/supplier-mapper.error";
import { SupplierIdentificationType } from "@entity/domain/models/supplier-identification-type.model";
import { CreateSupplier } from "@entity/domain/models/supplier/create-supplier.model";
import { EditSupplier } from "@entity/domain/models/supplier/edit-supplier.model";
import { ServiceProvided } from "@entity/domain/models/supplier/service-provided.model";
import { SupplierType } from "@entity/domain/models/supplier/supplier-type.model";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import {
    SupplierSearchFilters,
    Suppliers,
} from "@entity/domain/models/supplier/suppliers.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const SUPPLIERS_PATH = "/suppliers/";

@injectable()
export class SupplierDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(SupplierMapper)
        private readonly supplierMapper: SupplierMapper,
        @inject(CreateSupplierMapper)
        private readonly createSupplierMapper: CreateSupplierMapper,
        @inject(EditSupplierMapper)
        private readonly editSupplierMapper: EditSupplierMapper,
        @inject(SuppliersMapper)
        private readonly suppliersSummaryMapper: SuppliersMapper,
        @inject(SupplierTypeMapper)
        private readonly supplierTypeMapper: SupplierTypeMapper,
        @inject(SupplierIdentificationTypeMapper)
        private readonly supplierIdentificationTypeMapper: SupplierIdentificationTypeMapper,
        @inject(ServiceProvidedMapper)
        private readonly serviceProvidedMapper: ServiceProvidedMapper,
    ) { }

    async create(
        newSupplier: CreateSupplier,
    ): Promise<Either<ValidationError | EntitySupplierError | SupplierMapperError, Supplier>> {
        const supplierBody =
            this.createSupplierMapper.mapToCreateDto(newSupplier);

        const supplierResult = await this.http.post<
            SupplierDto,
            CreateSupplierBody
        >(SUPPLIERS_PATH, supplierBody);

        return supplierResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntitySupplierError("createSupplierError");
            })
            .flatMap((response) => {
                const supplier = this.supplierMapper.map(
                    plainToClass(SupplierDto, response.data),
                );
                if (!supplier) {
                    return Either.Left(new SupplierMapperError());
                }
                return Either.Right(supplier);
            });
    }

    async edit(
        editSupplier: EditSupplier,
    ): Promise<Either<ValidationError | EntitySupplierError | SupplierMapperError, Supplier>> {
        const supplierBody = this.editSupplierMapper.mapToDto(editSupplier);

        const supplierResult = await this.http.patch<
            SupplierDto,
            EditSupplierBody
        >(`${SUPPLIERS_PATH}${editSupplier.id}/`, supplierBody);

        return supplierResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntitySupplierError("editSupplierError");
            })
            .flatMap((response) => {
                const supplier = this.supplierMapper.map(
                    plainToClass(SupplierDto, response.data),
                );
                if (!supplier) {
                    return Either.Left(new SupplierMapperError());
                }
                return Either.Right(supplier);
            });
    }

    async delete(supplierId: number): Promise<Either<EntitySupplierError, true>> {
        const supplierResult = await this.http.delete(
            `${SUPPLIERS_PATH}${supplierId}/`,
        );

        return supplierResult
            .mapLeft(() => new EntitySupplierError("deleteSupplierError"))
            .map(() => true);
    }

    async fetchById(
        supplierId: number,
    ): Promise<Either<EntitySupplierError | SupplierMapperError, Supplier>> {
        const supplierResult = await this.http.get<SupplierDto>(
            `${SUPPLIERS_PATH}${supplierId}/`,
        );

        return supplierResult
            .mapLeft(() => new EntitySupplierError("getSupplierError"))
            .flatMap((response) => {
                const supplier = this.supplierMapper.map(
                    plainToClass(SupplierDto, response.data),
                );
                if (!supplier) {
                    return Either.Left(new SupplierMapperError());
                }
                return Either.Right(supplier);
            });
    }

    async fetchBy(
        pagination: Pagination,
        filters?: SupplierSearchFilters,
    ): Promise<Either<HttpError, Suppliers>> {
        const query: SupplierQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.search) query.search = filters.search;

        if (filters?.active) {
            query.active = filters.active;
        }
        if (filters?.dateTo) {
            query.dateTo = filters.dateTo.toISODate();
        }
        if (filters?.dateFrom) {
            query.dateFrom = filters.dateFrom.toISODate();
        }

        if (filters?.entityIds) query.entity__in = filters.entityIds.join(",");

        const suppliersResult = await this.http.get<SuppliersDto>(
            SUPPLIERS_PATH,
            {
                query,
            },
        );

        return suppliersResult
            .map((response) =>
                this.suppliersSummaryMapper.map(
                    plainToClass(SuppliersDto, response.data),
                ),
            )
            .mapLeft(() => new EntitySupplierError("getSuppliersError"));
    }

    async fetchAllSupplierTypes(): Promise<
        Either<EntitySupplierError, SupplierType[]>
    > {
        const responseResult = await this.http.get<SupplierTypeDto[]>(
            `${SUPPLIERS_PATH}types/`,
        );

        return responseResult
            .mapLeft(() => new EntitySupplierError("getSupplierTypesError"))
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.supplierTypeMapper.map(
                        plainToClass(SupplierTypeDto, typeDto),
                    ),
                ),
            );
    }

    // eslint-disable-next-line id-length
    async fetchAllSupplierIdentificationDocumentTypes(): Promise<
        Either<EntitySupplierError, SupplierIdentificationType[]>
    > {
        const supplierIdentificationTypesResult = await this.http.get<
            SupplierIdentificationTypeDto[]
        >(`${SUPPLIERS_PATH}identification_types/`);

        return supplierIdentificationTypesResult
            .mapLeft(() => new EntitySupplierError("getSupplierIdentificationTypesError"))
            .map((response) =>
                response.data.mapNotNull((supplierIdentificationType) =>
                    this.supplierIdentificationTypeMapper.map(
                        plainToClass(
                            SupplierIdentificationTypeDto,
                            supplierIdentificationType,
                        ),
                    ),
                ),
            );
    }

    async fetchAllServiceProvided(): Promise<
        Either<EntitySupplierError, ServiceProvided[]>
    > {
        const serviceProvidedResult = await this.http.get<ServiceProvidedDto[]>(
            `${SUPPLIERS_PATH}service_provided/`,
        );

        return serviceProvidedResult
            .mapLeft(() => new EntitySupplierError("getAllServiceProvidedError"))
            .map((response) =>
                response.data.mapNotNull((serviceProvided) =>
                    this.serviceProvidedMapper.map(
                        plainToClass(ServiceProvidedDto, serviceProvided),
                    ),
                ),
            );
    }
}
