import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { PolicyProtocolDatasource } from "@entity/data/datasources/policy-protocol.datasource";
import { EntityPolicyProtocolNumberError } from "@entity/domain/errors/entity.error";
import { PolicyProtocolMapperError } from "@entity/domain/errors/policy-protocol/policy-protocol-mapper.error";
import { PolicyProtocolType } from "@entity/domain/models/policy-protocol-type.model";
import { CreatePolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/create-policy-protocol.model";
import { EditPolicyProtocol } from "@entity/domain/models/quality-framework/policy-protocol/edit-policy-protocol.model";
import {
    PolicyProtocol,
    PolicyProtocols,
} from "@entity/domain/models/quality-framework/policy-protocol/policy-protocol.model";
import { inject, injectable } from "inversify";

@injectable()
export class PolicyProtocolRepository {
    constructor(
        @inject(PolicyProtocolDatasource)
        private readonly policyProtocolDatasource: PolicyProtocolDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityPolicyProtocolNumberError, PolicyProtocols>> {
        const policyProtocolResult =
            await this.policyProtocolDatasource.fetchAll(pagination);

        return policyProtocolResult.mapLeft(() => new EntityPolicyProtocolNumberError("fetchPolicyProtocolsError"));
    }

    async create(
        policyProtocol: CreatePolicyProtocol,
    ): Promise<Either<ValidationError | EntityPolicyProtocolNumberError | PolicyProtocolMapperError, PolicyProtocol>> {
        return this.policyProtocolDatasource.create(policyProtocol);
    }

    async edit(
        editPolicyProtocol: EditPolicyProtocol,
    ): Promise<Either<ValidationError | EntityPolicyProtocolNumberError | PolicyProtocolMapperError, PolicyProtocol>> {
        return this.policyProtocolDatasource.edit(editPolicyProtocol);
    }

    async delete(
        policyProtocolId: number,
    ): Promise<Either<EntityPolicyProtocolNumberError, true>> {
        const policyProtocolResult =
            await this.policyProtocolDatasource.delete(policyProtocolId);
        return policyProtocolResult.mapLeft(() => new EntityPolicyProtocolNumberError("deletePolicyProtocolError"));
    }

    async getAllPolicyProtocolTypes(): Promise<
        Either<EntityPolicyProtocolNumberError, PolicyProtocolType[]>
    > {
        return this.policyProtocolDatasource.fetchAllPolicyProtocolTypes();
    }
}
