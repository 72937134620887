import { CreateRelative } from "@beneficiary/domain/models/relatives/create-relative.model";
import { EditRelative } from "@beneficiary/domain/models/relatives/edit-relative.model";
import {
    Relative,
    RelativesSearchFilters,
} from "@beneficiary/domain/models/relatives/relative.model";
import { coreTypes } from "@core/core-types.di";
import type { Http } from "@core/data/infrastructures/http/http";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateRelativesDto } from "../dto/relatives/create-relatives.body";
import { EditRelativesDto } from "../dto/relatives/edit-relatives.body";
import {
    RelativesDto,
    RelativesSummaryDto,
    RelativesSummaryQuery,
} from "../dto/relatives/relatives.dto";
import { CreateRelativeMapper } from "../mappers/relatives/create-relative.mapper";
import { EditRelativeMapper } from "../mappers/relatives/edit-relative.mapper";
import { RelativeMapper } from "../mappers/relatives/relative.mapper";
import { BeneficiaryRelativesError } from "@beneficiary/domain/errors/beneficiary.error";
import { RelativesMapperError } from "@beneficiary/domain/errors/relatives/relatives-mapper.error";

const RELATIVES_PATH = "/family_members/";

@injectable()
export class RelativesDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(RelativeMapper)
        private readonly relativeMapper: RelativeMapper,
        @inject(CreateRelativeMapper)
        private readonly createRelativeMapper: CreateRelativeMapper,
        @inject(EditRelativeMapper)
        private readonly editRelativeMapper: EditRelativeMapper,
    ) { }

    async fetchBy(
        pagination: Pagination,
        filters?: RelativesSearchFilters,
    ): Promise<Either<BeneficiaryRelativesError, Relative[]>> {
        const query: RelativesSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.search) query.search = filters.search;
        if (filters?.beneficiaryId) query.beneficiary = filters.beneficiaryId;

        const relativesResult = await this.http.get<RelativesSummaryDto>(
            RELATIVES_PATH,
            { query },
        );

        const result = relativesResult
            .mapLeft(() => new BeneficiaryRelativesError("fetchAllByError"))
            .map((relativeResponse) =>
                relativeResponse.data.results.mapNotNull((response) =>
                    this.relativeMapper.map(
                        plainToClass(RelativesDto, response),
                    ),
                ),
            );

        return result;
    }

    async create(
        createRelative: CreateRelative,
        beneficiaryId: number,
    ): Promise<Either<BeneficiaryRelativesError | RelativesMapperError, Relative>> {
        const createRelativeDto = this.createRelativeMapper.mapToDto(
            createRelative,
            beneficiaryId,
        );

        const relativeResult = await this.http.post<
            RelativesDto,
            CreateRelativesDto
        >(RELATIVES_PATH, createRelativeDto);

        return relativeResult
            .mapLeft(() => new BeneficiaryRelativesError("createError"))
            .flatMap((response) => {
                const createdRelative = this.relativeMapper.map(
                    plainToClass(RelativesDto, response.data),
                );
                if (!createdRelative) return Either.Left(new RelativesMapperError());

                return Either.Right(createdRelative);
            });
    }

    async update(
        editRelative: EditRelative,
        beneficiaryId: number,
    ): Promise<Either<BeneficiaryRelativesError | RelativesMapperError, Relative>> {
        const editRelativeDto = this.editRelativeMapper.mapToDto(
            editRelative,
            beneficiaryId,
        );

        const relativeResult = await this.http.patch<
            RelativesDto,
            EditRelativesDto
        >(`${RELATIVES_PATH}${editRelative.id}/`, editRelativeDto);

        return relativeResult
            .mapLeft(() => new BeneficiaryRelativesError("editError"))
            .flatMap((response) => {
                const editedRelative = this.relativeMapper.map(
                    plainToClass(RelativesDto, response.data),
                );
                if (!editedRelative) return Either.Left(new RelativesMapperError());

                return Either.Right(editedRelative);
            });
    }

    async delete(relativeId: number): Promise<Either<BeneficiaryRelativesError, true>> {
        const deleteRelative = await this.http.delete(
            `${RELATIVES_PATH}${relativeId}`,
        );

        return deleteRelative
            .mapLeft(() => new BeneficiaryRelativesError("deleteError"))
            .map(() => true);
    }
}
