/* eslint-disable max-lines */
import {
    DownloadOutlined,
    LeftOutlined,
    ProjectOutlined,
} from "@ant-design/icons";
import { Undefinable, isDefined } from "@core/domain/types/undefinable.type";
import { IncCard } from "@core/presentacion/component/data-display/card/inc-card.component";
import { IncSpreadsheet } from "@core/presentacion/component/data-entry/spreadsheet/inc-spreadsheet.component";
import { IncSkeleton } from "@core/presentacion/component/feedback/skeleton/inc-skeleton.component";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IncButton } from "@core/presentacion/component/general/button/inc-button.component";
import { IncCol } from "@core/presentacion/component/layout/col/inc-col.component";
import { IncRow } from "@core/presentacion/component/layout/row/inc-row.component";
import { useViewModel } from "@core/presentacion/hook/use-view-model/use-view-model.hook";
import { container } from "@di/inversify.config";
import { HotTableClass } from "@handsontable/react";
import { BudgetSpreadsheetValidationTypeEnum } from "@project/domain/models/budget-spreadsheet.model";
import { EditProjectNavigationState } from "@project/presentation/components/project-form/project-form.component";
import {
    BudgetSpreadsheetCategoryView,
    BudgetSpreadsheetGroupView,
    BudgetSpreadsheetItemView,
    BudgetSpreadsheetValidationView,
    BudgetSpreadsheetValueView,
    CellTypeData,
    EditBudgetPageViewModel,
} from "@project/presentation/pages/edit-budget/edit-budget-page.viewmodel";
import { ProjectRoutes } from "@routes/private/project.routes";
import { theme } from "@tailwind";
import { isNumber, isNumberString } from "class-validator";
import Handsontable from "handsontable/base";
import { DetailedSettings } from "handsontable/plugins/columnSummary/columnSummary";
import { CommentObject } from "handsontable/plugins/comments";
import { runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import numbro from "numbro";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import colors from "tailwindcss/colors";
// @ts-expect-error: Missing typescript definitions for language configuration objets
import esEs from "numbro/languages/es-ES";

numbro.registerLanguage(esEs, true);

/* eslint-disable max-lines-per-function */
const _EditBudgetPage: FC = () => {
    const params = useParams<"projectId" | "projectFinancialEntityId">();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const handsontableRef = useRef<HotTableClass>(null);

    const projectFinancialEntityId = Number(params.projectFinancialEntityId);

    const viewModel = useViewModel(() => {
        if (isNaN(projectFinancialEntityId)) {
            throw new Error("Invalid project financial entity id");
        }

        const vmInstance = container.get(EditBudgetPageViewModel);
        vmInstance.projectFinancialEntityId = projectFinancialEntityId;

        return vmInstance;
    });

    const getColumnId = (prop: string): string => prop.split(".")[0];

    const columns = useMemo<Handsontable.ColumnSettings[]>(() => {
        // Columns for Category, Group and Item
        const fixedColumns: Handsontable.ColumnSettings[] = [
            {
                // eslint-disable-next-line id-blacklist
                data: "categoryLabel",
                title: " ",
                readOnly: true,
                width: 200,
            },
            {
                // eslint-disable-next-line id-blacklist
                data: "groupLabel",
                title: " ",
                readOnly: true,
                width: 200,
            },
            {
                // eslint-disable-next-line id-blacklist
                data: "itemLabel",
                title: " ",
                readOnly: true,
                width: 200,
            },
        ];

        const dynamicColumns: Handsontable.ColumnSettings[] = (
            viewModel.budgetSpreadsheetInmutable?.columns ?? []
        ).map((column) => ({
            // @see BudgetSpreadsheetValueView
            // eslint-disable-next-line id-blacklist
            data: `${column.id}.value`,
            title: column.label,
        }));

        return fixedColumns.concat(dynamicColumns);
    }, [viewModel.budgetSpreadsheetInmutable?.columns]);

    // Generate summary (sum) for each dynamic column at category/group level
    const columnSummary = useMemo<DetailedSettings[]>(() => {
        const summary: DetailedSettings[] = [];

        (viewModel.budgetSpreadsheetInmutable?.columns ?? []).forEach(
            (_column, columnIndex) => {
                let currentRowIndex = 0;
                const allRanges: [number, number][] = [];

                (viewModel.budgetSpreadsheetInmutable?.rows ?? []).forEach(
                    (category) => {
                        // We track the start index of the category to use it as destination row
                        const categoryStartIndex = currentRowIndex;
                        // Ranges are an array of arrays, each array is a range of rows
                        // So if we need to include for summary rows index from 1-6, 10 and 12-15
                        // ranges will contain  [1, 6], [10, 10], [12, 15]
                        // The same for groupRanges
                        const categoryRanges: [number, number][] = [];

                        category.__children.forEach((group) => {
                            currentRowIndex += 1;
                            // We track the start index of the group to use it as destination row
                            const groupStartIndex = currentRowIndex;
                            const groupRanges: number[][] = [];

                            // eslint-disable-next-line max-nested-callbacks
                            group.__children.forEach((item) => {
                                currentRowIndex += 1;

                                categoryRanges.push([
                                    // We are at item level (row) and the value is below
                                    currentRowIndex + 1,
                                    currentRowIndex + item.__children.length,
                                ]);

                                groupRanges.push([
                                    // We are at item level (row) and the value is below
                                    currentRowIndex + 1,
                                    currentRowIndex + item.__children.length,
                                ]);

                                allRanges.push([
                                    // We are at item level (row) and the value is below
                                    currentRowIndex + 1,
                                    currentRowIndex + item.__children.length,
                                ]);

                                // eslint-disable-next-line max-nested-callbacks
                                item.__children.forEach(() => {
                                    currentRowIndex += 1;
                                });
                            });

                            // push summary for group with all item ranges
                            summary.push({
                                destinationRow: groupStartIndex,
                                ranges: groupRanges,
                                destinationColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                sourceColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                type: "sum",
                            });
                        });

                        // push summary for category with all group ranges
                        if (
                            category.categoryId ===
                            EditBudgetPageViewModel.TOTAL_SUMMARY_CATEGORY_ID
                        ) {
                            summary.push({
                                destinationRow: currentRowIndex,
                                // destinationRow: 0,
                                // reversedRowCoords: true,
                                ranges: allRanges,
                                destinationColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                sourceColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                type: "sum",
                            });
                        } else {
                            summary.push({
                                destinationRow: categoryStartIndex,
                                ranges: categoryRanges,
                                destinationColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                sourceColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                type: "sum",
                            });

                            // push budget summary with all ranges
                        }

                        currentRowIndex += 1;
                    },
                );
            },
        );

        return summary;
    }, [
        viewModel.budgetSpreadsheetInmutable?.columns,
        viewModel.budgetSpreadsheetInmutable?.rows,
    ]);

    const handleExportFinancialEntity = useCallback(async () => {
        if (isNaN(projectFinancialEntityId)) {
            throw new Error("Invalid project financial entity id");
        }
        await viewModel.exportFinancialEntity(projectFinancialEntityId);
    }, [projectFinancialEntityId, viewModel]);

    const handleCells = useCallback(
        function cells(
            this: Handsontable.CellProperties,
            row: number,
            column: number,
            prop: string | number,
        ): Handsontable.CellMeta {
            /* eslint-disable react/no-this-in-sfc */
            const rowObject = this.instance.getSourceDataAtRow(
                row,
            ) as CellTypeData;

            const cellProperties: Handsontable.CellMeta = {
                readOnly: true,
                renderer(
                    _instance,
                    tableCell,
                    _row,
                    col,
                    _prop,
                    _value,
                    _cellProperties,
                ) {
                    let columnType;
                    runInAction(() => {
                        if (
                            viewModel.budgetSpreadsheetInmutable?.columns[
                            col -
                            EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                            ]
                        )
                            columnType =
                                viewModel.budgetSpreadsheetInmutable.columns[
                                    col -
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                ].type;
                    });

                    if (
                        typeof _value === "number" &&
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        columnType ===
                        BudgetSpreadsheetValidationTypeEnum.Amount
                    ) {
                        // In normal circumstances we should specify format via columns options with numericFormat and type: numeric
                        // But we are using renderer to format the value and set styles, so we need to format the value here
                        tableCell.innerHTML = numbro(_value).formatCurrency({
                            average: false,
                            thousandSeparated: true,
                            mantissa: 2,
                        });
                    } else if (
                        typeof _value === "number" &&
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        columnType ===
                        BudgetSpreadsheetValidationTypeEnum.Percentage
                    ) {
                        tableCell.innerHTML = numbro(_value).formatCurrency({
                            average: false,
                            thousandSeparated: false,
                            mantissa: 2,
                            currencySymbol: "%",
                        });
                    } else {
                        tableCell.innerHTML = _value;
                    }

                    // @ts-expect-error: TW
                    const errorColor = theme.extend.colors["coral-red"];

                    // We display the cell in different colors depending on the level. Also for category/group we handle invalid cell display style
                    if (
                        Object.hasOwn(
                            rowObject,
                            "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
                        )
                    ) {
                        tableCell.style.backgroundColor =
                            _cellProperties.valid === false
                                ? errorColor
                                : // @ts-expect-error: TW
                                theme.extend.colors.cyan[950];
                        tableCell.style.color = colors.white;
                        tableCell.style.fontWeight = "bold";
                        tableCell.style.fontSize = "1rem";
                        tableCell.style.textAlign = "center";
                        tableCell.style.verticalAlign = "middle";
                        tableCell.style.padding = "0.5rem";
                        tableCell.style.borderBottom = "1px solid #fff";
                    } else if (
                        Object.hasOwn(
                            rowObject,
                            "groupId" satisfies keyof BudgetSpreadsheetGroupView,
                        ) &&
                        col >= EditBudgetPageViewModel.GROUP_COLUMN_INDEX
                    ) {
                        tableCell.style.backgroundColor =
                            _cellProperties.valid === false
                                ? errorColor
                                : // @ts-expect-error: TW
                                theme.extend.colors.cyan[800];
                        // @ts-expect-error: TW
                        tableCell.style.color = theme.extend.colors.white;
                        tableCell.style.fontWeight = "bold";
                        tableCell.style.fontSize = "0.9rem";
                        tableCell.style.textAlign = "center";
                        tableCell.style.verticalAlign = "middle";
                        tableCell.style.padding = "0.5rem";
                        tableCell.style.borderBottom = "1px solid #fff";
                    } else if (
                        Object.hasOwn(
                            rowObject,
                            "itemId" satisfies keyof BudgetSpreadsheetItemView,
                        ) &&
                        col >= EditBudgetPageViewModel.ITEM_COLUMN_INDEX
                    ) {
                        tableCell.style.backgroundColor =
                            // @ts-expect-error: TW
                            theme.extend.colors.cyan[500];
                        // @ts-expect-error: TW
                        tableCell.style.color = theme.extend.colors.white;
                        tableCell.style.fontSize = "0.8rem";
                        tableCell.style.textAlign = "left";
                        tableCell.style.verticalAlign = "middle";
                        tableCell.style.padding = "0.5rem";
                        tableCell.style.borderBottom = "1px solid #fff";
                    } else if (
                        col >= EditBudgetPageViewModel.VALUE_COLUMN_INDEX
                    ) {
                        tableCell.style.backgroundColor =
                            // @ts-expect-error: TW
                            theme.extend.colors.cyan[200];
                        tableCell.style.color = colors.black;
                        tableCell.style.fontSize = "0.8rem";
                        tableCell.style.textAlign = "left";
                        tableCell.style.verticalAlign = "middle";
                        tableCell.style.padding = "0.5rem";
                        tableCell.style.borderBottom = "1px solid #fff";
                    }
                },
            };

            // Only dynamic columns are editable, and they are identified as [idColumn].value
            if (
                this.readOnly === false &&
                typeof prop === "string" &&
                prop.includes("value")
            ) {
                // Example of prop: 1.value where 1 is the column id
                const id = Number(getColumnId(prop));

                if (
                    isNumber(id) &&
                    isDefined(
                        // We guess the type of the rowObject, that why optional chaining
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        (rowObject as BudgetSpreadsheetValueView)?.[id]?.value,
                    )
                ) {
                    cellProperties.readOnly = false;
                    // We set the type of the cell to numeric format be applied
                    cellProperties.type = "numeric";
                }
            }

            return cellProperties;

            /* eslint-enable react/no-this-in-sfc */
        },
        [viewModel.budgetSpreadsheetInmutable?.columns],
    );

    const isPercentageColumn = useCallback((columnId: number): boolean => {
        if (!viewModel.budgetSpreadsheetInmutable?.columns) return false;

        // Encontrar la columna por ID
        const column = viewModel.budgetSpreadsheetInmutable.columns.find(col => col.id === columnId);

        if (!column) return false;

        // Verificar si es de tipo porcentaje
        return column.type === BudgetSpreadsheetValidationTypeEnum.Percentage ||
            column.label.toLowerCase().includes('%');
    }, [viewModel.budgetSpreadsheetInmutable?.columns]);

    const handleAfterChange = useCallback(
        function afterChange(
            this: Handsontable,
            changes: Handsontable.CellChange[] | null,
            source: Handsontable.ChangeSource,
        ): void {
            /* eslint-disable react/no-this-in-sfc */
            if (!changes) {
                return;
            }

            if (source === 'internal' as string) return;

            if (source === "edit" && changes) {

                // Procesar cambios del usuario y calcular sumas
                this.batch(() => {
                    const tableColumns = this.getSettings().columns;

                    changes.forEach(([row, prop, _oldValue, newValue]) => {
                        const rowObject = this.getSourceDataAtRow(row) as BudgetSpreadsheetValueView;

                        if (typeof prop === "string") {
                            const columnId = getColumnId(prop);

                            if (isNumberString(columnId)) {
                                const columnIndex: number = this.propToCol(prop);
                                const valueItem = rowObject[Number(columnId)];

                                if (valueItem) {
                                    const formattedValue = numbro(newValue).value();

                                    if (newValue !== formattedValue) {
                                        this.setDataAtCell(row, columnIndex, typeof formattedValue === 'number' ? formattedValue : Number(formattedValue), 'internal');
                                    }

                                    const cellValid = this.getCellMeta(row, columnIndex).valid;
                                    if (cellValid === true) {
                                        viewModel.setChangedCell(valueItem.id, formattedValue);
                                    } else if (viewModel.hasChangedCell(valueItem.id)) {
                                        viewModel.removeChangedCell(valueItem.id);
                                    }
                                }
                            }
                        }
                    });

                    // Calcular sumas totales
                    if (Array.isArray(tableColumns)) {
                        const totalColumns = tableColumns.filter(
                            col => col.title &&
                                (col.title.toString().toLowerCase().includes("suma") ||
                                    col.title.toString().toLowerCase().includes("total")) &&
                                !isPercentageColumn(parseInt(col.data?.toString().split('.')[0] || '0'))
                        );

                        changes.forEach(([row]) => {
                            const rowData = this.getSourceDataAtRow(row) as Record<string, unknown>;

                            const sum = Object.keys(rowData)
                                .filter(key =>
                                    !totalColumns.some(col => {
                                        const colKey = typeof col.data === 'string' ?
                                            col.data.split('.')[0] :
                                            col.data;
                                        return colKey === key;
                                    }) &&
                                    typeof (rowData[key] as { value: number })?.value === "number" &&
                                    !isPercentageColumn(parseInt(key))
                                )
                                .reduce((acc, key) => {
                                    const cellData = rowData[key];
                                    return acc + ((
                                        typeof cellData === 'object' &&
                                        cellData !== null &&
                                        'value' in cellData
                                    ) ? (cellData.value as number) : 0);
                                }, 0);

                            totalColumns.forEach(col => {
                                const columnIndex = tableColumns.indexOf(col);
                                if (columnIndex !== -1) {
                                    this.setDataAtCell(row, columnIndex, sum, 'internal');

                                    // Agregar esta línea para rastrear el cambio en el viewModel
                                    const propertyName = this.colToProp(columnIndex);
                                    const columnId = getColumnId(propertyName.toString());
                                    const rowData = this.getSourceDataAtRow(row);
                                    if (rowData && isNumberString(columnId)) {
                                        const valueItem = (rowData as Record<string, any>)[Number(columnId)];
                                        if (valueItem && valueItem.id) {
                                            viewModel.setChangedCell(valueItem.id, sum);
                                        }
                                    }
                                }
                            });
                        });
                    }
                });

                // Usar setTimeout para asegurar que las sumas se han actualizado completamente
                setTimeout(() => {
                    if (this.isDestroyed) return;

                    // Calcular porcentajes DESPUÉS de que las sumas se han asentado
                    this.batch(() => {
                        const tableColumns = this.getSettings().columns;
                        if (!Array.isArray(tableColumns)) return;

                        const totalColumns = tableColumns.filter(col =>
                            col.title &&
                            (col.title.toString().toLowerCase().includes("suma") ||
                                col.title.toString().toLowerCase().includes("total")) &&
                            !isPercentageColumn(parseInt(col.data?.toString().split('.')[0] || '0'))
                        );

                        const percentageColumns = tableColumns.filter(col =>
                            col.title &&
                            (col.title.toString().toLowerCase().includes("%") ||
                                col.title.toString().toLowerCase().includes("porcentaje") ||
                                col.title.toString().toLowerCase().includes("percentage"))
                        );

                        if (totalColumns.length === 0 || percentageColumns.length === 0) return;

                        // Encontrar fila con total del proyecto
                        const rowCount = this.countRows();
                        let projectTotalValue = 0;

                        for (let row = 0; row < rowCount; row++) {
                            const rowData = this.getSourceDataAtRow(row);
                            if (rowData &&
                                (rowData as BudgetSpreadsheetCategoryView).categoryLabel?.toString()
                                    .toLowerCase().includes("sumatoria total")) {
                                totalColumns.forEach(totalCol => {
                                    const totalColIndex = tableColumns.indexOf(totalCol);
                                    if (totalColIndex !== -1) {
                                        const value = this.getDataAtCell(row, totalColIndex);
                                        projectTotalValue = typeof value === 'number' ? value : 0;
                                    }
                                });
                                break;
                            }
                        }

                        if (projectTotalValue <= 0) return;

                        // Actualizar porcentajes
                        for (let row = 0; row < rowCount; row++) {
                            const rowData = this.getSourceDataAtRow(row);

                            if (rowData &&
                                !Object.hasOwn(rowData, "categoryId") &&
                                !Object.hasOwn(rowData, "groupId") &&
                                !Object.hasOwn(rowData, "itemId")) {

                                totalColumns.forEach(totalCol => {
                                    const totalColIndex = tableColumns.indexOf(totalCol);
                                    if (totalColIndex === -1) return;

                                    const rowValue = this.getDataAtCell(row, totalColIndex);
                                    if (typeof rowValue !== 'number') return;

                                    percentageColumns.forEach(percentCol => {
                                        const percentColIndex = tableColumns.indexOf(percentCol);
                                        if (percentColIndex === -1) return;

                                        const percentage = (rowValue / projectTotalValue) * 100;
                                        this.setDataAtCell(row, percentColIndex, percentage, 'internal');

                                        // Agregar esta línea para rastrear el cambio en el viewModel
                                        const propertyName = this.colToProp(percentColIndex);
                                        const columnId = getColumnId(propertyName.toString());
                                        const rowData = this.getSourceDataAtRow(row);
                                        if (rowData && isNumberString(columnId)) {
                                            const valueItem = (rowData as Record<number, any>)[Number(columnId)];
                                            if (valueItem && valueItem.id) {
                                                viewModel.setChangedCell(valueItem.id, percentage);
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    });
                }, 1000); // 100ms es suficiente para que las sumas se actualicen completamente
            } else if (source === "CopyPaste.paste" && changes) {
                changes.forEach(([row, colProp, _oldValue, newValue]) => {

                    const rowObject = this.getSourceDataAtRow(row) as BudgetSpreadsheetValueView;

                    if (typeof colProp === "string") {
                        const columnId = getColumnId(colProp);
                        const valueItem = rowObject[Number(columnId)];

                        if (isNumberString(columnId) && valueItem) {
                            const columnIndex: number = this.propToCol(colProp);
                            const formattedValue = numbro(newValue).value();

                            if (newValue !== formattedValue) {
                                this.setDataAtCell(row, columnIndex, Number(formattedValue), 'internal');
                            }

                            if (this.getCellMeta(row, columnIndex).valid === true) {
                                viewModel.setChangedCell(valueItem.id, valueItem.value);
                            } else if (viewModel.hasChangedCell(valueItem.id)) {
                                viewModel.removeChangedCell(valueItem.id);
                            }
                        }
                    }
                });
            }
            /* eslint-enable react/no-this-in-sfc */
        },
        [viewModel, isPercentageColumn]
    );

    const handleUpdateBudget = useCallback(async () => {
        viewModel.setValidatingBeforeSave(true);

        // We validate all cells before save
        handsontableRef.current?.hotInstance?.validateCells(
            async (isValid: boolean) => {
                if (isValid) {
                    const edited = await viewModel.updateBudget();

                    if (edited) {
                        viewModel.getBudget();
                    }
                } else {
                    ToastManagerStore.warning(
                        t("private:project.editBudget.someInvalidCellError"),
                    );
                }

                viewModel.setValidatingBeforeSave(false);
            },
        );
    }, [t, viewModel]);

    const handleGoBack = useCallback(() => {
        const projectId = Number(params.projectId);
        if (isNaN(projectId)) {
            throw new Error("Invalid project id");
        }

        const state: EditProjectNavigationState = {
            defaultTab: "budget",
        };

        navigate(ProjectRoutes.EDIT(projectId), {
            state,
        });
    }, [navigate, params.projectId]);

    const renderInstance = (
        self: Handsontable.CellProperties,
        callBack: (valid: boolean) => void,
        valid: boolean,
        message?: string,
    ): void => {
        callBack(valid);

        if (valid) {
            self.instance.removeCellMeta(self.row, self.col, "comment");
        } else if (message) {
            const comment: CommentObject = {
                value: message,
                readOnly: true,
            };
            self.instance.setCellMeta(self.row, self.col, "comment", comment);
        }

        self.instance.render();
    };

    const validatePercentage = useCallback(
        (
            self: Handsontable.CellProperties,
            callBack: (valid: boolean) => void,
            validation: BudgetSpreadsheetValidationView,
            value: Handsontable.CellValue,
        ): void => {
            if (validation.percentage?.validateAgainstTotal) {
                if (value > validation.valueToCompare) {
                    renderInstance(self, callBack, false, validation.message);
                } else {
                    renderInstance(self, callBack, true);
                }
            } else {
                /**
                 * These are the 4 cases that can occur:
                 *
                 *  type 1- validation of item type (NOT belonging to a group but belonging to a category).
                 *  type 2- item type validation (belonging to a group and category)
                 *  type 3- validation of type group
                 *  type 4- category type validation
                 */

                const categoryRowIndexToCompare = self.instance
                    .getSourceData()
                    .findIndex(
                        (maybeCategoryRow) =>
                            Object.hasOwn(
                                maybeCategoryRow,
                                "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
                            ) &&
                            maybeCategoryRow.categoryId ===
                            validation.percentage?.categoryIdToCompare,
                    );

                // All validations must have category
                if (categoryRowIndexToCompare === -1) {
                    renderInstance(self, callBack, true);

                    return;
                }

                const categoryRowToCompare = self.instance.getSourceDataAtRow(
                    categoryRowIndexToCompare,
                ) as BudgetSpreadsheetCategoryView;

                let groupRowIndexToCompare: Undefinable<number> = undefined;

                // verify that it is a validation of type item that haven´t a parent group (type 1)
                if (
                    validation.percentage?.itemIdToCompare &&
                    !validation.percentage.groupIdToCompare
                ) {
                    const children: BudgetSpreadsheetItemView[] = (
                        categoryRowToCompare.__children as BudgetSpreadsheetGroupView[]
                    )
                        .map((group) => group.__children)
                        .flat() as BudgetSpreadsheetItemView[];

                    const items = children.filter(
                        (item) =>
                            item.itemId ===
                            validation.percentage?.itemIdToCompare,
                    )[0].__children;

                    const childrenValue = items[0][validation.columnId].value;

                    const percent = 100;
                    const percentToCompare =
                        childrenValue * (validation.valueToCompare / percent);

                    if (value > percentToCompare) {
                        renderInstance(
                            self,
                            callBack,
                            false,
                            validation.message,
                        );
                    } else {
                        renderInstance(self, callBack, true);
                    }
                }
                // verify that it is a validation of type item that has a parent group (type 2)
                else if (
                    validation.percentage?.itemIdToCompare &&
                    validation.percentage.groupIdToCompare
                ) {
                    groupRowIndexToCompare =
                        categoryRowToCompare.__children.findIndex(
                            (group) =>
                                group.groupId ===
                                validation.percentage?.groupIdToCompare,
                        );

                    let children: BudgetSpreadsheetItemView[] = [];
                    if (groupRowIndexToCompare === -1) {
                        children = (
                            categoryRowToCompare.__children as BudgetSpreadsheetGroupView[]
                        )
                            .map((group) => group.__children)
                            .flat() as BudgetSpreadsheetItemView[];
                    } else {
                        children = (
                            categoryRowToCompare.__children as BudgetSpreadsheetGroupView[]
                        )[groupRowIndexToCompare]
                            .__children as BudgetSpreadsheetItemView[];
                    }
                    const items = children.filter(
                        (item) =>
                            item.itemId ===
                            validation.percentage?.itemIdToCompare,
                    )[0].__children;

                    const childrenValue = (
                        items as BudgetSpreadsheetValueView[]
                    ).reduce(
                        (acc, item) => (acc += item[validation.columnId].value),
                        0,
                    );

                    const percent = 100;
                    const percentToCompare =
                        childrenValue * (validation.valueToCompare / percent);

                    if (value > percentToCompare) {
                        renderInstance(
                            self,
                            callBack,
                            false,
                            validation.message,
                        );
                    } else {
                        renderInstance(self, callBack, true);
                    }
                    // if it is not of type item but is of type group (type 3)
                } else if (validation.percentage?.groupIdToCompare) {
                    groupRowIndexToCompare =
                        categoryRowToCompare.__children.findIndex(
                            (group) =>
                                group.groupId ===
                                validation.percentage?.groupIdToCompare,
                        );

                    if (groupRowIndexToCompare !== undefined) {
                        const children =
                            categoryRowToCompare.__children[
                                groupRowIndexToCompare
                            ].__children;

                        const valueGroupToCompare = children.reduce(
                            (acc, i) =>
                            (acc +=
                                i.__children[0][validation.columnId].value),
                            0,
                        );

                        const percent = 100;
                        const percentToCompare =
                            valueGroupToCompare *
                            (validation.valueToCompare / percent);

                        if (value > percentToCompare) {
                            renderInstance(
                                self,
                                callBack,
                                false,
                                validation.message,
                            );
                        } else {
                            renderInstance(self, callBack, true);
                        }
                    } else {
                        renderInstance(self, callBack, true);
                    }
                    // if it is of type category and is neither of type group nor of type item (type 4)
                } else {
                    const valueCAtegoryToCompare =
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        categoryRowToCompare[validation.columnId]?.value ?? 0;

                    const percent = 100;
                    const percentToCompare =
                        valueCAtegoryToCompare *
                        (validation.valueToCompare / percent);

                    if (value > percentToCompare) {
                        renderInstance(
                            self,
                            callBack,
                            false,
                            validation.message,
                        );
                    } else {
                        renderInstance(self, callBack, true);
                    }
                }
            }
        },
        [],
    );

    const validate = useCallback(
        (
            validationsValuesFiltered: BudgetSpreadsheetValidationView[],
            self: Handsontable.CellProperties,
            value: Handsontable.CellValue,
            callBack: (valid: boolean) => void,
        ): void => {
            validationsValuesFiltered.forEach((validation) => {
                const isAmountValidation =
                    validation.type ===
                    BudgetSpreadsheetValidationTypeEnum.Amount;

                const isPercentageValidation =
                    validation.type ===
                    BudgetSpreadsheetValidationTypeEnum.Percentage;

                const isOnlyValueBasedValidation =
                    isAmountValidation ||
                    (isPercentageValidation &&
                        validation.percentage === undefined);

                if (
                    isOnlyValueBasedValidation &&
                    value > validation.valueToCompare
                ) {
                    renderInstance(self, callBack, false, validation.message);
                } else if (isPercentageValidation) {
                    validatePercentage(self, callBack, validation, value);
                } else {
                    renderInstance(self, callBack, true);
                }
            });
        },
        [validatePercentage],
    );

    const handleItemWithGroupValidator = useCallback(
        function validator(
            this: Handsontable.CellProperties,
            row: BudgetSpreadsheetGroupView,
            callBack: (valid: boolean) => void,
            hasChildrenValidation: boolean,
            col: string,
        ): boolean {
            row.__children.forEach((childrenItem) => {
                const validations = childrenItem.validations;
                const validationsValues = validations
                    ? Array.from(validations.values())
                    : [];

                const validationsValuesFiltered: BudgetSpreadsheetValidationView[] =
                    validationsValues.filter(
                        (validationsValue) =>
                            validationsValue.columnId === Number(col),
                    );

                if (validationsValuesFiltered.length) {
                    hasChildrenValidation = true;
                    const childrenValue = (
                        childrenItem.__children as BudgetSpreadsheetValueView[]
                    ).reduce(
                        (acc, item) => (acc += item[Number(col)].value),
                        0,
                    );

                    validate(
                        validationsValuesFiltered,
                        this,
                        childrenValue,
                        callBack,
                    );
                } else {
                    renderInstance(this, callBack, true);
                }
            });
            return hasChildrenValidation;
        },
        [validate],
    );

    const handleItemWithoutGroupValidator = useCallback(
        function validator(
            this: Handsontable.CellProperties,
            row: BudgetSpreadsheetGroupView,
            callBack: (valid: boolean) => void,
            hasChildrenValidation: boolean,
            col: string,
        ): boolean {
            row.__children.forEach((childrenItem) => {
                const validations = childrenItem.validations;

                const validationsValues = validations
                    ? Array.from(validations.values())
                    : [];

                const validationsValuesFiltered: BudgetSpreadsheetValidationView[] =
                    validationsValues.filter(
                        (validationsValue) =>
                            validationsValue.columnId === Number(col),
                    );

                if (validationsValuesFiltered.length) {
                    hasChildrenValidation = true;
                    const childrenValue =
                        childrenItem.__children[0][Number(col)].value;

                    validate(
                        validationsValuesFiltered,
                        this,
                        childrenValue,
                        callBack,
                    );
                }
            });
            return hasChildrenValidation;
        },
        [validate],
    );

    const handleValidator = useCallback(
        function validator(
            this: Handsontable.CellProperties,
            value: Handsontable.CellValue,
            callBack: (valid: boolean) => void,
        ): void {
            /*  eslint-disable react/no-this-in-sfc */
            const row = this.instance.getSourceDataAtRow(this.row) as
                | BudgetSpreadsheetCategoryView
                | BudgetSpreadsheetGroupView;

            // For example, if we have a category with a validation of 20000, the sum of all values of the summary columns should not be greater than 20000
            // A category/group can define different validations for each column
            if (
                Object.hasOwn(
                    row,
                    "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
                ) ||
                Object.hasOwn(
                    row,
                    "groupId" satisfies keyof BudgetSpreadsheetGroupView,
                )
            ) {
                const colProp = this.instance.colToProp(this.col);

                const col = getColumnId(colProp.toString());

                let hasChildrenValidation = false;

                // If it is a group type, the validations of the children "items" must be checked. Since we cannot access the item validations in other ways
                if (
                    Object.hasOwn(
                        row,
                        "groupId" satisfies keyof BudgetSpreadsheetGroupView,
                    )
                ) {
                    hasChildrenValidation = handleItemWithGroupValidator.bind(
                        this,
                        row as BudgetSpreadsheetGroupView,
                        callBack,
                        hasChildrenValidation,
                        col,
                    )();
                } else if (
                    Object.hasOwn(
                        row,
                        "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
                    )
                ) {
                    // The edge case is checked in that it has a category, but not a group, but has items with validations.
                    row.__children.forEach((group) => {
                        if (
                            (group as BudgetSpreadsheetGroupView).groupId === 0
                        ) {
                            hasChildrenValidation =
                                handleItemWithoutGroupValidator.bind(
                                    this,
                                    group as BudgetSpreadsheetGroupView,
                                    callBack,
                                    hasChildrenValidation,
                                    col,
                                )();
                        }
                    });
                }
                if (!hasChildrenValidation) {
                    const validations = (
                        row as
                        | BudgetSpreadsheetCategoryView
                        | BudgetSpreadsheetGroupView
                    ).validations;

                    const validationsValues = validations
                        ? Array.from(validations.values())
                        : [];

                    const validationsValuesFiltered: BudgetSpreadsheetValidationView[] =
                        validationsValues.filter(
                            (validationsValue) =>
                                validationsValue.columnId === Number(col),
                        );

                    if (validationsValuesFiltered.length) {
                        validate(
                            validationsValuesFiltered,
                            this,
                            value,
                            callBack,
                        );
                    } else {
                        renderInstance(this, callBack, true);
                    }
                }
            } else {
                renderInstance(this, callBack, true);
            }

            /*  eslint-enable react/no-this-in-sfc */
        },
        [
            validate,
            handleItemWithGroupValidator,
            handleItemWithoutGroupValidator,
        ],
    );

    return (
        <>
            <IncRow gutter={["medium", "medium"]}>
                <IncCol span={24}>
                    <IncCard>
                        <IncRow
                            align={"middle"}
                            justify={"space-between"}
                        >
                            <IncCol span={12}>
                                <IncRow
                                    gutter={"small"}
                                    justify={"start"}
                                    align={"middle"}
                                >
                                    <IncCol>
                                        <IncButton
                                            onClick={handleGoBack}
                                            icon={<LeftOutlined />}
                                        />
                                    </IncCol>
                                    <IncCol>
                                        <ProjectOutlined
                                            className={"tw-text-3xl"}
                                        />
                                    </IncCol>
                                    <IncCol>
                                        <h2>
                                            {t(
                                                "private:project.editBudget.title",
                                            )}
                                        </h2>
                                    </IncCol>
                                </IncRow>
                            </IncCol>
                            <IncCol>
                                <IncRow
                                    gutter={"small"}
                                    justify={"end"}
                                    align={"middle"}
                                >
                                    <IncCol>
                                        <IncButton.Form
                                            type={"primary"}
                                            icon={<DownloadOutlined />}
                                            onClick={
                                                handleExportFinancialEntity
                                            }
                                        >
                                            {t(
                                                "private:project.editBudget.exportFinancialEntityBtn",
                                            )}
                                        </IncButton.Form>
                                    </IncCol>
                                    <IncCol>
                                        <IncButton.Form
                                            type={"primary"}
                                            disabled={!viewModel.anyCellChanged}
                                            loading={
                                                viewModel.validatingBeforeSave
                                            }
                                            onClick={handleUpdateBudget}
                                        >
                                            {viewModel.validatingBeforeSave
                                                ? t(
                                                    "private:project.editBudget.validating",
                                                )
                                                : t("common:saveButton")}
                                        </IncButton.Form>
                                    </IncCol>
                                </IncRow>
                            </IncCol>
                        </IncRow>
                    </IncCard>
                </IncCol>
            </IncRow>
            <IncRow className={"tw-mt-4"}>
                <IncCol span={24}>
                    <IncCard>
                        <IncSkeleton loading={viewModel.initialLoading}>
                            {viewModel.budgetSpreadsheetMutable ? (
                                <div
                                    style={{
                                        height: "550px",
                                        width: "100%",
                                        overflow: "auto",
                                    }}
                                >
                                    <IncSpreadsheet
                                        width={"100%"}
                                        height={550}
                                        ref={handsontableRef}
                                        key={viewModel.budgetSpreadsheetMutable.uuid}
                                        afterChange={handleAfterChange}
                                        manualColumnResize={true}
                                        manualRowResize={true}
                                        outsideClickDeselects={false}
                                        persistentState={true}
                                        columns={columns}
                                        columnSummary={columnSummary}
                                        data={toJS(viewModel.budgetSpreadsheetMutable.rows)}
                                        comments
                                        rowHeaders
                                        nestedRows
                                        bindRowsWithHeaders
                                        cells={handleCells}
                                    />
                                </div>
                            ) : null}
                        </IncSkeleton>
                    </IncCard>
                </IncCol>
            </IncRow>
        </>
    );
};

export const EditBudgetPage = observer(_EditBudgetPage);
