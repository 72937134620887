import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum ExpenseTypeEnumDto {
    NONE = "NONE",
    PERSONNEL_EXPENSES = "PERSONNEL EXPENSES",
    TRAVEL = "TRAVEL",
    ACCOMMODATION_MEALS = "ACCOMMODATION_MEALS",
    PERSONNEL_MANAGEMENT_ADMINISTRATION = "PERSONEEL_MANAGEMENT_ADMINISTRATION",
    MAINTENANCE_ACTIVITIES = "MAINTENANCE_ACTIVITIES",
    INVESTMENT = "INVESTMENT",
    MANAGEMENT_ADMINISTRATION = "MANAGEMENT_ADMINISTRATION",
    OTHER_SUBSIDIZABLE_EXPENSES = "OTHER_SUBSIDIZABLE_EXPENSES",
}

export class ExpenseTypeDto {
    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    key!: ExpenseTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
