import { coreTypes } from "@core/core-types.di";
import { PaginatedQueryDto } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass, plainToInstance } from "class-transformer";
import { inject, injectable } from "inversify";
import { CreateRoom, Room, Rooms } from "../../domain/models/locals/room.model";
import { RoomsSearchFilters } from "../../domain/models/locals/rooms-search-filters";
import { CreateRoomBody } from "../dto/locals/create-room.body";
import { RoomDto, RoomsDto, RoomsQuery } from "../dto/locals/room.dto";
import { RoomMapper } from "../mappers/locals/room.mapper";
import { RoomsMapper } from "../mappers/locals/rooms.mapper";
import { EntityRoomError } from "@entity/domain/errors/entity.error";
import { RoomMapperError } from "@entity/domain/errors/room/room-mapper.error";

const ROOM_PATH = "/rooms/";

@injectable()
export class RoomDatasource {
    constructor(
        @inject(RoomMapper) private readonly roomMapper: RoomMapper,
        @inject(RoomsMapper) private readonly roomsMapper: RoomsMapper,
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
    ) { }

    async fetchAll(): Promise<Either<EntityRoomError, Room[]>> {
        const roomsResult = await this.http.get<RoomsDto>(ROOM_PATH);

        return roomsResult
            .mapLeft(() => new EntityRoomError("fetchRoomsError"))
            .map((response) => {
                const rooms = response.data.results.mapNotNull((room) =>
                    this.roomMapper.map(plainToClass(RoomDto, room)),
                );
                return rooms;
            });
    }

    async fetchAllPaginated(
        limit?: number,
        offset?: number,
    ): Promise<Either<HttpError, Rooms>> {
        const query: PaginatedQueryDto = {
            limit,
            offset,
        };
        const roomsResult = await this.http.get<RoomsDto>(ROOM_PATH, {
            query,
        });

        return roomsResult.map((response) =>
            this.roomsMapper.map(plainToClass(RoomsDto, response.data)),
        );
    }

    async create(
        createRoom: CreateRoom,
    ): Promise<Either<ValidationError | EntityRoomError | RoomMapperError, Room>> {
        const createdRoomDto = this.roomMapper.mapToCreate(createRoom);

        const createRoomResult = await this.http.post<RoomDto, CreateRoomBody>(
            ROOM_PATH,
            createdRoomDto,
        );

        return createRoomResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityRoomError("createRoomError");
            })
            .flatMap((response) => {
                const createdRoom = this.roomMapper.map(
                    plainToClass(RoomDto, response.data),
                );

                if (!createdRoom) return Either.Left(new RoomMapperError());

                return Either.Right(createdRoom);
            });
    }

    async delete(roomId: number): Promise<Either<EntityRoomError, boolean>> {
        const deleteRoomResult = await this.http.delete(
            `${ROOM_PATH}${roomId}/`,
        );

        return deleteRoomResult
            .mapLeft(() => new EntityRoomError("deleteRoomError"))
            .map(() => true);
    }

    async fetchAllBy(
        pagination: Pagination,
        filters?: RoomsSearchFilters,
    ): Promise<Either<EntityRoomError, Rooms>> {
        const query: RoomsQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.localId) {
            query.local = filters.localId;
        }

        const responseResult = await this.http.get<RoomsDto>(ROOM_PATH, {
            query,
        });

        return responseResult
            .mapLeft(() => new EntityRoomError("fetchFilteredRoomsError"))
            .map((response) =>
                this.roomsMapper.map(plainToInstance(RoomsDto, response.data)),
            );
    }
}
