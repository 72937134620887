import { BeneficiaryError } from "@beneficiary/domain/errors/beneficiary.error";
import { EducationLevelDatasource } from "@core/data/datasources/education-level.datasource";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class EducationLevelRepository {
    constructor(
        @inject(EducationLevelDatasource)
        private readonly educationLevelDatasource: EducationLevelDatasource,
    ) { }

    async getAll(): Promise<Either<BeneficiaryError, EducationLevel[]>> {
        return this.educationLevelDatasource.fetchAllEducationLevels();
    }
}
