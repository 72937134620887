import { inject, injectable } from "inversify";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { Either } from "@core/domain/types/either";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { PostalCodeDto } from "../dto/postal-code.dto";
import { plainToClass } from "class-transformer";
import { PostalCode } from "../../domain/models/postal-code.model";
import { PostalCodeMapper } from "../mappers/postal-code.mapper";

@injectable()
export class PostalCodeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(PostalCodeMapper)
        private readonly postalCodeMapper: PostalCodeMapper,
    ) { }

    async search(query: string): Promise<Either<FallbackError, PostalCode[]>> {
        const response = await this.http.get<PostalCodeDto[]>('/postal_codes/search/', {
            query: { q: query }
        });

        return response
            .mapLeft(() => new FallbackError())
            .map((result) =>
                result.data.mapNotNull((dto) =>
                    this.postalCodeMapper.map(plainToClass(PostalCodeDto, dto))
                )
            );
    }
}