import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { AuthorityManagerDatasource } from "@entity/data/datasources/authority-manager.datasource";
import { AuthorityManagerMapperError } from "@entity/domain/errors/authority-manager/authority-manager-mapper.error";
import { EntityAuthorityManagerError } from "@entity/domain/errors/entity.error";
import {
    AuthorityManager,
    AuthorityManagers,
} from "@entity/domain/models/authority-manager/authority-manager.model";
import { CreateAuthorityManager } from "@entity/domain/models/authority-manager/create-authority-manager.model";
import { EditAuthorityManager } from "@entity/domain/models/authority-manager/edit-authority-manager.model";
import { MandateTerminationReason } from "@entity/domain/models/mandate-termination-reason.model";
import { PositionType } from "@entity/domain/models/position-type.model";
import { inject, injectable } from "inversify";

@injectable()
export class AuthorityManagerRepository {
    constructor(
        @inject(AuthorityManagerDatasource)
        private readonly authorityManagerDatasource: AuthorityManagerDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityAuthorityManagerError, AuthorityManagers>> {
        const materialResult =
            await this.authorityManagerDatasource.fetchAll(pagination);

        return materialResult.mapLeft(() => new EntityAuthorityManagerError("fetchAllAuthorityManagersError"));
    }

    async create(
        authorityManager: CreateAuthorityManager,
    ): Promise<Either<ValidationError | EntityAuthorityManagerError | AuthorityManagerMapperError, AuthorityManager>> {
        return this.authorityManagerDatasource.create(authorityManager);
    }

    async edit(
        editAuthorityManager: EditAuthorityManager,
    ): Promise<Either<ValidationError | EntityAuthorityManagerError | AuthorityManagerMapperError, AuthorityManager>> {
        return this.authorityManagerDatasource.edit(editAuthorityManager);
    }

    async delete(
        authorityManagerId: number,
    ): Promise<Either<EntityAuthorityManagerError, true>> {
        const authorityManagerResult =
            await this.authorityManagerDatasource.delete(authorityManagerId);
        return authorityManagerResult.mapLeft(() => new EntityAuthorityManagerError("deleteAuthorityManagerError"));
    }

    async getAllMandateTerminationReasons(): Promise<
        Either<EntityAuthorityManagerError, MandateTerminationReason[]>
    > {
        return this.authorityManagerDatasource.fetchAllMandateTerminationReasons();
    }

    async getAllPositionTypes(): Promise<
        Either<EntityAuthorityManagerError, PositionType[]>
    > {
        return this.authorityManagerDatasource.fetchAllPositionTypes();
    }
}
