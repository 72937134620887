import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreatePartnersNumberBody } from "@entity/data/dto/partners-number/create-partners-number.body";
import { EditPartnersNumberBody } from "@entity/data/dto/partners-number/edit-partners-number.body";
import {
    PartnersNumberDto,
    PartnersNumbersDto,
} from "@entity/data/dto/partners-number/partners-number.dto";
import { CreatePartnersNumberMapper } from "@entity/data/mappers/partners-number/create-partners-number.mapper";
import { EditPartnersNumberMapper } from "@entity/data/mappers/partners-number/edit-partners-number.mapper";
import { PartnersNumbersMapper } from "@entity/data/mappers/partners-number/partners-numbers.mapper";
import { EntityPartnersNumberError } from "@entity/domain/errors/entity.error";
import { PartnersMapperError } from "@entity/domain/errors/partners-number/partners-number-mapper.error";
import { CreatePartnersNumber } from "@entity/domain/models/partners-number/create-partners-number.model";
import { EditPartnersNumber } from "@entity/domain/models/partners-number/edit-partners-number.model";
import {
    PartnersNumber,
    PartnersNumbers,
} from "@entity/domain/models/partners-number/partners-number.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const PARTNERS_NUMBER_PATH = "/partners/";

@injectable()
export class PartnersNumberDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(PartnersNumbersMapper)
        private readonly partnersNumbersMapper: PartnersNumbersMapper,
        @inject(CreatePartnersNumberMapper)
        private readonly createPartnersNumberMapper: CreatePartnersNumberMapper,
        @inject(EditPartnersNumberMapper)
        private readonly editPartnersNumberMapper: EditPartnersNumberMapper,
    ) { }

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, PartnersNumbers>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const partnersNumberResult = await this.http.get<PartnersNumbersDto>(
            PARTNERS_NUMBER_PATH,
            {
                query,
            },
        );

        return partnersNumberResult.map((response) =>
            this.partnersNumbersMapper.map(
                plainToClass(PartnersNumbersDto, response.data),
            ),
        );
    }

    async create(
        newPartnersNumber: CreatePartnersNumber,
    ): Promise<Either<ValidationError | EntityPartnersNumberError | PartnersMapperError, PartnersNumber>> {
        const patrnersNumberBody =
            this.createPartnersNumberMapper.mapToCreateDto(newPartnersNumber);

        const partnersNumberResult = await this.http.post<
            PartnersNumberDto,
            CreatePartnersNumberBody
        >(PARTNERS_NUMBER_PATH, patrnersNumberBody);

        return partnersNumberResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityPartnersNumberError("createPartnersNumberError");
            })
            .flatMap((response) => {
                const partnersNumber =
                    this.partnersNumbersMapper.mapPartnersNumber(
                        plainToClass(PartnersNumberDto, response.data),
                    );

                if (!partnersNumber) return Either.Left(new PartnersMapperError());

                return Either.Right(partnersNumber);
            });
    }

    async edit(
        editPartnersNumber: EditPartnersNumber,
    ): Promise<Either<ValidationError | EntityPartnersNumberError | PartnersMapperError, PartnersNumber>> {
        const editedPartnersNumber =
            this.editPartnersNumberMapper.mapToDto(editPartnersNumber);

        const editPartnersNumberResult = await this.http.put<
            PartnersNumberDto,
            EditPartnersNumberBody
        >(
            `${PARTNERS_NUMBER_PATH}${editPartnersNumber.id}/`,
            editedPartnersNumber,
        );

        return editPartnersNumberResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityPartnersNumberError("editPartnersNumberError");
            })
            .flatMap((response) => {
                const partnersNumber =
                    this.partnersNumbersMapper.mapPartnersNumber(
                        plainToClass(PartnersNumberDto, response.data),
                    );

                if (!partnersNumber) return Either.Left(new PartnersMapperError());

                return Either.Right(partnersNumber);
            });
    }

    async delete(
        partnersNumberId: number,
    ): Promise<Either<EntityPartnersNumberError, true>> {
        const deletePartnersNumber = await this.http.delete(
            `${PARTNERS_NUMBER_PATH}${partnersNumberId}`,
        );

        return deletePartnersNumber
            .mapLeft(() => new EntityPartnersNumberError("deletePartnersNumberError"))
            .map(() => true);
    }
}
