import { MaritalStatusDto } from "@beneficiary/data/dto/marital-status.dto";
import { MaritalStatusMapper } from "@beneficiary/data/mappers/marital-status.mapper";
import { BeneficiaryMaritalStatusError } from "@beneficiary/domain/errors/beneficiary.error";
import { MaritalStatus } from "@beneficiary/domain/models/marital-status.model";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class MaritalStatusDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(MaritalStatusMapper)
        private readonly maritalStatusMapper: MaritalStatusMapper,
    ) { }

    async fetchAllMaritalStatuses(): Promise<
        Either<BeneficiaryMaritalStatusError, MaritalStatus[]>
    > {
        const maritalStatusResutls = await this.http.get<MaritalStatusDto[]>(
            "/beneficiaries/civil_status/",
        );

        return maritalStatusResutls
            .mapLeft(() => new BeneficiaryMaritalStatusError("fetchAllError"))
            .map((genderResponse) =>
                genderResponse.data.mapNotNull((maritalStatusDto) =>
                    this.maritalStatusMapper.map(
                        plainToClass(MaritalStatusDto, maritalStatusDto),
                    ),
                ),
            );
    }
}
