import { GendersDatasource } from "@beneficiary/data/datasource/genders.datasource";
import { BeneficiaryGendersError } from "@beneficiary/domain/errors/beneficiary.error";
import { Gender } from "@beneficiary/domain/models/gender.model";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class GendersRepository {
    constructor(
        @inject(GendersDatasource)
        private readonly genderDatasource: GendersDatasource,
    ) { }

    async getAll(): Promise<Either<BeneficiaryGendersError, Gender[]>> {
        return this.genderDatasource.fetchAllGenders();
    }
}
