import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { PartnersNumberDatasource } from "@entity/data/datasources/partners-number.datasource";
import { EntityPartnersNumberError } from "@entity/domain/errors/entity.error";
import { PartnersMapperError } from "@entity/domain/errors/partners-number/partners-number-mapper.error";
import { CreatePartnersNumber } from "@entity/domain/models/partners-number/create-partners-number.model";
import { EditPartnersNumber } from "@entity/domain/models/partners-number/edit-partners-number.model";
import {
    PartnersNumber,
    PartnersNumbers,
} from "@entity/domain/models/partners-number/partners-number.model";
import { inject, injectable } from "inversify";

@injectable()
export class PartnersNumberRepository {
    constructor(
        @inject(PartnersNumberDatasource)
        private readonly partnersNumberDatasource: PartnersNumberDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityPartnersNumberError, PartnersNumbers>> {
        const partnersNumbersResult =
            await this.partnersNumberDatasource.fetchAll(pagination);

        return partnersNumbersResult.mapLeft(() => new EntityPartnersNumberError("fetchPartnersNumbersError"));
    }

    async create(
        partnersNumber: CreatePartnersNumber,
    ): Promise<Either<ValidationError | EntityPartnersNumberError | PartnersMapperError, PartnersNumber>> {
        return this.partnersNumberDatasource.create(partnersNumber);
    }

    async edit(
        editPartnersNumber: EditPartnersNumber,
    ): Promise<Either<ValidationError | EntityPartnersNumberError | PartnersMapperError, PartnersNumber>> {
        return this.partnersNumberDatasource.edit(editPartnersNumber);
    }

    async delete(
        partnersNumberId: number,
    ): Promise<Either<EntityPartnersNumberError, true>> {
        const partnersNumberResult =
            await this.partnersNumberDatasource.delete(partnersNumberId);
        return partnersNumberResult.mapLeft(() => new EntityPartnersNumberError("deletePartnersNumberError"));
    }
}
