import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { SupplierDatasource } from "@entity/data/datasources/supplier.datasource";
import { EntitySupplierError } from "@entity/domain/errors/entity.error";
import { SupplierMapperError } from "@entity/domain/errors/supplier/supplier-mapper.error";
import { SupplierIdentificationType } from "@entity/domain/models/supplier-identification-type.model";
import { CreateSupplier } from "@entity/domain/models/supplier/create-supplier.model";
import { EditSupplier } from "@entity/domain/models/supplier/edit-supplier.model";
import { ServiceProvided } from "@entity/domain/models/supplier/service-provided.model";
import { SupplierType } from "@entity/domain/models/supplier/supplier-type.model";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import {
    SupplierSearchFilters,
    Suppliers,
} from "@entity/domain/models/supplier/suppliers.model";
import { inject, injectable } from "inversify";

@injectable()
export class SupplierRepository {
    constructor(
        @inject(SupplierDatasource)
        private readonly supplierDatasource: SupplierDatasource,
    ) { }

    async create(
        createSupplier: CreateSupplier,
    ): Promise<Either<ValidationError | EntitySupplierError | SupplierMapperError, Supplier>> {
        return this.supplierDatasource.create(createSupplier);
    }

    async edit(
        editSupplier: EditSupplier,
    ): Promise<Either<ValidationError | EntitySupplierError | SupplierMapperError, Supplier>> {
        return this.supplierDatasource.edit(editSupplier);
    }

    async delete(supplierId: number): Promise<Either<EntitySupplierError, true>> {
        const result = await this.supplierDatasource.delete(supplierId);
        return result.mapLeft(() => new EntitySupplierError("deleteSupplierError"));
    }

    async getById(
        supplierId: number,
    ): Promise<Either<EntitySupplierError | SupplierMapperError, Supplier>> {
        return this.supplierDatasource.fetchById(supplierId);
    }

    async getBy(
        pagination: Pagination,
        filters?: SupplierSearchFilters,
    ): Promise<Either<EntitySupplierError, Suppliers>> {
        const suppliersSummary = await this.supplierDatasource.fetchBy(
            pagination,
            filters,
        );
        return suppliersSummary.mapLeft(() => new EntitySupplierError("getSuppliersError"));
    }

    async getAllBy(
        filters?: SupplierSearchFilters,
    ): Promise<Either<EntitySupplierError, Supplier[]>> {
        const suppliersResult = await this.getBy(
            Pagination.NoPagination(),
            filters,
        );

        return suppliersResult.map((suppliers) => suppliers.suppliers);
    }

    async getAllSupplierTypes(): Promise<
        Either<EntitySupplierError, SupplierType[]>
    > {
        return this.supplierDatasource.fetchAllSupplierTypes();
    }

    // eslint-disable-next-line id-length
    async getAllSupplierIdentificationDocumentTypes(): Promise<
        Either<EntitySupplierError, SupplierIdentificationType[]>
    > {
        return this.supplierDatasource.fetchAllSupplierIdentificationDocumentTypes();
    }

    async getAllServiceProvided(): Promise<
        Either<EntitySupplierError, ServiceProvided[]>
    > {
        return this.supplierDatasource.fetchAllServiceProvided();
    }
}
