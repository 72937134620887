import {
    AccommodationExpense,
    Expense,
    InvestmentExpense,
    MaintenanceExpense,
    ManagementExpense,
    PersonnelExpense,
    TravelExpense,
    OtherSubsidizableExpense
} from "./expense.model";

export type CreateExpenseAllTypes =
    | CreateInvestmentExpense
    | CreateOtherSubsidizableExpense
    | CreateMaintenanceExpense
    | CreateManagementExpense
    | CreateAccommodationExpense
    | CreateTravelExpense
    | CreatePersonnelExpense;

export class CreateExpense {
    constructor(
        readonly projectId: number,
        readonly typeId: Expense["typeId"],
        readonly percentageImputation: Expense["percentageImputation"],
        readonly amountImputation: Expense["amountImputation"],
        readonly paymentDate: Expense["paymentDate"],
        readonly documents: number[],
        readonly concept?: Expense["concept"],
    ) { }
}

export class CreateInvestmentExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly subtypeExpense: InvestmentExpense["subtypeExpense"],
        public readonly provider: InvestmentExpense["provider"],
        public readonly nifProvider: InvestmentExpense["nifProvider"],
        public readonly description: InvestmentExpense["description"],
        public readonly invoiceNumber: InvestmentExpense["invoiceNumber"],
        public readonly invoiceDate: InvestmentExpense["invoiceDate"],
        public readonly expenseDate: InvestmentExpense["expenseDate"],
        public readonly paymentMethod: InvestmentExpense["paymentMethod"],
        public readonly taxBase: InvestmentExpense["taxBase"],
        public readonly iva: InvestmentExpense["iva"],
        public readonly totalTaxWithIva: InvestmentExpense["totalTaxWithIva"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class CreateOtherSubsidizableExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly provider: OtherSubsidizableExpense["provider"],
        public readonly nifProvider: OtherSubsidizableExpense["nifProvider"],
        public readonly description: OtherSubsidizableExpense["description"],
        public readonly invoiceNumber: OtherSubsidizableExpense["invoiceNumber"],
        public readonly invoiceDate: OtherSubsidizableExpense["invoiceDate"],
        public readonly expenseDate: OtherSubsidizableExpense["expenseDate"],
        public readonly paymentMethod: OtherSubsidizableExpense["paymentMethod"],
        public readonly taxBase: OtherSubsidizableExpense["taxBase"],
        public readonly iva: OtherSubsidizableExpense["iva"],
        public readonly totalTaxWithIva: OtherSubsidizableExpense["totalTaxWithIva"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class CreateMaintenanceExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly subtypeExpense: MaintenanceExpense["subtypeExpense"],
        public readonly provider: MaintenanceExpense["provider"],
        public readonly nifProvider: MaintenanceExpense["nifProvider"],
        public readonly description: MaintenanceExpense["description"],
        public readonly invoiceNumber: MaintenanceExpense["invoiceNumber"],
        public readonly invoiceDate: MaintenanceExpense["invoiceDate"],
        public readonly expenseDate: MaintenanceExpense["expenseDate"],
        public readonly paymentMethod: MaintenanceExpense["paymentMethod"],
        public readonly taxBase: MaintenanceExpense["taxBase"],
        public readonly iva: MaintenanceExpense["iva"],
        public readonly totalTaxWithIva: MaintenanceExpense["totalTaxWithIva"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class CreateManagementExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly subtypeExpense: ManagementExpense["subtypeExpense"],
        public readonly provider: ManagementExpense["provider"],
        public readonly nifProvider: ManagementExpense["nifProvider"],
        public readonly invoiceNumber: ManagementExpense["invoiceNumber"],
        public readonly invoiceDate: ManagementExpense["invoiceDate"],
        public readonly expenseDate: ManagementExpense["expenseDate"],
        public readonly paymentMethod: ManagementExpense["paymentMethod"],
        public readonly taxBase: ManagementExpense["taxBase"],
        public readonly iva: ManagementExpense["iva"],
        public readonly totalTaxWithIva: ManagementExpense["totalTaxWithIva"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class CreateTravelExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly dni: TravelExpense["dni"],
        public readonly name: TravelExpense["name"],
        public readonly address: TravelExpense["address"],
        public readonly positionFunction: TravelExpense["positionFunction"],
        public readonly travelDestination: TravelExpense["travelDestination"],
        public readonly dateDeparture: TravelExpense["dateDeparture"],
        public readonly dateReturn: TravelExpense["dateReturn"],
        public readonly transport: TravelExpense["transport"],
        public readonly otherTransport: TravelExpense["otherTransport"],
        public readonly amount: TravelExpense["amount"],
        public readonly kilometers: TravelExpense["kilometers"],
        public readonly costPerKilometer: TravelExpense["costPerKilometer"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class CreateAccommodationExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly name: AccommodationExpense["name"],
        public readonly dni: AccommodationExpense["dni"],
        public readonly address: AccommodationExpense["address"],
        public readonly position: AccommodationExpense["position"],
        public readonly travel: AccommodationExpense["travel"],
        public readonly startDate: AccommodationExpense["startDate"],
        public readonly endDate: AccommodationExpense["endDate"],
        public readonly diet: AccommodationExpense["diet"],
        public readonly days: AccommodationExpense["days"],
        public readonly expensePersonDay: AccommodationExpense["expensePersonDay"],
        public readonly totalExpense: AccommodationExpense["totalExpense"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}

export class CreatePersonnelExpense extends CreateExpense {
    // eslint-disable-next-line max-params
    constructor(
        projectId: CreateExpense["projectId"],
        typeId: CreateExpense["typeId"],
        public readonly name: PersonnelExpense["name"],
        public readonly dni: PersonnelExpense["dni"],
        public readonly contractStartDate: PersonnelExpense["contractStartDate"],
        public readonly contractEndDate: PersonnelExpense["contractEndDate"],
        public readonly contractType: PersonnelExpense["contractType"],
        public readonly weeklyHours: PersonnelExpense["weeklyHours"],
        // public readonly professionalGroupExp: Nullable<ProfEnum>,
        public readonly contributionGroup: PersonnelExpense["contributionGroup"],
        public readonly month: PersonnelExpense["month"],
        public readonly daysImputable: PersonnelExpense["daysImputable"],
        public readonly netSalary: PersonnelExpense["netSalary"],
        public readonly irpf: PersonnelExpense["irpf"],
        public readonly socialSecurity: PersonnelExpense["socialSecurity"],
        public readonly monthlyGrossIncome: PersonnelExpense["monthlyGrossIncome"],
        public readonly companySocialSecurity: PersonnelExpense["companySocialSecurity"],
        public readonly totalCost: PersonnelExpense["totalCost"],
        percentageImputation: CreateExpense["percentageImputation"],
        amountImputation: CreateExpense["amountImputation"],
        paymentDate: CreateExpense["paymentDate"],
        documents: CreateExpense["documents"],
        concept?: CreateExpense["concept"],
    ) {
        super(
            projectId,
            typeId,
            percentageImputation,
            amountImputation,
            paymentDate,
            documents,
            concept,
        );
    }
}
