import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ProjectAuditoryDatasource } from "@entity/data/datasources/project-auditory.datasource";
import { EntityPolicyProjectAuditoryError } from "@entity/domain/errors/entity.error";
import { ProjectAuditoryMapperError } from "@entity/domain/errors/project-auditory/project-auditory-mapper.error";
import { CreateProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/create-project-auditory.model";
import { EditProjectAuditory } from "@entity/domain/models/quality-framework/project-auditory/edit-project-auditory.model";
import {
    ProjectAuditories,
    ProjectAuditory,
} from "@entity/domain/models/quality-framework/project-auditory/project-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class ProjectAuditoryRepository {
    constructor(
        @inject(ProjectAuditoryDatasource)
        private readonly projectAuditoryDatasource: ProjectAuditoryDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityPolicyProjectAuditoryError, ProjectAuditories>> {
        const projectAuditoryResult =
            await this.projectAuditoryDatasource.fetchAll(pagination);

        return projectAuditoryResult.mapLeft(() => new EntityPolicyProjectAuditoryError("fetchProjectAuditoriesError"));
    }

    async create(
        projectAuditory: CreateProjectAuditory,
    ): Promise<Either<ValidationError | EntityPolicyProjectAuditoryError | ProjectAuditoryMapperError, ProjectAuditory>> {
        return this.projectAuditoryDatasource.create(projectAuditory);
    }

    async edit(
        editProjectAuditory: EditProjectAuditory,
    ): Promise<Either<ValidationError | EntityPolicyProjectAuditoryError | ProjectAuditoryMapperError, ProjectAuditory>> {
        return this.projectAuditoryDatasource.edit(editProjectAuditory);
    }

    async delete(
        projectAuditoryId: number,
    ): Promise<Either<EntityPolicyProjectAuditoryError, true>> {
        const projectAuditoryResult =
            await this.projectAuditoryDatasource.delete(projectAuditoryId);
        return projectAuditoryResult.mapLeft(() => new EntityPolicyProjectAuditoryError("deleteProjectAuditoryError"));
    }
}
