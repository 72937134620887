import { coreTypes } from "@core/core-types.di";
import { PaginatedQuery } from "@core/data/dto/paginated.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { QualityCertificateTypeDto } from "@entity/data/dto/quality-certificate-type.dto";
import { CreateQualityCertificateBody } from "@entity/data/dto/quality-framework/quality-certificate/create-quality-certificate.body";
import { EditQualityCertificateBody } from "@entity/data/dto/quality-framework/quality-certificate/edit-quality-certificate.body";
import {
    QualityCertificateDto,
    QualityCertificatesDto,
} from "@entity/data/dto/quality-framework/quality-certificate/quality-certificate.dto";
import { QualityCertificateTypeMapper } from "@entity/data/mappers/quality-certificate-type.mapper";
import { CreateQualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/create-quality-certificate.mapper";
import { EditQualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/edit-quality-certificate.mapper";
import { QualityCertificatesMapper } from "@entity/data/mappers/quality-framework/quality-certificate/quality-certificates.mapper";
import { EntityQualityCertificateError } from "@entity/domain/errors/entity.error";
import { QualityCertificateMapperError } from "@entity/domain/errors/quality-certificate/quality-certificate-mapper.error";
import { QualityCertificateType } from "@entity/domain/models/quality-certificate-type.model";
import { CreateQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/create-quality-certificate.model";
import { EditQualityCertificate } from "@entity/domain/models/quality-framework/quality-certificate/edit-quality-certificate.model";
import {
    QualityCertificate,
    QualityCertificates,
} from "@entity/domain/models/quality-framework/quality-certificate/quality-certificate.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_QUALITY_CERTIFICATES_PATH = "/entities_quality_certificates/";

@injectable()
export class QualityCertificateDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(QualityCertificatesMapper)
        private readonly qualityCertificatesMapper: QualityCertificatesMapper,
        @inject(CreateQualityCertificateMapper)
        private readonly createQualityCertificateMapper: CreateQualityCertificateMapper,
        @inject(EditQualityCertificateMapper)
        private readonly editQualityCertificateMapper: EditQualityCertificateMapper,
        @inject(QualityCertificateTypeMapper)
        private readonly qualityCertificateTypeMapper: QualityCertificateTypeMapper,
    ) { }

    async fetchAll(
        pagination: Pagination,
    ): Promise<Either<HttpError, QualityCertificates>> {
        const query: PaginatedQuery = {
            limit: pagination.pageSize,
        };

        const qualityCertificatesResult =
            await this.http.get<QualityCertificateDto>(
                ENTITY_QUALITY_CERTIFICATES_PATH,
                {
                    query,
                },
            );

        return qualityCertificatesResult.map((response) =>
            this.qualityCertificatesMapper.map(
                plainToClass(QualityCertificatesDto, response.data),
            ),
        );
    }

    async create(
        newQualityCertificate: CreateQualityCertificate,
    ): Promise<Either<ValidationError | EntityQualityCertificateError | QualityCertificateMapperError, QualityCertificate>> {
        const qualityCertificateBody =
            this.createQualityCertificateMapper.mapToCreateDto(
                newQualityCertificate,
            );

        const qualityCertificateResult = await this.http.post<
            QualityCertificateDto,
            CreateQualityCertificateBody
        >(ENTITY_QUALITY_CERTIFICATES_PATH, qualityCertificateBody);

        return qualityCertificateResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityQualityCertificateError("createQualityCertificateError");
            })
            .flatMap((response) => {
                const qualityCertificate =
                    this.qualityCertificatesMapper.mapQualityCertificate(
                        plainToClass(QualityCertificateDto, response.data),
                    );

                if (!qualityCertificate)
                    return Either.Left(new QualityCertificateMapperError());

                return Either.Right(qualityCertificate);
            });
    }

    async edit(
        editQualityCertificate: EditQualityCertificate,
    ): Promise<Either<ValidationError | EntityQualityCertificateError | QualityCertificateMapperError, QualityCertificate>> {
        const editedQualityCertificate =
            this.editQualityCertificateMapper.mapToDto(editQualityCertificate);

        const editQualityCertificateResult = await this.http.put<
            QualityCertificateDto,
            EditQualityCertificateBody
        >(
            `${ENTITY_QUALITY_CERTIFICATES_PATH}${editQualityCertificate.id}/`,
            editedQualityCertificate,
        );

        return editQualityCertificateResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityQualityCertificateError("editQualityCertificateError");
            })
            .flatMap((response) => {
                const qualityCertificate =
                    this.qualityCertificatesMapper.mapQualityCertificate(
                        plainToClass(QualityCertificateDto, response.data),
                    );

                if (!qualityCertificate)
                    return Either.Left(new QualityCertificateMapperError());

                return Either.Right(qualityCertificate);
            });
    }

    async delete(
        qualityCertificateId: number,
    ): Promise<Either<EntityQualityCertificateError, true>> {
        const deleteQualityCertificate = await this.http.delete(
            `${ENTITY_QUALITY_CERTIFICATES_PATH}${qualityCertificateId}`,
        );

        return deleteQualityCertificate
            .mapLeft(() => new EntityQualityCertificateError("deleteQualityCertificateError"))
            .map(() => true);
    }

    async fetchQualityCertificateTypes(): Promise<
        Either<EntityQualityCertificateError, QualityCertificateType[]>
    > {
        const responseResult = await this.http.get<QualityCertificateTypeDto[]>(
            `${ENTITY_QUALITY_CERTIFICATES_PATH}quality_certificates/`,
        );

        return responseResult
            .mapLeft(() => new EntityQualityCertificateError("fetchQualityCertificateTypesError"))
            .map((response) =>
                response.data.mapNotNull((qualityCertificateTypeDto) =>
                    this.qualityCertificateTypeMapper.map(
                        plainToClass(
                            QualityCertificateTypeDto,
                            qualityCertificateTypeDto,
                        ),
                    ),
                ),
            );
    }
}
