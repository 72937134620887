import { ExpenseType } from "@beneficiary/domain/models/economic-data/expense/expense-type.model";
import {
    CreateExpense,
    EditExpense,
    Expense,
    ExpenseSearchFilters,
    Expenses,
} from "@beneficiary/domain/models/economic-data/expense/expense.model";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { inject, injectable } from "inversify";
import { ExpenseRecurrence } from "../../domain/models/economic-data/expense/expense-recurrence.model";
import { ExpenseDatasource } from "../datasource/expense.datasource";
import { BeneficiaryExpenseError } from "@beneficiary/domain/errors/beneficiary.error";
import { ExpenseMapperError } from "@beneficiary/domain/errors/expense/expense-mapper.error";

@injectable()
export class ExpenseRepository {
    private expenseTypes: Undefinable<ExpenseType[]>;

    private expenseRecurrences: Undefinable<ExpenseRecurrence[]>;

    constructor(
        @inject(ExpenseDatasource)
        private readonly expenseDatasource: ExpenseDatasource,
    ) { }

    async create(
        createExpense: CreateExpense,
    ): Promise<Either<ValidationError | BeneficiaryExpenseError | ExpenseMapperError, Expense>> {
        return this.expenseDatasource.create(createExpense);
    }

    async edit(
        expense: EditExpense,
    ): Promise<Either<ValidationError | BeneficiaryExpenseError | ExpenseMapperError, Expense>> {
        return this.expenseDatasource.update(expense);
    }

    async delete(expenseId: number): Promise<Either<BeneficiaryExpenseError, boolean>> {
        return this.expenseDatasource.delete(expenseId);
    }

    async getAllBy(
        filters?: ExpenseSearchFilters,
    ): Promise<Either<BeneficiaryExpenseError, Expenses>> {
        const expensesSummary = await this.expenseDatasource.fetchBy(
            Pagination.NoPagination(),
            filters,
        );

        return expensesSummary.mapLeft(() => new BeneficiaryExpenseError("fetchAllByExpenseError"));
    }

    async findById(expenseId: number): Promise<Either<BeneficiaryExpenseError | ExpenseMapperError, Expense>> {
        const expenseResult = await this.expenseDatasource.fetchById(expenseId);

        if (expenseResult.isLeft())
            return Either.Left(expenseResult.getLeftOrThrow());

        const expense = expenseResult.getOrThrow();

        return Either.Right(expense);
    }

    async getAllExpenseTypes(): Promise<Either<BeneficiaryExpenseError, ExpenseType[]>> {
        if (this.expenseTypes) return Either.Right(this.expenseTypes);

        const expenseTypesResult =
            await this.expenseDatasource.fetchAllExpenseTypes();

        if (expenseTypesResult.isRight()) {
            this.expenseTypes = expenseTypesResult.getOrThrow();
        }

        return expenseTypesResult;
    }

    async getAllExpenseRecurrences(): Promise<
        Either<BeneficiaryExpenseError, ExpenseRecurrence[]>
    > {
        if (this.expenseRecurrences)
            return Either.Right(this.expenseRecurrences);

        const expenseRecurrencesResult =
            await this.expenseDatasource.fetchAllExpenseRecurrences();

        if (expenseRecurrencesResult.isRight()) {
            this.expenseRecurrences = expenseRecurrencesResult.getOrThrow();
        }

        return expenseRecurrencesResult;
    }
}
