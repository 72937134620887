import { DocumentRepository } from "@core/data/repositories/document.repository";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { ProceedingDatasource } from "@proceeding/data/datasource/proceeding.datasource";
import { CreateProceeding } from "@proceeding/domain/models/create-proceeding.model";
import { EditProceeding } from "@proceeding/domain/models/edit-proceeding.model";
import { ProceedingSummary } from "@proceeding/domain/models/proceeding-summary.model";
import {
    Proceeding,
    ProceedingSearchFilters,
} from "@proceeding/domain/models/proceeding.model";
import { ProceedingsSummary } from "@proceeding/domain/models/proceedings-sumary.model";
import { Status } from "@proceeding/domain/models/status.model";
import { inject, injectable } from "inversify";
import { DerivationProceeding } from "../../domain/models/derivation-proceeding.model";
import { ProceedingError } from "@proceeding/domain/errors/proceeding.error";
import { ProceedingMapperError } from "@proceeding/domain/errors/proceeding-mapper.error";

@injectable()
export class ProceedingRepository {
    constructor(
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
        @inject(ProceedingDatasource)
        private readonly proceedingDatasource: ProceedingDatasource,
    ) { }

    async create(
        createProceeding: CreateProceeding,
    ): Promise<Either<ValidationError | ProceedingError | ProceedingMapperError, Proceeding>> {
        return this.proceedingDatasource.create(createProceeding);
    }

    async update(
        editProceeding: EditProceeding,
    ): Promise<Either<ValidationError | ProceedingError | ProceedingMapperError, Proceeding>> {
        return this.proceedingDatasource.update(editProceeding);
    }

    async getAllBy(
        pagination: Pagination,
        filters?: ProceedingSearchFilters,
        order?: Order<ProceedingSummary>,
    ): Promise<Either<ProceedingError, ProceedingsSummary>> {
        const proceedingsSummary = await this.proceedingDatasource.fetchAllBy(
            pagination,
            filters,
            order,
        );

        return proceedingsSummary.mapLeft(() => new ProceedingError("fetchProceedingsError"));
    }

    async getRecentModifiedDerivatedProceedings(): Promise<
        Either<ProceedingError, ProceedingsSummary>
    > {
        const recentSize = 5;
        const pagination: Pagination = new Pagination(0, recentSize);
        const filters: ProceedingSearchFilters = {
            derivation: "fromOther",
        };

        const order = new Order<ProceedingSummary>("modified", "desc");

        const proceedingsSummary = await this.getAllBy(
            pagination,
            filters,
            order,
        );

        return proceedingsSummary;
    }

    async getRecentModifiedProceedings(): Promise<
        Either<FallbackError, ProceedingsSummary>
    > {
        const recentSize = 5;
        const pagination: Pagination = new Pagination(0, recentSize);

        const order = new Order<ProceedingSummary>("modified", "desc");

        const proceedingsSummary = await this.proceedingDatasource.fetchAllBy(
            pagination,
            undefined,
            order,
        );

        return proceedingsSummary.mapLeft(() => new FallbackError());
    }

    async getById(
        proceedingId: number,
    ): Promise<Either<ProceedingError, Proceeding>> {
        const proceedingResult =
            await this.proceedingDatasource.fetchById(proceedingId);

        if (proceedingResult.isLeft()) {
            return Either.Left(new ProceedingError("fetchProceedingError"));
        }

        const { proceeding, authorizationFileId, resolutionFileId } =
            proceedingResult.getOrThrow();

        if (authorizationFileId) {
            const authorizationDocumentResult =
                await this.documentRepository.getById(authorizationFileId);

            if (authorizationDocumentResult.isLeft()) {
                return Either.Left(new FallbackError());
            }

            proceeding.authorizationFile =
                authorizationDocumentResult.getOrThrow();
        }

        if (resolutionFileId && proceeding.resolution) {
            const resolutionDocumentResult =
                await this.documentRepository.getById(resolutionFileId);

            if (resolutionDocumentResult.isLeft()) {
                return Either.Left(new FallbackError());
            }

            proceeding.resolution.file = resolutionDocumentResult.getOrThrow();
        }

        return Either.Right(proceeding);
    }

    async getAllStatuses(): Promise<Either<ProceedingError, Status[]>> {
        return this.proceedingDatasource.fetchAllStatuses();
    }

    async derive(
        deriveProceeding: DerivationProceeding,
    ): Promise<Either<ValidationError | ProceedingError | ProceedingMapperError, DerivationProceeding>> {
        return this.proceedingDatasource.derive(deriveProceeding);
    }
}
