import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { AuditoryDatasource } from "@entity/data/datasources/auditory.datasource";
import { AuditMapperError } from "@entity/domain/errors/auditory/auditory-mapper.error";
import { EntityAuditError } from "@entity/domain/errors/entity.error";
import {
    Auditories,
    Auditory,
} from "@entity/domain/models/quality-framework/auditory/auditory.model";
import { CreateAuditory } from "@entity/domain/models/quality-framework/auditory/create-auditory.model";
import { EditAuditory } from "@entity/domain/models/quality-framework/auditory/edit-auditory.model";
import { inject, injectable } from "inversify";

@injectable()
export class AuditoryRepository {
    constructor(
        @inject(AuditoryDatasource)
        private readonly auditoryDatasource: AuditoryDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityAuditError, Auditories>> {
        const auditoryResult =
            await this.auditoryDatasource.fetchAll(pagination);

        return auditoryResult.mapLeft(() => new EntityAuditError("fetchAllAuditsError"));
    }

    async create(
        auditory: CreateAuditory,
    ): Promise<Either<ValidationError | EntityAuditError | AuditMapperError, Auditory>> {
        return this.auditoryDatasource.create(auditory);
    }

    async edit(
        editAuditory: EditAuditory,
    ): Promise<Either<ValidationError | EntityAuditError | AuditMapperError, Auditory>> {
        return this.auditoryDatasource.edit(editAuditory);
    }

    async delete(auditoryId: number): Promise<Either<EntityAuditError, true>> {
        const auditoryResult = await this.auditoryDatasource.delete(auditoryId);
        return auditoryResult.mapLeft(() => new EntityAuditError("deleteAuditError"));
    }
}
