import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class BeneficiaryAddressError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.address.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryAddressError.prototype);
    }
}

export class BeneficiaryCenterTypesError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.centerTypes.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryCenterTypesError.prototype);
    }
}

export class BeneficiaryCountryError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.country.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryCountryError.prototype);
    }
}

export class BeneficiaryDistrictError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.district.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryDistrictError.prototype);
    }
}

export class BeneficiaryExpenseError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.expense.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryExpenseError.prototype);
    }
}

export class BeneficiaryExternalDerivationError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.externalDerivation.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryExternalDerivationError.prototype);
    }
}

export class BeneficiaryGendersError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.genders.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryGendersError.prototype);
    }
}

export class BeneficiaryIncomeError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.income.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryIncomeError.prototype);
    }
}

export class BeneficiaryInternalNotesError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.internalNotes.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryInternalNotesError.prototype);
    }
}

export class BeneficiaryMaritalStatusError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.maritalStatus.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryMaritalStatusError.prototype);
    }
}

export class BeneficiaryPublicItineraryError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.publicItinerary.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryPublicItineraryError.prototype);
    }
}

export class BeneficiaryRelativesError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.relatives.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryRelativesError.prototype);
    }
}

export class BeneficiaryError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(`error:beneficiary.${errorKey}`));
        Object.setPrototypeOf(this, BeneficiaryError.prototype);
    }
}

export class MapperError extends IncError {
    constructor(errorKey: string) {
        super(i18n.t(errorKey));
        Object.setPrototypeOf(this, MapperError.prototype);
    }
}