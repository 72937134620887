import { inject, injectable } from "inversify";
import { Either } from "@core/domain/types/either";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { PostalCode } from "../../domain/models/postal-code.model";
import { PostalCodeDatasource } from "../datasources/postal-codes.datasource";

@injectable()
export class PostalCodeRepository {
    constructor(
        @inject(PostalCodeDatasource)
        private readonly postalCodeDatasource: PostalCodeDatasource,
    ) { }

    async searchPostalCodes(query: string): Promise<Either<FallbackError, PostalCode[]>> {
        return this.postalCodeDatasource.search(query);
    }
}