import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { LocalDatasource } from "@entity/data/datasources/local.datasource";
import { CostPeriodicityType } from "@entity/domain/models/locals/cost-periodicity.model";
import {
    CreateLocal,
    EditLocal,
    Local,
    Locals,
} from "@entity/domain/models/locals/local.model";
import { CreateRoom, Room } from "@entity/domain/models/locals/room.model";
import { inject, injectable } from "inversify";
import { StatusType } from "../../domain/models/locals/status.model";
import { RoomDatasource } from "../datasources/room.datasource";
import { EntityLocalError, EntityRoomError } from "@entity/domain/errors/entity.error";
import { LocalMapperError } from "@entity/domain/errors/local/local-mapper.error";

@injectable()
export class LocalRepository {
    constructor(
        @inject(LocalDatasource)
        private readonly localDatasource: LocalDatasource,
        @inject(RoomDatasource)
        private readonly roomDatasource: RoomDatasource,
    ) { }

    async getById(localId: number): Promise<Either<EntityLocalError, Local>> {
        return (await this.localDatasource.fetchById(localId)).mapLeft(
            () => new EntityLocalError("fetchLocalError"),
        );
    }

    async getAll(): Promise<Either<EntityLocalError, Local[]>> {
        const localsResult = await this.localDatasource.fetchAll();

        return localsResult
            .mapLeft(() => new EntityLocalError("fetchLocalsError"))
            .map((locals) => locals);
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<EntityLocalError, Locals>> {
        const localsResult = await this.localDatasource.fetchAllPaginated(
            limit,
            offset,
        );

        return localsResult.mapLeft(() => new EntityLocalError("fetchPaginatedLocalsError"));
    }

    async getAllCostPeriodicities(): Promise<
        Either<EntityLocalError, CostPeriodicityType[]>
    > {
        const costPeriodicities =
            await this.localDatasource.fetchAllCostPeriodicities();

        return costPeriodicities
            .mapLeft(() => new EntityLocalError("fetchCostPeriodicitiesError"))
            .map((costPeriodicity) => costPeriodicity);
    }

    async getAllStatusTypes(): Promise<Either<EntityLocalError, StatusType[]>> {
        const statusTypes = await this.localDatasource.fetchAllStatusTypes();

        return statusTypes
            .mapLeft(() => new EntityLocalError("fetchStatusTypesError"))
            .map((status) => status);
    }

    async create(
        createLocal: CreateLocal,
    ): Promise<Either<ValidationError | EntityLocalError | LocalMapperError, Local>> {
        return this.localDatasource.create(createLocal);
    }

    async createRoom(
        createRoom: CreateRoom,
    ): Promise<Either<ValidationError | EntityRoomError, Room>> {
        return this.roomDatasource.create(createRoom);
    }

    async edit(
        editLocal: EditLocal,
    ): Promise<Either<ValidationError | EntityLocalError | LocalMapperError, Local>> {
        return this.localDatasource.edit(editLocal);
    }

    async delete(localId: number): Promise<Either<EntityLocalError, boolean>> {
        return this.localDatasource.delete(localId);
    }
}
