import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CreateEmployee } from "@entity/domain/models/employee/create-employee.model";
import { DisabilityType } from "@entity/domain/models/employee/disability-type.model";
import { EditEmployee } from "@entity/domain/models/employee/edit-employee.model";
import { ProfessionalGroup } from "@entity/domain/models/employee/professional-group.model";
import { SubsidyReason } from "@entity/domain/models/employee/subsidy-reason.model";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { inject, injectable } from "inversify";
import { EmployeeType } from "../../domain/models/employee/employee-type.model";
import {
    Employee,
    EmployeeSearchFilters,
    Employees,
    EmployeesCount,
} from "../../domain/models/employee/employee.model";
import { EmployeeDatasource } from "../datasources/employee.datasource";
import { EntityEmployeeError } from "@entity/domain/errors/entity.error";
import { EmployeeMapperError } from "@entity/domain/errors/employee/employee-mapper.error";

@injectable()
export class EmployeeRepository {
    constructor(
        @inject(EmployeeDatasource)
        private readonly employeeDatasource: EmployeeDatasource,
    ) { }

    async getAllEmployeeTypes(): Promise<
        Either<EntityEmployeeError, EmployeeType[]>
    > {
        return this.employeeDatasource.fetchAllTypes();
    }

    async searchAllBy(
        pagination: Pagination,
        filters?: EmployeeSearchFilters,
    ): Promise<Either<EntityEmployeeError, Employees>> {
        const employeesResult = await this.employeeDatasource.fetchAllBy(
            pagination,
            filters,
        );

        if (employeesResult.isLeft())
            return Either.Left(employeesResult.getLeftOrThrow());

        return Either.Right(employeesResult.getOrThrow());
    }

    async getCountByType(): Promise<Either<EntityEmployeeError, EmployeesCount>> {
        return this.employeeDatasource.getCountByType();
    }

    async create(
        createEmployee: CreateEmployee,
    ): Promise<Either<ValidationError | EntityEmployeeError | EmployeeMapperError, Employee>> {
        return this.employeeDatasource.create(createEmployee);
    }

    async edit(
        editEmployee: EditEmployee,
    ): Promise<Either<ValidationError | EntityEmployeeError | EmployeeMapperError, Employee>> {
        return this.employeeDatasource.edit(editEmployee);
    }

    async findById(
        employeeId: number,
    ): Promise<Either<EntityEmployeeError | EmployeeMapperError, Employee>> {
        const employeeResult =
            await this.employeeDatasource.fetchById(employeeId);

        if (employeeResult.isLeft())
            return Either.Left(employeeResult.getLeftOrThrow());

        const employee = employeeResult.getOrThrow();

        return Either.Right(employee);
    }

    async getAllProfessionalGroups(): Promise<
        Either<EntityEmployeeError, ProfessionalGroup[]>
    > {
        return this.employeeDatasource.fetchAllProfessionalGroups();
    }

    async getAllDisabilityTypes(): Promise<
        Either<EntityEmployeeError, DisabilityType[]>
    > {
        return this.employeeDatasource.fetchAllDisabilityTypes();
    }

    async getAllTerminationReasons(): Promise<
        Either<EntityEmployeeError, TerminationReason[]>
    > {
        return this.employeeDatasource.fetchAllTerminationReasons();
    }

    async getAllSubsidyReasons(): Promise<
        Either<EntityEmployeeError, SubsidyReason[]>
    > {
        return this.employeeDatasource.fetchAllSubsidyReasons();
    }
}
