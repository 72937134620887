import { IncError } from "@core/domain/errors/inc.error";
import i18n from "@i18n";

export class AddressMapperError extends IncError {
    constructor() {
        super(i18n.t("error:beneficiary.address.addressMapperError"));

        Object.setPrototypeOf(this, AddressMapperError.prototype);
    }
}
