import { District } from "@beneficiary/domain/models/district.model";
import { GetAllDistrictsUseCase } from "@beneficiary/domain/usecases/get-all-disctricts.usecase";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";
import { PostalCode } from "@postal-codes/domain/models/postal-code.model";
import { SearchPostalCodesUseCase } from "@postal-codes/domain/usecases/postal-code.usecase";

@injectable()
export class AddressFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    districts: District[] = [];

    constructor(
        @inject(GetAllDistrictsUseCase)
        private readonly getAllDistrictsUseCase: GetAllDistrictsUseCase,
        @inject(SearchPostalCodesUseCase)
        private readonly searchPostalCodesUseCase: SearchPostalCodesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([this.getAllDistricts()]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllDistricts(): Promise<void> {
        const districts = await this.getAllDistrictsUseCase.execute();

        runInAction(() => {
            this.districts = districts;
        });
    }

    async searchPostalCodes(query: string): Promise<PostalCode[]> {
        return this.searchPostalCodesUseCase.execute(query);
    }
}
