import { CenterTypeDto } from "@beneficiary/data/dto/center-type.dto";
import { CenterTypeMapper } from "@beneficiary/data/mappers/center-type.mapper";
import { BeneficiaryCenterTypesError } from "@beneficiary/domain/errors/beneficiary.error";
import { CenterTypesMapperError } from "@beneficiary/domain/errors/center-types/center-types-mapper.error";
import { CenterType } from "@beneficiary/domain/models/center-type.model";
import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class CenterTypeDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(CenterTypeMapper)
        private readonly centerTypeMapper: CenterTypeMapper,
    ) { }

    async fetchAll(): Promise<Either<BeneficiaryCenterTypesError | CenterTypesMapperError, CenterType[]>> {
        const centerTypesResult = await this.http.get<CenterTypeDto[]>(
            "/beneficiaries/external_referral_centre/",
        );

        return centerTypesResult
            .mapLeft(() => new BeneficiaryCenterTypesError("fetchAllCenterTypesError"))
            .flatMap((centerTypesResponse) => {
                try {
                    const mappedTypes = centerTypesResponse.data.mapNotNull((centerType) => {
                        const dtoInstance = plainToClass(CenterTypeDto, centerType);
                        const mapped = this.centerTypeMapper.map(dtoInstance);

                        return mapped;
                    });

                    return Either.Right(mappedTypes);
                } catch (error) {
                    return Either.Left(new CenterTypesMapperError());
                }
            });
    }
}
