import { CreateRelative } from "@beneficiary/domain/models/relatives/create-relative.model";
import { EditRelative } from "@beneficiary/domain/models/relatives/edit-relative.model";
import {
    Relative,
    RelativesSearchFilters,
} from "@beneficiary/domain/models/relatives/relative.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";
import { RelativesDatasource } from "../datasource/relatives.datasource";
import { BeneficiaryRelativesError } from "@beneficiary/domain/errors/beneficiary.error";
import { RelativesMapperError } from "@beneficiary/domain/errors/relatives/relatives-mapper.error";

@injectable()
export class RelativesRepository {
    constructor(
        @inject(RelativesDatasource)
        private readonly relativesDatasource: RelativesDatasource,
    ) { }

    async getAllBy(
        pagination: Pagination,
        filters?: RelativesSearchFilters,
    ): Promise<Either<BeneficiaryRelativesError, Relative[]>> {
        const relativesSummary = await this.relativesDatasource.fetchBy(
            pagination,
            filters,
        );

        return relativesSummary.mapLeft(() => new BeneficiaryRelativesError("fetchAllByError"));
    }

    async create(
        createRelative: CreateRelative,
        beneficiaryId: number,
    ): Promise<Either<BeneficiaryRelativesError | RelativesMapperError, Relative>> {
        return this.relativesDatasource.create(createRelative, beneficiaryId);
    }

    async edit(
        editRelative: EditRelative,
        beneficiaryId: number,
    ): Promise<Either<BeneficiaryRelativesError | RelativesMapperError, Relative>> {
        return this.relativesDatasource.update(editRelative, beneficiaryId);
    }

    async delete(relativeId: number): Promise<Either<BeneficiaryRelativesError, true>> {
        const relativeResult =
            await this.relativesDatasource.delete(relativeId);
        return relativeResult.mapLeft(() => new BeneficiaryRelativesError("deleteError"));
    }
}
