export enum TerminationReasonEnum {
    RESIGNATION = "resignation",
    DISCIPLINARY_DISMISSAL = "disciplinary_dismissal",
    OBJECTIVE_DISMISSAL = "objective_dismissal",
    COLLECTIVE_DISMISSAL = "collective_dismissal",
    IMPROPER_DISMISSAL = "improper_dismissal",
    TEMPORARY_LEAVE = "temporary_leave",
    DEATH = "death",
}

export class TerminationReason {
    constructor(
        public readonly id: TerminationReasonEnum,
        public readonly label: string,
    ) { }
}
