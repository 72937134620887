import {
    Address,
    Addresses,
    CreateAddress,
    EditAddress,
} from "@beneficiary/domain/models/address.model";
import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpError } from "@core/data/infrastructures/http/errors/http.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { OrderMapper } from "@core/data/mappers/order.mapper";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import {
    AddressDto,
    AddressSearchFilters,
    AddressesDto,
    AddressesQuery,
    CreateAddressBody,
    EditAddressBody,
    addressesOrderMap,
} from "../dto/address.dto";
import { AddressMapper } from "../mappers/address/address.mapper";
import { AddressesMapper } from "../mappers/address/addresses.mapper";
import { BeneficiaryAddressError } from "@beneficiary/domain/errors/beneficiary.error";
import { AddressMapperError } from "@beneficiary/domain/errors/address/address-mapper.error";

const ADDRESSS_PATH = "/beneficiaries_addresses/";

@injectable()
export class AddressDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(OrderMapper)
        private readonly orderMapper: OrderMapper,
        @inject(AddressMapper)
        private readonly addressMapper: AddressMapper,
        @inject(AddressesMapper)
        private readonly addressesMapper: AddressesMapper,
    ) { }

    async create(
        newAddress: CreateAddress,
    ): Promise<Either<BeneficiaryAddressError | AddressMapperError, Address>> {
        const createdAddressDto =
            this.addressMapper.mapToCreateBody(newAddress);

        const createAddressQuery: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const createAddressResult = await this.http.post<CreateAddressBody>(
            ADDRESSS_PATH,
            createdAddressDto,
            {
                query: createAddressQuery,
            },
        );

        return createAddressResult
            .mapLeft(() => new BeneficiaryAddressError("createAddressError"))
            .flatMap((response) => {
                const createdAddress = this.addressMapper.map(
                    plainToClass(AddressDto, response.data),
                );

                if (!createdAddress) return Either.Left(new AddressMapperError());

                return Either.Right(createdAddress);
            });
    }

    async fetchBy(
        pagination: Pagination,
        filters?: AddressSearchFilters,
        order?: Order<Address>,
    ): Promise<Either<HttpError, Addresses>> {
        const query: AddressesQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.id) query.id = filters.id;

        if (order) {
            const orderQuery = this.orderMapper.mapToDto<Address, AddressDto>(
                order.field,
                order.direction,
                addressesOrderMap,
            );
            if (orderQuery) query.ordering = orderQuery;
        }

        const addressesResult = await this.http.get<AddressDto>(ADDRESSS_PATH, {
            query,
        });

        const addressesDto = addressesResult.map((response) =>
            this.addressesMapper.map(plainToClass(AddressesDto, response.data)),
        );

        return addressesDto;
    }

    async fetchById(
        addressId: number,
    ): Promise<Either<BeneficiaryAddressError | AddressMapperError, { address: Address }>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const addressResult = await this.http.get<AddressDto>(
            `${ADDRESSS_PATH}${addressId}/`,
            { query },
        );

        return addressResult
            .mapLeft(() => new BeneficiaryAddressError("fetchAddressByIdError"))
            .flatMap((response) => {
                const address = this.addressMapper.map(
                    plainToClass(AddressDto, response.data),
                );

                if (!address) return Either.Left(new AddressMapperError());

                return Either.Right({
                    address,
                });
            });
    }

    async update(
        addressToEdit: EditAddress,
    ): Promise<Either<BeneficiaryAddressError | AddressMapperError, Address>> {
        const editAddressBody = this.addressMapper.mapToEditBody(addressToEdit);

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editAddressResult = await this.http.patch<
            AddressDto,
            EditAddressBody
        >(`${ADDRESSS_PATH}${addressToEdit.id}/`, editAddressBody, {
            query,
        });

        return editAddressResult
            .mapLeft(() => new BeneficiaryAddressError("updateAddressError"))
            .flatMap((response) => {
                const address = this.addressMapper.map(
                    plainToClass(AddressDto, response.data),
                );

                if (!address) return Either.Left(new AddressMapperError());

                return Either.Right(address);
            });
    }

    async delete(addressId: number): Promise<Either<BeneficiaryAddressError, boolean>> {
        const deleteAddressResult = await this.http.delete(
            `${ADDRESSS_PATH}${addressId}/`,
        );

        return deleteAddressResult
            .mapLeft(() => new BeneficiaryAddressError("deleteAddressError"))
            .map(() => true);
    }
}
