import { injectable } from "inversify";
import { PostalCode } from "../../domain/models/postal-code.model";
import { PostalCodeDto } from "../dto/postal-code.dto";

@injectable()
export class PostalCodeMapper {
    map(dto: PostalCodeDto): PostalCode | null {
        if (!dto) return null;

        return new PostalCode(
            dto.code,
            dto.city,
            dto.province
        );
    }
}