import { CountryMapper } from "@beneficiary/data/mappers/country.mapper";
import { BeneficiaryCountryError } from "@beneficiary/domain/errors/beneficiary.error";
import { CountryMapperError } from "@beneficiary/domain/errors/country/country-mapper.error";
import { coreTypes } from "@core/core-types.di";
import { CountryDto } from "@core/data/dto/country.dto";
import { type Http } from "@core/data/infrastructures/http/http";
import { Country } from "@core/domain/models/country.model";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class CountriesDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(CountryMapper)
        private readonly countryMapper: CountryMapper,
    ) { }

    async fetchAllCountries(): Promise<Either<BeneficiaryCountryError | CountryMapperError, Country[]>> {
        const countriesResults = await this.http.get<CountryDto[]>(
            "/beneficiaries/countries/",
        );

        return countriesResults
            .mapLeft(() => new BeneficiaryCountryError("fetchAllCountriesError"))
            .flatMap((countriesResponse) => {
                try {
                    const mappedCountries = countriesResponse.data.mapNotNull((countryDto) =>
                        this.countryMapper.map(
                            plainToClass(CountryDto, countryDto),
                        ),
                    )
                    return Either.Right(mappedCountries);
                } catch (error) {
                    return Either.Left(new CountryMapperError());
                }
            }

            );
    }
}
