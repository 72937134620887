import { DocumentDto } from "@core/data/dto/document.dto";
import { DocumentCategoryMapper } from "@core/data/mappers/document-category.mapper";
import { DocumentSignatureMapper } from "@core/data/mappers/document-signature.mapper";
import { DocumentSignature } from "@core/domain/models/document-signatures.model";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";

@injectable()
export class DocumentMapper {
    constructor(
        @inject(DocumentCategoryMapper)
        private readonly documentCategoryMapper: DocumentCategoryMapper,
        @inject(DocumentSignatureMapper)
        private readonly documentSignatureMapper: DocumentSignatureMapper,
    ) { }

    map(documentDto: Partial<DocumentDto>): Nullable<IncDocument> {
        if (!documentDto.id || !documentDto.name || !documentDto.document) {
            return null;
        }
        const errors = validateSync(documentDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        let category: Nullish<DocumentCategory> = undefined;
        if (documentDto.category_data) {
            category = this.documentCategoryMapper.map(
                documentDto.category_data,
            );

            if (!category) return null;
        }

        let documentSignature: Nullish<DocumentSignature> = undefined;
        if (documentDto.signature_data) {
            documentSignature = this.documentSignatureMapper.map(
                documentDto.signature_data,
            );
            if (!documentSignature) return null;
        }

        return new IncDocument(
            documentDto.id,
            documentDto.name,
            documentDto.document,
            DateTime.fromISO(documentDto.created ?? DateTime.now().toISO()),
            DateTime.fromISO(documentDto.modified ?? DateTime.now().toISO()),
            documentDto.title ?? undefined,
            documentDto.author ?? undefined,
            category,
            documentSignature,
            documentDto.file_with_signature ?? undefined,
        );
    }
}
