import { inject, injectable } from "inversify";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { ServiceDatasource } from "@entity/data/datasources/service.datasource";
import { Service } from "@entity/domain/models/service.model";
import { Services } from "@entity/domain/models/services.model";
import { EntityServiceError } from "@entity/domain/errors/entity.error";

@injectable()
export class ServiceRepository {
    constructor(
        @inject(ServiceDatasource)
        private readonly serviceDatasource: ServiceDatasource,
    ) { }

    async getAll(): Promise<Either<EntityServiceError, Service[]>> {
        const servicesResult = await this.serviceDatasource.fetchAllPaginated();

        return servicesResult
            .mapLeft(() => new EntityServiceError("fetchServicesError"))
            .map((services) => services.services);
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<EntityServiceError, Services>> {
        const servicesResult = await this.serviceDatasource.fetchAllPaginated(
            limit,
            offset,
        );

        return servicesResult.mapLeft(() => new EntityServiceError("fetchPaginatedServicesError"));
    }
}
