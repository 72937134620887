import { coreTypes } from "@core/core-types.di";
import { type Http } from "@core/data/infrastructures/http/http";
import { Either } from "@core/domain/types/either";
import { TitularityTypeDto } from "@entity/data/dto/titularity-type.dto";
import { TitularityTypeMapper } from "@entity/data/mappers/titularity-type.mapper";
import { EntityTitularityTypesError } from "@entity/domain/errors/entity.error";
import { TitularityType } from "@entity/domain/models/titularity-type.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class TitularityTypesDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(TitularityTypeMapper)
        private readonly titularityTypeMapper: TitularityTypeMapper,
    ) { }

    async fetchAllTitularityTypes(): Promise<
        Either<EntityTitularityTypesError, TitularityType[]>
    > {
        const titularityTypesResults = await this.http.get<TitularityTypeDto[]>(
            "/entities_addresses/titularity_types/",
        );

        return titularityTypesResults
            .mapLeft(() => new EntityTitularityTypesError("fetchTitularityTypesError"))
            .map((titularityTypesResponse) =>
                titularityTypesResponse.data.mapNotNull((titularityTypeDto) =>
                    this.titularityTypeMapper.map(
                        plainToClass(TitularityTypeDto, titularityTypeDto),
                    ),
                ),
            );
    }
}
