import { ExternalDerivationDatasource } from "@beneficiary/data/datasource/external-derivation.datasource";
import { BeneficiaryExternalDerivationError } from "@beneficiary/domain/errors/beneficiary.error";
import { ExternalDerivationMapperError } from "@beneficiary/domain/errors/external-derivation/external-derivation-mapper.error";
import { CreateExternalDerivation } from "@beneficiary/domain/models/external-derivation/create-external-derivation.model";
import { EditExternalDerivation } from "@beneficiary/domain/models/external-derivation/edit-external-derivation.model";
import {
    ExternalDerivation,
    ExternalDerivations,
} from "@beneficiary/domain/models/external-derivation/external-derivation.model";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class ExternalDerivationRepository {
    constructor(
        @inject(ExternalDerivationDatasource)
        private readonly externalDerivationDatasource: ExternalDerivationDatasource,
    ) { }

    async getAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<BeneficiaryExternalDerivationError, ExternalDerivations>> {
        return this.externalDerivationDatasource.fetchAllBy(
            beneficiaryId,
            pagination,
        );
    }

    async create(
        externalDerivation: CreateExternalDerivation,
    ): Promise<Either<BeneficiaryExternalDerivationError | ExternalDerivationMapperError, ExternalDerivation>> {
        return this.externalDerivationDatasource.create(externalDerivation);
    }

    async edit(
        externalDerivation: EditExternalDerivation,
    ): Promise<Either<BeneficiaryExternalDerivationError | ExternalDerivationMapperError, ExternalDerivation>> {
        return this.externalDerivationDatasource.edit(externalDerivation);
    }

    async delete(
        externalDerivationId: number,
    ): Promise<Either<BeneficiaryExternalDerivationError, boolean>> {
        return this.externalDerivationDatasource.delete(externalDerivationId);
    }
}
