import { CreatePublicItineraryBody } from "@beneficiary/data/dto/public-itinerary/create-public-itinerary.body";
import { EditPublicItineraryBody } from "@beneficiary/data/dto/public-itinerary/edit-public-itinerary.body";
import {
    PublicItinerariesDto,
    PublicItinerariesQuery,
    PublicItineraryDto,
} from "@beneficiary/data/dto/public-itinerary/public-itinerary.dto";
import { CreatePublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/create-public-itinerary.mapper";
import { EditPublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/edit-public-itinerary.mapper";
import { PublicItinerariesMapper } from "@beneficiary/data/mappers/public-itinerary/public-itineraries.mapper";
import { BeneficiaryPublicItineraryError } from "@beneficiary/domain/errors/beneficiary.error";
import { PublicItineraryMapperError } from "@beneficiary/domain/errors/public-itinerary/public-itinerary-mapper.error";
import { CreatePublicItinerary } from "@beneficiary/domain/models/public-itinerary/create-public-itinerary.model";
import { EditPublicItinerary } from "@beneficiary/domain/models/public-itinerary/edit-public-itinerary.model";
import {
    PublicItineraries,
    PublicItinerary,
} from "@beneficiary/domain/models/public-itinerary/public-itinerary.model";
import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const BENEFICIARY_PUBLIC_ITINERARY_PATH = "/beneficiary_public_itinerary/";

@injectable()
export class PublicItineraryDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(PublicItinerariesMapper)
        private readonly publicItinerariesMapper: PublicItinerariesMapper,
        @inject(CreatePublicItineraryMapper)
        private readonly createPublicItineraryMapper: CreatePublicItineraryMapper,
        @inject(EditPublicItineraryMapper)
        private readonly editPublicItineraryMapper: EditPublicItineraryMapper,
    ) { }

    async fetchAllBy(
        beneficiaryId: number,
        pagination: Pagination,
    ): Promise<Either<BeneficiaryPublicItineraryError, PublicItineraries>> {
        const query: PublicItinerariesQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
            beneficiary: beneficiaryId,
        };

        const publicItinerariesResult = await this.http.get<PublicItineraryDto>(
            BENEFICIARY_PUBLIC_ITINERARY_PATH,
            {
                query,
            },
        );

        return publicItinerariesResult
            .mapLeft(() => new BeneficiaryPublicItineraryError("fetchAllByError"))
            .flatMap((response) => {
                const publicItineraries = this.publicItinerariesMapper.map(
                    plainToClass(PublicItinerariesDto, response.data),
                );

                return Either.Right(publicItineraries);
            });
    }

    async create(
        newPublicItinerary: CreatePublicItinerary,
    ): Promise<Either<ValidationError | BeneficiaryPublicItineraryError | PublicItineraryMapperError, PublicItinerary>> {
        const publicItineraryBody =
            this.createPublicItineraryMapper.mapToCreateDto(newPublicItinerary);

        const publicItineraryResult =
            await this.http.post<CreatePublicItineraryBody>(
                BENEFICIARY_PUBLIC_ITINERARY_PATH,
                publicItineraryBody,
            );

        return publicItineraryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new BeneficiaryPublicItineraryError("createError");
            })
            .flatMap((response) => {
                const publicItinerary =
                    this.publicItinerariesMapper.mapPublicItinerary(
                        plainToClass(PublicItineraryDto, response.data),
                    );

                if (!publicItinerary) return Either.Left(new PublicItineraryMapperError());

                return Either.Right(publicItinerary);
            });
    }

    async edit(
        editPublicItinerary: EditPublicItinerary,
    ): Promise<Either<ValidationError | BeneficiaryPublicItineraryError | PublicItineraryMapperError, PublicItinerary>> {
        const editedPublicItinerary =
            this.editPublicItineraryMapper.mapToDto(editPublicItinerary);

        const editPublicItineraryResult = await this.http.patch<
            PublicItineraryDto,
            EditPublicItineraryBody
        >(
            `${BENEFICIARY_PUBLIC_ITINERARY_PATH}${editPublicItinerary.id}/`,
            editedPublicItinerary,
        );

        return editPublicItineraryResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new BeneficiaryPublicItineraryError("editError");
            })
            .flatMap((response) => {
                const publicItinerary =
                    this.publicItinerariesMapper.mapPublicItinerary(
                        plainToClass(PublicItineraryDto, response.data),
                    );

                if (!publicItinerary) return Either.Left(new PublicItineraryMapperError());

                return Either.Right(publicItinerary);
            });
    }

    async delete(
        publicItineraryId: number,
    ): Promise<Either<BeneficiaryPublicItineraryError, true>> {
        const deletePublicItinerary = await this.http.delete(
            `${BENEFICIARY_PUBLIC_ITINERARY_PATH}${publicItineraryId}`,
        );

        return deletePublicItinerary
            .mapLeft(() => new BeneficiaryPublicItineraryError("deleteError"))
            .map(() => true);
    }
}
