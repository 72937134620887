import { Nullable } from "@core/domain/types/nullable.type";
import { ExpenseTypeEnumDto } from "@project/data/dto/expenses/expense-type.dto";
import { ExpenseTypeEnum } from "@project/domain/models/expenses/expense-type.model";
import { injectable } from "inversify";

@injectable()
export class ExpenseTypeEnumMapper {
    map(statusEnumDto: ExpenseTypeEnumDto): Nullable<ExpenseTypeEnum> {
        switch (statusEnumDto) {
            case ExpenseTypeEnumDto.TRAVEL:
                return ExpenseTypeEnum.TRAVEL;
            case ExpenseTypeEnumDto.ACCOMMODATION_MEALS:
                return ExpenseTypeEnum.ACCOMMODATION_MEALS;
            case ExpenseTypeEnumDto.PERSONNEL_MANAGEMENT_ADMINISTRATION:
                return ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION;
            case ExpenseTypeEnumDto.MAINTENANCE_ACTIVITIES:
                return ExpenseTypeEnum.MAINTENANCE_ACTIVITIES;
            case ExpenseTypeEnumDto.INVESTMENT:
                return ExpenseTypeEnum.INVESTMENT;
            case ExpenseTypeEnumDto.MANAGEMENT_ADMINISTRATION:
                return ExpenseTypeEnum.MANAGEMENT_ADMINISTRATION;
            case ExpenseTypeEnumDto.OTHER_SUBSIDIZABLE_EXPENSES:
                return ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES;
            default:
                return null;
        }
    }

    mapToDto(statusEnum: ExpenseTypeEnum): ExpenseTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (statusEnum) {
            case ExpenseTypeEnum.TRAVEL:
                return ExpenseTypeEnumDto.TRAVEL;
            case ExpenseTypeEnum.ACCOMMODATION_MEALS:
                return ExpenseTypeEnumDto.ACCOMMODATION_MEALS;
            case ExpenseTypeEnum.PERSONNEL_MANAGEMENT_ADMINISTRATION:
                return ExpenseTypeEnumDto.PERSONNEL_MANAGEMENT_ADMINISTRATION;
            case ExpenseTypeEnum.MAINTENANCE_ACTIVITIES:
                return ExpenseTypeEnumDto.MAINTENANCE_ACTIVITIES;
            case ExpenseTypeEnum.INVESTMENT:
                return ExpenseTypeEnumDto.INVESTMENT;
            case ExpenseTypeEnum.MANAGEMENT_ADMINISTRATION:
                return ExpenseTypeEnumDto.MANAGEMENT_ADMINISTRATION;
            case ExpenseTypeEnum.PERSONNEL_EXPENSES:
                return ExpenseTypeEnumDto.PERSONNEL_EXPENSES;
            case ExpenseTypeEnum.OTHER_SUBSIDIZABLE_EXPENSES:
                return ExpenseTypeEnumDto.OTHER_SUBSIDIZABLE_EXPENSES;
            case ExpenseTypeEnum.NONE:
                return ExpenseTypeEnumDto.NONE;
        }
    }
}
