import { Either } from "@core/domain/types/either";
import { CollectiveDatasource } from "@entity/data/datasources/collective.datasource";
import { EntityCollectiveError } from "@entity/domain/errors/entity.error";
import { Collective } from "@entity/domain/models/collective.model";
import { inject, injectable } from "inversify";

@injectable()
export class CollectiveRepository {
    constructor(
        @inject(CollectiveDatasource)
        private readonly collectiveDatasource: CollectiveDatasource,
    ) { }

    async getAll(): Promise<Either<EntityCollectiveError, Collective[]>> {
        return this.collectiveDatasource.fetchAllPaginated();
    }

    async getAllPaginated(
        limit: number,
        offset: number,
    ): Promise<Either<EntityCollectiveError, Collective[]>> {
        return this.collectiveDatasource.fetchAllPaginated(limit, offset);
    }
}
