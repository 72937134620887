import { BeneficiaryError } from "@beneficiary/domain/errors/beneficiary.error";
import { EmploymentStatusDatasource } from "@core/data/datasources/employment-status.datasource";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { Either } from "@core/domain/types/either";
import { inject, injectable } from "inversify";

@injectable()
export class EmploymentStatusRepository {
    constructor(
        @inject(EmploymentStatusDatasource)
        private readonly employmentStatusDatasource: EmploymentStatusDatasource,
    ) { }

    async getAll(): Promise<Either<BeneficiaryError, EmploymentStatus[]>> {
        return this.employmentStatusDatasource.fetchAllEmploymentStatus();
    }
}
