import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import { ContactDto, ContactResultDto } from "@entity/data/dto/contact.dto";
import { ContactsMapper } from "@entity/data/mappers/contacts.mapper";
import { ContactMapperError } from "@entity/domain/errors/contact/contact-mapper.error";
import { EntityContactError } from "@entity/domain/errors/entity.error";
import {
    Contact,
    CreateContactBody,
} from "@proceeding/domain/models/contact.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENTITY_CONTACT_PATH = "/entities_contact_persons/";

@injectable()
export class ContactDatasource {
    constructor(
        @inject(ContactsMapper)
        private readonly contactsMapper: ContactsMapper,
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
    ) { }

    async fetchAll(): Promise<Either<EntityContactError, Contact[]>> {
        const contactResult =
            await this.http.get<ContactResultDto>(ENTITY_CONTACT_PATH);

        return contactResult
            .mapLeft(() => new EntityContactError("fetchAllContactsError"))
            .map((response) =>
                response.data.results.mapNotNull((contactDto) =>
                    this.contactsMapper.map(
                        plainToClass(ContactDto, contactDto),
                    ),
                ),
            );
    }

    async create(
        contact: CreateContactBody,
    ): Promise<Either<ValidationError | EntityContactError | ContactMapperError, Contact>> {
        const contactDto = this.contactsMapper.mapToCreateContactDto(contact);
        const createContactResult = await this.http.post<ContactDto>(
            ENTITY_CONTACT_PATH,
            contactDto,
        );

        return createContactResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityContactError("createContactError");
            })
            .flatMap((response) => {
                const contactCreated = this.contactsMapper.map(
                    plainToClass(ContactDto, response.data),
                );

                if (!contactCreated) return Either.Left(new ContactMapperError());

                return Either.Right(contactCreated);
            });
    }

    async edit(
        contact: Contact,
    ): Promise<Either<ValidationError | EntityContactError | ContactMapperError, Contact>> {
        const contactDto = this.contactsMapper.mapToContactDto(contact);

        const editContactResult = await this.http.patch<ContactDto>(
            `${ENTITY_CONTACT_PATH}${contact.id}/`,
            contactDto,
        );

        return editContactResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new EntityContactError("editContactError");
            })
            .flatMap((response) => {
                const contactEdited = this.contactsMapper.map(
                    plainToClass(ContactDto, response.data),
                );

                if (!contactEdited) return Either.Left(new ContactMapperError());

                return Either.Right(contactEdited);
            });
    }

    async delete(contactId: number): Promise<Either<EntityContactError, true>> {
        const deleteContactResult = await this.http.delete(
            `${ENTITY_CONTACT_PATH}${contactId}/`,
        );

        return deleteContactResult
            .mapLeft(() => new EntityContactError("deleteContactError"))
            .map(() => true);
    }
}
