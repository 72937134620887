import { BeneficiaryError } from "@beneficiary/domain/errors/beneficiary.error";
import { coreTypes } from "@core/core-types.di";
import { EducationLevelDto } from "@core/data/dto/education-level.dto";
import { type Http } from "@core/data/infrastructures/http/http";
import { EducationLevelMapper } from "@core/data/mappers/education-level.mapper";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { Either } from "@core/domain/types/either";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

@injectable()
export class EducationLevelDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http) private readonly http: Http,
        @inject(EducationLevelMapper)
        private readonly educationLevelMapper: EducationLevelMapper,
    ) { }

    async fetchAllEducationLevels(): Promise<
        Either<BeneficiaryError, EducationLevel[]>
    > {
        const educationLevelsResult = await this.http.get<EducationLevelDto[]>(
            "/beneficiaries/education_level/",
        );

        return educationLevelsResult
            .mapLeft(() => new BeneficiaryError("fetchAllEducationLevelsError"))
            .map((educationLevelsResponse) =>
                educationLevelsResponse.data.mapNotNull((educationLevelDto) =>
                    this.educationLevelMapper.map(
                        plainToClass(EducationLevelDto, educationLevelDto),
                    ),
                ),
            );
    }
}
