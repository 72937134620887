import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { AffiliationDatasource } from "@entity/data/datasources/affiliation.datasource";
import { AffiliationMapperError } from "@entity/domain/errors/affiliation/affiliation-mapper.error";
import { EntityAffiliationError } from "@entity/domain/errors/entity.error";
import {
    Affiliation,
    Affiliations,
} from "@entity/domain/models/affiliation/affiliation.model";
import { CreateAffiliation } from "@entity/domain/models/affiliation/create-affiliation.model";
import { EditAffiliation } from "@entity/domain/models/affiliation/edit-affiliation.model";
import { inject, injectable } from "inversify";

@injectable()
export class AffiliationRepository {
    constructor(
        @inject(AffiliationDatasource)
        private readonly affiliationDatasource: AffiliationDatasource,
    ) { }

    async getAll(
        pagination: Pagination,
    ): Promise<Either<EntityAffiliationError, Affiliations>> {
        const materialResult =
            await this.affiliationDatasource.fetchAll(pagination);

        return materialResult.mapLeft(() => new EntityAffiliationError("fetchAllAffiliationsError"));
    }

    async create(
        affiliation: CreateAffiliation,
    ): Promise<Either<ValidationError | EntityAffiliationError | AffiliationMapperError, Affiliation>> {
        return this.affiliationDatasource.create(affiliation);
    }

    async edit(
        editAffiliation: EditAffiliation,
    ): Promise<Either<ValidationError | EntityAffiliationError | AffiliationMapperError, Affiliation>> {
        return this.affiliationDatasource.edit(editAffiliation);
    }

    async delete(affiliationId: number): Promise<Either<EntityAffiliationError, true>> {
        const affiliationResult =
            await this.affiliationDatasource.delete(affiliationId);
        return affiliationResult.mapLeft(() => new EntityAffiliationError("deleteAffiliationError"));
    }
}
