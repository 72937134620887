import { inject, injectable } from "inversify";
import { PostalCode } from "../models/postal-code.model";
import { PostalCodeRepository } from "@postal-codes/data/repository/postal-code.repository";

@injectable()
export class SearchPostalCodesUseCase {
    constructor(
        @inject(PostalCodeRepository)
        private readonly postalCodeRepository: PostalCodeRepository,
    ) { }

    async execute(query: string): Promise<PostalCode[]> {
        const result = await this.postalCodeRepository.searchPostalCodes(query);
        return result.getOrElse([]);
    }
}