import { AddressMapper } from "@beneficiary/data/mappers/address/address.mapper";
import { AddressRepository } from "@beneficiary/data/repositories/address.repository";
import {
    AddressData,
    CreateAddress,
    EditAddress,
} from "@beneficiary/domain/models/address.model";
import { AddressFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/address/address-form.view";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";
import { CreateAddressUseCase } from "./create-address.usecase";
import { EditAddressUseCase } from "./edit-address.usecase";
import { EditBeneficiaryAddressUseCase } from "./edit-beneficiary-address.usecase";

@injectable()
export class SaveAddressUseCase {
    constructor(
        @inject(AddressMapper)
        private readonly addressMapper: AddressMapper,
        @inject(CreateAddressUseCase)
        private readonly createAddressUseCase: CreateAddressUseCase,
        @inject(EditAddressUseCase)
        private readonly editAddressUseCase: EditAddressUseCase,
        @inject(EditBeneficiaryAddressUseCase)
        private readonly editBeneficiaryAddressUseCase: EditBeneficiaryAddressUseCase,
    ) { }

    async execute(
        beneficiaryId: number,
        addressFormValuesValidated: AddressFormValuesValidated,
    ): Promise<AddressData> {
        const { usualAddress, censusAddress } = addressFormValuesValidated;

        const addressData: AddressData = {
            usualAddress: null,
            censusAddress: null,
        };

        if (censusAddress.id) {
            const editAddress: EditAddress =
                this.addressMapper.mapFormToEdit(censusAddress);
            addressData.censusAddress =
                await this.editAddressUseCase.execute(editAddress);
        } else {
            const createdAddress: CreateAddress =
                this.addressMapper.mapFormToCreate(censusAddress);
            addressData.censusAddress =
                await this.createAddressUseCase.execute(createdAddress);
        }

        if (usualAddress && usualAddress.address !== "") {
            if (usualAddress.id) {
                const editAddress: EditAddress =
                    this.addressMapper.mapFormToEdit(usualAddress);
                addressData.usualAddress =
                    await this.editAddressUseCase.execute(editAddress);
            } else {
                const createdAddress: CreateAddress =
                    this.addressMapper.mapFormToCreate(usualAddress);
                addressData.usualAddress =
                    await this.createAddressUseCase.execute(createdAddress);
            }
        }

        const beneficiaryResponse =
            await this.editBeneficiaryAddressUseCase.execute(
                beneficiaryId,
                addressData.usualAddress?.id,
                addressData.censusAddress?.id,
            );

        if (beneficiaryResponse) {
            ToastManagerStore.success("Dirección del beneficiario actualizada");
        }

        return addressData;
    }
}
